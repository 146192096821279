import React from 'react'

export default function Hindi() {
	return (
		<>
			<div className="terms">
				<ol>
				  <li>यह फिनोलेक्स पाइप्स & फिटिंग्स सुपर प्लंबर लॉयल्टी प्रोग्राम विशेष रूप से केवल फिनोलेक्स पाइप्स & फिटिंग्स द्वारा विचार किए गए पैन इंडिया प्लंबरों के लिए डिज़ाइन किया गया है।</li>
				  <li>कार्यक्रम 16 अक्टूबर से शुरू हो रहा है और कार्यक्रम में भागीदारी स्वैच्छिक है।</li>
				  <li>कार्यक्रम में प्लंबर का पंजीकरण स्वैच्छिक है।</li>
				  <li>सभी उपयोगकर्ताओं/सदस्यों ने कार्यक्रम के नियम और शर्तें और गोपनीयता नीति को विस्तार से पढ़ लिया है और उनका पालन करने के लिए सहमत हैं।</li>
				  <li>इस प्लेटफ़ॉर्म और लॉयल्टी प्रोग्राम के सभी उपयोगकर्ताओं/सदस्यों ने डिजिटल व्यक्तिगत डेटा संरक्षण अधिनियम, 2023 के अनुसार अपने व्यक्तिगत डेटा जैसे नाम, मोबाइल नंबर, फोटोग्राफ, आधार का उपयोग करने के लिए ईबीपीएल और एफआईएल को सहमति दी है और अपनी सहमति प्रदान की है। ईबीपीएल/एफआईएल द्वारा उक्त व्यक्तिगत डेटा के प्रसंस्करण के लिए कार्ड, पैन कार्ड और अपने वैध व्यावसायिक उद्देश्यों के लिए तीसरे पक्ष को समग्र पंजीकरण डेटा का खुलासा करने का अधिकार, जिसमें विज्ञापनों और अन्य सेवा प्रस्तावों को लक्षित करना शामिल है, लेकिन इन्हीं तक सीमित नहीं है।</li>
				  <li>मोचन ऑनलाइन मोचन विकल्पों के माध्यम से किया जा सकता है।</li>
				  <li>फिनोलेक्स पाइप्स & फिटिंग्स के अधिकृत डीलरों और खुदरा विक्रेताओं से खरीदे गए उत्पाद (इस कार्यक्रम के अंतर्गत आने वाले) पर इस कार्यक्रम के लिए विचार किया जाएगा।</li>
				  <li>इस कार्यक्रम को किसी अन्य योजना/कार्यक्रम/प्रस्ताव के साथ तब तक नहीं जोड़ा जा सकता जब तक कि इसे फिनोलेक्स पाइप्स & फिटिंग्स द्वारा लिखित रूप से अनुमोदित न किया गया हो।</li>
				  <li>सभी प्लंबर फिनोलेक्स पाइप्स & फिटिंग्स सुपर प्लंबर लॉयल्टी प्रोग्राम में पंजीकरण के लिए पात्र हैं, जो फिनोलेक्स पाइप्स & फिटिंग्स बिक्री टीम से सत्यापन के अधीन है। पंजीकृत सदस्य फिनोलेक्स पाइप्स & फिटिंग्स कर्मचारी, विक्रेता, एजेंट या कार्यक्रम चलाने में शामिल कोई व्यक्ति या संगठन नहीं होना चाहिए, और ऐसे किसी भी व्यक्ति का पारिवारिक संबंध नहीं होना चाहिए।</li>
				  <li>कार्यक्रम के लाभों का आनंद लेने के लिए  फिनोलेक्स पाइप्स & फिटिंग्स सुपर प्लंबर लॉयल्टी प्रोग्राम में सभी पंजीकरण सत्यापन आवश्यक है।</li>
				  <li>लॉटरी (विनियमन) अधिनियम, 1998 के तहत आने वाला कोई भी लाभ या इनाम तमिलनाडु राज्य में पंजीकृत और संचालन करने वाले सदस्यों के लिए लागू नहीं होगा। 1 पॉइंट का मूल्य INR 0.02/- होगा।</li>
				  <li>अर्जित अंकों को पेटीएम वॉलेट, यूपीआई, बैंक ट्रांसफर उत्पादों और ई-वाउचर के बदले भुनाया जाएगा। फिनोलेक्स पाइप्स & फिटिंग्स द्वारा कोई और शर्त लागू होने तक भौतिक उत्पाद रिवॉर्ड कैटलॉग में उपलब्ध हैं।</li>
				  <li>किसी भी डुप्लिकेट चालान अपलोड करने पर कोई वफादारी पुरस्कार अंक नहीं दिए जाएंगे।</li>
				  <li>न तो इस कार्यक्रम और न ही इसके नियमों और शर्तों को एक संयुक्त उद्यम, एजेंसी संबंध, या फ्रेंचाइजी देने के रूप में समझा जाएगा।</li>
				  <li>फिनोलेक्स पाइप्स & फिटिंग्स के पास बिना कोई कारण और सूचना दिए किसी भी समय " फिनोलेक्स पाइप्स & फिटिंग्स सुपर प्लंबर लॉयल्टी प्रोग्राम" में संशोधन करने या वापस लेने का अधिकार सुरक्षित है और इस संबंध में फिनोलेक्स पाइप्स & फिटिंग्स का निर्णय माना जाता है। अंतिम और बाध्यकारी।</li>
				  <li>फिनोलेक्स पाइप्स & फिटिंग्स के पास किसी भी आवश्यक दस्तावेज के गैर-उत्पादन और/या झूठे, गलत, भ्रामक या धोखाधड़ी वाले दस्तावेज प्रस्तुत करने की स्थिति में इस कार्यक्रम के तहत सभी लाभों को रद्द करने और पुनर्प्राप्त करने का अधिकार सुरक्षित है। नामांकित/पंजीकृत सदस्यों द्वारा दी गई जानकारी।</li>
				  <li>पंजीकृत सदस्य कार्यक्रम में या अन्यथा किए जा रहे किसी भी अनुचित व्यापार व्यवहार के अवलोकन/रिपोर्ट पर कार्यक्रम और ऐसे अन्य कार्यक्रमों/प्रोत्साहन योजनाओं से अयोग्यता/निष्कासन के लिए उत्तरदायी है और उसकी इनाम राशि भी जब्त की जा सकती है। जो अंतिम, निर्णायक और बाध्यकारी होगा और विवाद या पूछताछ के लिए उत्तरदायी नहीं होगा।</li>
				  <li>फिनोलेक्स पाइप्स & फिटिंग्स योजना में वापसी या बदलाव या किसी अन्य कारण से पंजीकृत सदस्यों सहित किसी भी व्यक्ति को होने वाली किसी भी हानि या क्षति के लिए उत्तरदायी नहीं होगा, चाहे वह प्रत्यक्ष या अप्रत्यक्ष हो।</li>
				  <li>सभी पंजीकृत सदस्यों को फिनोलेक्स पाइप्स & फिटिंग्स द्वारा निर्धारित पूर्व-निर्धारित पुरस्कार मैट्रिक्स के अनुसार पुरस्कृत किया जाएगा।</li>
				  <li>वर्तमान कार्यक्रम के तहत अंक या कोई अन्य लाभ गैर-हस्तांतरणीय हैं।</li>
				  <li>सभी पंजीकृत सदस्य अपरिवर्तनीय रूप से स्वीकार करते हैं कि सभी नियम और शर्तें उनके द्वारा पूरी तरह से पढ़ी और समझी गई हैं।</li>
				  <li>फिनोलेक्स पाइप्स & फिटिंग्स उपहार/कैटलॉग सूची में प्रदान किए गए तीसरे पक्ष के सामान पर गुणवत्ता, बिक्री के बाद सेवा, वारंटी, प्रदर्शन आदि जैसी किसी भी प्रकार की जिम्मेदारी नहीं लेता है।</li>
				  <li>फिनोलेक्स पाइप्स & फिटिंग्स सदस्यों द्वारा प्रविष्टियों के प्रसारण या प्रस्तुतिकरण में किसी भी तकनीकी, या भौतिक देरी या किसी भी व्यवधान, हानि, क्षति, कंप्यूटर से संबंधित खराबी / विफलताओं के लिए उत्तरदायी नहीं होगा जो सदस्यों की भागीदारी, या किसी भी बल को प्रभावित करता है। दैवीय कृत्य, सरकारी कार्यों/प्रतिबंधों/लॉकडाउन, महामारी/महामारी और फिनोलेक्स पाइप्स & फिटिंग्स द्वारा नियुक्त तीसरे पक्षों द्वारा किए गए कृत्यों, त्रुटियों, चूक के कारण होने वाली अप्रत्याशित स्थितियां या क्षति।</li>
				  <li>फिनोलेक्स पाइप्स & फिटिंग्स विभिन्न कारणों जैसे जलवायु परिस्थितियों, श्रमिक अशांति, दिवालियापन, व्यावसायिक अत्यावश्यकताओं, या परिचालन और तकनीकी मुद्दों या किसी अन्य मुद्दे के कारण प्रदर्शन में देरी के कारण स्टॉक की अनुपलब्धता के लिए भी जिम्मेदार नहीं होंगे। कार्यक्रम।</li>
				  <li>पॉइंट्स के उपयोग या गैर-उपयोग के बारे में उत्पन्न होने वाले किसी भी विवाद और संघर्ष को फिनोलेक्स पाइप्स & फिटिंग्स को सूचित किया जाएगा। इसके बाद फिनोलेक्स पाइप्स & फिटिंग्स विवाद को जल्द से जल्द सुलझाने के लिए समिति की नियुक्ति/अधिकृत करेगा। वर्तमान कार्यक्रम पुरस्कार साझा करने और व्यावसायिक लेनदेन में पारदर्शिता लाने के माध्यम से प्लंबरों को प्रोत्साहित करने के लिए चलाया जाता है। प्लंबर के प्रति किसी भी अनिवार्य दायित्व के लिए यह फिनोलेक्स पाइप्स & फिटिंग्स का कानूनी दायित्व नहीं है। इसलिए, प्लंबर इसे अपने अधिकार या कानूनी देय के रूप में दावा नहीं करेंगे। फिनोलेक्स पाइप्स & फिटिंग्स प्लंबरों को बिना किसी पूर्व सूचना के पुरस्कार प्रणाली को बदलने, संशोधित करने और बदलने के सभी विवेकाधीन अधिकार रखता है और सुरक्षित रखता है।</li>
				  <li>विवाद, यदि कोई हो, पुणे क्षेत्राधिकार के अधीन हैं।</li>
				</ol>
			</div>
		</>
	)
}
