import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
export default function ComingSoon() {
    return (
        <>
            <Header />
            <section className="blog-area pt182">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <h2>Coming Soon</h2>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
