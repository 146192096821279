import React, { useState, useEffect } from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import longarrow from '../../../../assets/img/longarrow.svg'
import whitearrow from '../../../../assets/img/whitearrow.svg'
import { useNavigate } from 'react-router-dom'
import * as Constants from "../../../../Constants/index"
import axios from 'axios'
import Oval from '../../Loader/CircleLoade';
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/minimal.css';
export default function Rejected() {
    const navigate = useNavigate()
    let monthName = localStorage.getItem('month_name')
    const [results, setResults] = useState([])
    const [data, setData] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchRecord, setSearchRecord] = useState()
    const [searchUser, setSearchUser] = useState('')
    const [bankDetailShow, setBankDetailShow] = useState('')
    const [registartionShow, setRegistartionShow] = useState(false)
    const [limit] = useState('10')




    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        //pending registration data 
        if (currentPage !== 1) {
            rejectedRegistrationData(searchUser);
        } else {
            rejectedRegistrationData('');
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage]);


    const rejectedRegistrationData = async (userName) => {
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/')
        } else {
            const searchUserVal = userName === '' ? '' : `&name=${userName}`
            try {
                setLoading(true);
                await axios.get(Constants.baseUrl + `/user/month_year/user?monthYear=${monthName}${searchUserVal}&userType=MEMBER&status=REJECT&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        program: Constants.program,
                    }
                }).then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setLoading(false);
                            setResults(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                        } else if (res.data.code === 12002) {
                            setLoading(false);
                            setSearchRecord(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);

                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })

            } catch (error) {
                console.log(error)
            }
        }
    };

    const handleShowReg = async (id) => {
        let token = localStorage.getItem('token');

        try {
            // setRegistartionShow(false)
            setLoading(true);
            await axios.get(Constants.baseUrl + `user/manage/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            }).then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 10001) {
                        setData(res.data.output)
                        setLoading(false);
                        window.scrollTo({
                            top: 1000,
                            behavior: 'smooth',
                        });
                        setRegistartionShow(true)
                        if (data.bankDetail.bankAccountNo !== '' && data.bankDetail.upiId === '') {
                            setBankDetailShow('bankdetails')
                        }
                        if (data.bankDetail.bankAccountNo === '' && data.bankDetail.upiId !== '') {
                            setBankDetailShow('upidetails')
                        }

                    } else {
                        // swal({
                        //     text: res.data.msg,
                        //     icon: 'error',
                        //     timer: 2000,
                        //     buttons: false,
                        // })
                    }

                } else {
                    // swal({
                    //     text: res.data.msg,
                    //     icon: 'error',
                    //     timer: 2000,
                    //     buttons: false,
                    // })
                }
            });
        } catch (error) {
            console.log(error)
        }
    }
    const handleSearchQuery = (event) => {
        setSearchUser(event.target.value)
        if (event.target.value === '') {
            rejectedRegistrationData('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            rejectedRegistrationData(searchUser);
        }
    };
    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            {loading ? <Oval /> : null}

            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Rejected  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15 callwell">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <input className="frmcntrl" placeholder="Name/Mobile Number" type="text" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <button type="button" className="btn login_white_btn" disabled={searchUser.trim() === ''}
                                            onClick={() => rejectedRegistrationData(searchUser)}> Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Plumber User ID</th>
                                                            <th>Plumber Name</th>
                                                            <th>City </th>
                                                            <th>Reason</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="6" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                const reason = item.approval ? item.approval.reason : null

                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item.id}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.addressWork.workCity}</td>
                                                                            <td>{reason}</td>
                                                                            <td><button className="btn login_bl_btn" onClick={() => handleShowReg(item.id)}>View Details <span><img src={whitearrow} alt="" /></span></button></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {registartionShow === true ?
                <section className="blog-area pt30">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 tab mb-20">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Plumber Details</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="all-single-blog rpt m-b-1">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Name</label>
                                                <span className="form-control"  >{data.name}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Mobile No.</label>
                                                <span className="form-control"> {data.mobile}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Alternate Mobile Number</label>
                                                <span className="form-control">{data.alternateMobile}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> Whatsapp Number</label>
                                                <span className="form-control">{data.whatsapMobile}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>State</label>
                                                <span className="form-control">{data.addressWork.workState}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>District</label>
                                                <span className="form-control">{data.addressWork.workdistrict}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>City</label>
                                                <span className="form-control">{data.addressWork.workCity}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Pin Code</label>
                                                <span className="form-control">{data.addressWork.workPincode}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>Gender</label>
                                                <span className="form-control">{data.gender}</span>
                                            </div>
                                        </div>
                                        <div className='col-md-3 col-sm-12 col-xs-12'>
                                            <div className="form-group has-float-label style-two" >
                                                <label className='dob-100'>D.O.B</label>
                                                <span className="form-control">{data.dob}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Selfie</label>
                                                <span className="form-control">{data.profilePic !== '' ? <Link to={data.profilePic} target="_blank"><span><i className="fa fa-eye">View</i></span></Link> : 'no image'}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>12 Digit Aadhaar No.*</label>
                                                <span className="form-control">{data.userModelKYC.aadharNumber}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Aadhaar Front Image*</label>
                                                <span className="form-control">{data.userModelKYC.aadharFrontUrl !== '' ? <Link to={data.userModelKYC.aadharFrontUrl} target="_blank"><span><i className="fa fa-eye">View</i></span></Link> : ''}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Aadhaar Back Image*</label>
                                                <span className="form-control">{data.userModelKYC.aadharBackUrl !== '' ? <Link to={data.userModelKYC.aadharBackUrl} target="_blank"><span><i className="fa fa-eye">View</i></span></Link> : 'no image'}</span>
                                            </div>

                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label> PAN No.</label>
                                                <span className="form-control" >{data.panNumber}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>PAN Image</label>
                                                <span className="form-control">{data.userModelKYC.panFrontUrl !== '' ? <Link to={data.userModelKYC.panFrontUrl} target="_blank"><span><i className="fa fa-eye">View</i></span></Link> : 'no image'}</span>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <div className="h34">
                                                    {bankDetailShow === 'bankdetails' ?
                                                        <label className="radio-inline">
                                                            <span id="chckbanking" name="PaymentMode" type="checkBox" checked={bankDetailShow === 'bankdetails'} value='bankdetails' />
                                                            <span> Bank Details</span>
                                                        </label> : ''}
                                                    {bankDetailShow === 'upidetails' ?
                                                        <label className="radio-inline">
                                                            <span id="chckupi" name="PaymentMode" type="checkBox" checked={bankDetailShow === 'upidetails'} value='upidetails' /> <span> UPI Details</span>
                                                        </label> : ''}
                                                </div>
                                            </div>
                                        </div> */}
                                        {bankDetailShow === 'bankdetails' ?
                                            <div className="bankdetails" id="bankdetails">
                                                <div className="col-md-12">
                                                    <h4 className="heading-panel">Bank Details</h4>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Account Number*</label>
                                                        <span className="form-control" >{data.bankDetail.bankAccountNo} </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Account Holder Name*</label>
                                                        <span className="form-control">{data.bankDetail.holderName}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Account Type*</label>
                                                        <span className="form-control" >{data.bankDetail.accountType}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Bank Name*</label>
                                                        <span className="form-control"> {data.bankDetail.bankName}</span>
                                                    </div>

                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>IFSC Code*</label>
                                                        <span className="form-control">{data.bankDetail.ifsc}</span>
                                                    </div>

                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Passbook/Cancelled Cheque*</label>
                                                        <span className="form-control" id="" name="" type="file" accept=".jpg, .jpeg, .png, .svg" />
                                                    </div>
                                                </div>
                                            </div> : ''}
                                        {bankDetailShow === 'upidetails' ?
                                            <div id="upidetails">
                                                <div className="col-md-12">
                                                    <h4 className="heading-panel">UPI Transfer</h4>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Enter your UPI ID*</label>
                                                        <span className="form-control" >{data.bankDetail.upiId}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>UPI ID Screenshot*</label>
                                                        <span className="form-control">{data.bankDetail.upiIdScreen !== '' ? <Link to={data.bankDetail.upiIdScreen} target="_blank"><span><i className="fa fa-eye">View</i></span></Link> : 'no image'}</span>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section > : ''
            }
            <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>

            <Footer />
        </>
    )
}
