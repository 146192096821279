import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import ResponsivePagination from "react-responsive-pagination";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import longarrow from "../../../assets/img/longarrow.svg";
import Oval from "../Loader/CircleLoade";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
export default function BlockedMembReport() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});

  const searchFormik = useFormik({
    initialValues: {
      mobile: "",
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.mobile) {
        if (data.mobile.length < 10) {
          errors.mobile = "Phone number must be in 10 digits.";
        }
      }
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      // if (data.startDate && data.endDate) {
      //     const maxEndDate = new Date(data.startDate)
      //     maxEndDate.setDate(maxEndDate.getDate() + 30) // Adjust the range as needed (e.g., 31 days)

      //     if (data.endDate > maxEndDate) {
      //         errors.endDate = 'End date should be within 31 days from the start date.'
      //     }
      // }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getReportData(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getReportData = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      const searchUser = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }
      await axios
        .get(
          Constants.baseUrl +
            `reports/BlockedUsers/list?userType=MEMBER${searchUser}${stDate}${endDate}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            console.log("fkjashdkjfha", dt?.output?.results);
            setReportData(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error") {
            setReportData(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  const download = (data) => {
    setLoading(true);
    try {
      const searchUser = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      axios
        .get(
          Constants.baseUrl +
            `reports/download/blockedUsers?userType=MEMBER${searchUser}${stDate}${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "blockedMemberReport.csv");
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const areAllValuesBlank = () => {
    const { mobile, startDate, endDate } = searchFormik.values;
    return !mobile && !startDate && !endDate;
  };
  return (
    <>
      {loading ? <Oval /> : null}
      <Header />
      <section class="blog-area pt182">
        <div class="container">
          <div class="row">
            <div class="col-md-12 tab mb-20">
              <ul class="nav nav-tabs" role="tablist">
                <li role="presentation" class="active">
                  <a href="#Section1" role="tab" data-toggle="tab">
                    Blocked Member Report
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="all-single-blog rpt">
                <form onSubmit={searchFormik.handleSubmit}>
                  <div class="row clearfix mb-15 callwell">
                    <div class="col-md-3 col-sm-12 col-xs-8">
                      <input
                        id="mobile"
                        value={searchFormik.values.mobile}
                        onChange={(e) => {
                          // Allow only numeric input
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          searchFormik.handleChange({
                            target: {
                              name: "mobile",
                              value: numericValue,
                            },
                          });
                        }}
                        type="text"
                        className="frmcntrl"
                        placeholder="Mobile Number"
                        maxLength="10"
                      />
                      {getSearchFormErrorMessage("mobile")}
                    </div>
                    <div class="col-md-3 col-sm-12 col-xs-8">
                      <DatePicker
                        id="startDate"
                        selected={searchFormik.values.startDate}
                        onChange={(date) =>
                          searchFormik.setFieldValue("startDate", date)
                        }
                        dateFormat="dd-MM-yyyy"
                        className="frmcntrl"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="From date"
                      />
                      {getSearchFormErrorMessage("startDate")}
                    </div>
                    <div class="col-md-3 col-sm-12 col-xs-8">
                      <DatePicker
                        id="endDate"
                        selected={searchFormik.values.endDate}
                        onChange={(date) =>
                          searchFormik.setFieldValue("endDate", date)
                        }
                        dateFormat="dd-MM-yyyy"
                        className="frmcntrl"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="To date"
                      />
                      {getSearchFormErrorMessage("endDate")}
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-4">
                      <button
                        class="btn login_white_btn"
                        type="submit"
                        disabled={areAllValuesBlank()}
                      >
                        {" "}
                        Search <img src={longarrow} alt="" />
                      </button>
                    </div>
                  </div>
                </form>
                <div class="row">
                  <div class="col-md-12">
                    {reportData === null ||
                    userType === "RETAILER" ||
                    userType === "SALES" ? (
                      ""
                    ) : (
                      <button
                        class="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10"
                        type="submit"
                        onClick={() => download(searchFormik.values)}
                      >
                        {" "}
                        Export
                      </button>
                    )}
                    <div class="carrd1">
                      <div class="card-body table-responsive">
                        <table class="table table-bordered mytable mb_01">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>ZSM Name</th>
															<th>ZSM Mobile</th>
															<th>RSM Name</th>
															<th>RSM Mobile Number</th>
															<th>ASM Name</th>
															<th>ASM Mobile Number</th>
															<th>TSE Name</th>
															<th>TSE Mobile Number</th>
															<th>Retailer Code</th>
															<th>Retailer Name</th>
															<th>Retailer Mobile</th>
															<th>Retailer GST No</th>
															<th>Retailer State</th>
															<th>Retailer City</th>
															<th>Retailer District</th>
															<th>Retailer Pin Code</th>
															<th>Plumber Name</th>
															<th>Plumber Unique ID (Mobile Number)</th>
															<th>City</th>
															<th>District</th>
															<th>State</th>
															<th>Pincode</th>
															<th>Date Of Registration</th>
															<th>Total Points</th>
															<th>Total Redeem Points</th>
															<th>Total Balance Points</th>
															<th>Blocked Date</th>
                            </tr>
                          </thead>

                          {reportData === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="27"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {reportData &&
                                reportData.map((item, index) => {
                                  const serialNumber =
                                    (currentPage - 1) * limit + index + 1;
                                  const dateOfReg = item[16]
                                    ? moment(item[16]).format("DD-MM-YYYY")
                                    : "";
                                  return (
                                    <>
                                      <tr>
                                        <td>{serialNumber}</td>
                                        <td>{item[0]}</td>
                                        <td>{item[1]}</td>
                                        <td>{item[2]}</td>
                                        <td>{item[3]}</td>
                                        <td>{item[4]}</td>
                                        <td>{item[5]}</td>
                                        <td>{item[6]}</td>
                                        <td>{item[7]}</td>
                                        <td>{item[8]}</td>
                                        <td>{item[9]}</td>
                                        <td>{item[10]}</td>
                                        <td>{item[11]}</td>
                                        <td>{item[12]}</td>
                                        <td>{item[13]}</td>
                                        <td>{item[14]}</td>
                                        <td>{item[15]}</td>
                                        <td>{item[16]}</td>
                                        <td>{item[17]}</td>
                                        <td>{item[18]}</td>
                                        <td>{item[19]}</td>
                                        <td>{item[20]}</td>
                                        <td>{item[21]}</td>
                                        <td>{item[22]}</td>
																				<td>{item[23]}</td>
																				<td>{item[24]}</td>
																				<td>{item[25]}</td>
																				<td>{item[26]}</td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => {
                              setCurrentPage(page);
                              getReportData(filterData, page);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
