import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import axios from 'axios';
import * as Constants from "../../../Constants/index"
import Oval from '../../Pages/Loader/CircleLoade';
export default function TncRetailer() {
    const [loading, setLoading] = useState(false);
    // const [tncUrl, setTncUrl] = useState('')



    useEffect(() => {
        let token = localStorage.getItem('token');
        try {
            setLoading(true);
            axios.get(Constants.baseUrl + 'program/child', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept-Language": localStorage.getItem("langauge"),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    program: Constants.program,
                    company: Constants.company
                }
            })
                .then((res) => {
                    if (res.status === 201 && res.data.output) {
                        setLoading(false);
                        // {
                        //     res.data.output.results.map((item) => {
                        //         setTncUrl(item.tnc)
                        //     })
                        // }
                    } else {
                        // swal(res.data.msg);
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }, [])
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">General Terms And Conditions </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            {/* <iframe src={tncUrl} width={'100%'} height={'400px'} frameborder="0"></iframe> */}

                            <div className="all-single-blog rpt">
                                <div className="carrd1 requestbox">
                                    <div className="card-body pad30">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="terms-condition-iframe " >
                                                    <ol>
                                                        <li>This Finolex Pipes Super Plumber Loyalty Program is exclusively designed only for PAN India plumbers considered by FINOLEX PIPES.   </li>
                                                        <li>The program is starting from 16th Oct and participation in the program is voluntary.  </li>
                                                        <li>Registration of a plumber in the program is voluntary.</li>
                                                        <li>All users/members have read the program terms and conditions and privacy policy in detail and agree to abide by them.</li>
                                                        <li>All the users / members of this platform and loyalty program have agreed and provided their consent to EBPL and FIL, in accordance with the Digital Personal Data Protection Act, 2023 to use their personal data such as Name, Mobile number, photograph, Aadhaar Card, Pan Card, for processing of the said personal data by EBPL/ FIL and the right to disclose the aggregate Registration Data to third parties for its legitimate business purposes including but not limited to targeting of advertisements and other Service offers</li>
                                                        <li>Redemption can be done through online redemption options.</li>
                                                        <li>Products (covered under this Program) purchased from Authorized Dealers and retailers of FINOLEX PIPES will be considered for this Program</li>
                                                        <li>This Program cannot be clubbed with any other scheme/ program/offer unless approved in writing by FINOLEX PIPES</li>
                                                        <li>All plumbers are eligible for registration in the Finolex Pipes Super Plumber Loyalty program, subject to validation from the FINOLEX PIPES sales team. Registering/registered member must not be FINOLEX PIPES employee, oﬃcer, or agent, or an employee, oﬃcer, or agent of any person or organization involved in the running of the program, and must not be a family relation of any such person. </li>
                                                        <li>All registration validation is a must in the Finolex Pipes Super Plumber loyalty program to enjoy program benefits</li>
                                                        <li>Any benefit or reward that comes under THE LOTTERIES (REGULATION) ACT, 1998 will not be applicable for the members registered and operating in the state of Tamil Nadu The value of 1 Points will be INR 0.02/-.  </li>
                                                        <li>Accrued Points will be redeemed against Paytm wallet, UPI, Bank transfer products, and e-vouchers. Physical products are available in the reward catalog until any further condition is applied by FINOLEX PIPES.</li>
                                                        <li>No loyalty reward Points shall be awarded on any duplicate invoice uploading.</li>
                                                        <li>Neither this program nor its terms and conditions shall be construed as a joint venture, agency relationship, or as granting of a franchisee.</li>
                                                        <li>FINOLEX PIPES reserves the right to amend or withdraw the “Finolex  Pipes Super Plumber Loyalty Program” at any point in time without giving any reason(s) and intimation(s) for the same and decision of FINOLEX PIPES in this regard is considered as final and binding.</li>
                                                        <li>FINOLEX PIPES reserves the right to revoke and recover all the benefits under this Program in the event of the non-production of any of the necessary documents and/or submission of the false, incorrect, misleading, or fraudulent document(s)/ information furnished by enrolling/registered members. </li>
                                                        <li>The registered member is liable for disqualification/ expulsion from the program and other such programs/ incentive schemes, on the observation/report of any unfair trade practices being carried out in the program or otherwise and his/her reward amount can also be forfeited which shall be final, conclusive, and binding and will not be liable to be disputed or questioned. </li>
                                                        <li>FINOLEX PIPES will not be liable for any loss or damage, whether direct or indirect, caused to any person including registered members due to withdrawal or change in the scheme or for any other reasons.</li>
                                                        <li>All registered members will be rewarded as per the pre-defined awarding matrix set by FINOLEX PIPES </li>
                                                        <li>The Points or any other benefits under the present Program are non-transferable. </li>
                                                        <li>All registered members irrevocably acknowledge that all the terms and conditions have been fully read and understood by them.</li>
                                                        <li>FINOLEX PIPES does not take any type of responsibility such as quality, after-sales service, warranty, performance, etc. on the third-party goods provided in the gift/catalog list. </li>
                                                        <li>FINOLEX PIPES shall not be liable for any technical, or physical delay in transmission or submission of the entries by the members or any disruptions, losses, damages, computer-related malfunctions/ failures which affect the participation of the members, or any force majeure conditions or damages caused by Act of God, Governmental actions/restrictions/lockdown, pandemic/epidemic and for the acts, errors, omission done by third parties appointed by FINOLEX PIPES </li>
                                                        <li>FINOLEX PIPES shall also not be responsible for the non-availability of Stock(s) due to various reasons like climatic conditions, labour unrest, insolvency, business exigencies, or operational & technical issues or for any other issues causing delay in performance under the programme.</li>
                                                        <li>Any disputes, controversies, and conflicts arising about the usage or non-usage of Points shall communicate to FINOLEX PIPES. FINOLEX PIPES will then appoint/authorise the committee to resolve the dispute at the earliest. The present program runs to encourage plumbers by way of sharing rewards and bringing transparency to the business transaction. It is not FINOLEX PIPES legal liability for any compulsory obligation towards plumbers. Therefore, Plumbers shall not claim it as their right or legal due. FINOLEX PIPES holds and reserves all discretionary rights to change, modify and alter the rewards system without any prior notice to Plumbers.</li>
                                                        <li>Disputes, if any are subject to Pune Jurisdiction.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
