import React, { useState, useEffect } from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import longarrow from '../../../../assets/img/longarrow.svg'
import cashmemo from '../../../../assets/img/cashmemo.png'
import close from '../../../../assets/img/close.svg'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import * as Constants from "../../../../Constants/index"
import axios from 'axios'
import Oval from '../../Loader/CircleLoade';
import swal from 'sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/minimal.css';
import { Dialog } from 'primereact/dialog';
export default function PendingForApprovalRetailer() {
    const navigate = useNavigate()
    let gst = localStorage.getItem('gst')
    let mobile = localStorage.getItem('user_mobile')
    let monthName = localStorage.getItem('month_name')
    const [results, setResults] = useState([])
    const [idData, setIdData] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [isVisible, setIsVisible] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [status, setStatus] = useState('')
    const [reason, setReason] = useState('')
    const [remarks, setRemarks] = useState('')
    const [loading, setLoading] = useState(false);
    const [searchRecord, setSearchRecord] = useState()
    const userType = localStorage.getItem('user_type')
    const [searchUser, setSearchUser] = useState('')
    const [limit] = useState('10')

    const [invoiceUrl1, setInvoiceUrl1] = useState('')
    const [invoiceUrl2, setInvoiceUrl2] = useState('')
    const [invoiceUrl3, setInvoiceUrl3] = useState('')
    const [invoiceUrl4, setInvoiceUrl4] = useState('')


    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        if (currentPage !== 1) {
            pendingRegistrationData(searchUser);
        } else {
            pendingRegistrationData('');
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage, status]);

    //pending registration data 
    const pendingRegistrationData = async (userName) => {
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/')
        } else {
            const searchUserVal = userName === '' ? '' : `&name=${userName}`

            try {
                let endpoint = '';

                if (userType === 'RETAILER') {
                    endpoint = `capture/invoice/month_year/allinvoice?gst=${gst}&mobile=${mobile}&monthYear=${monthName}&status=PENDING${searchUserVal}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`;
                }
                // else if (userType === 'SH' || userType === 'RO' || userType === 'HO') {
                //     endpoint = `user/manage/userByparentId?${searchUserVal}&userType=MEMBER&status=VERIFIED&sortBy=createDateTime&sortAsc=false&limit=10&page=${currentPage}`;
                // }
                setLoading(true);
                await axios.get(Constants.baseUrl + endpoint, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        program: Constants.program,
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001) {
                                setResults(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                                setLoading(false);
                            } else if (res.data.code === 12002) {
                                setSearchRecord(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                                setLoading(false);
                            } else {
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    timer: 2000,
                                    buttons: false,
                                })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })
            } catch (error) {
                console.log(error)
            }
        }
    };

    // select registraion form for approved or rejected
    const handleSelect = async (id) => {
        let token = localStorage.getItem('token');

        try {
            await axios.get(Constants.baseUrl + `capture/invoice/manage/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            const data = res.data.output;
                            setIdData(data)
                            setInvoiceUrl1(data.invoiceUrl1)
                            setInvoiceUrl2(data.invoiceUrl2)
                            setInvoiceUrl3(data.invoiceUrl3)
                            setInvoiceUrl4(data.invoiceUrl4)
                            setModalShow(true)
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelection = (event) => {
        setStatus(event.target.value)
        setReason('')
        setRemarks('');

    }
    // update registration by approved or reject
    const updateRegistration = async (id) => {
        let token = localStorage.getItem('token');
        if (status === '') {
            swal({
                text: 'Please select a status',
                icon: 'error',
                timer: 2000,
                buttons: false,
            });
            return; // Return early if reason is not selected when status is 'reject'
        }
        if (status === 'REJECT' && reason === '') {
            swal({
                text: 'Please select a reason',
                icon: 'error',
                timer: 2000,
                buttons: false,
            });
            return; // Return early if reason is not selected when status is 'reject'
        }

        if (reason === 'OTHER' && remarks === '') {
            swal({
                text: 'Please enter remarks',
                icon: 'error',
                timer: 2000,
                buttons: false,
            });
            return; // Return early if reason is 'other' but remarks are not entered
        }
        try {
            await axios.post(Constants.baseUrl + `capture/invoice/manage/updatestatus/${id}`, {
                status: status,
                reason: reason,
                remarks: remarks
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            setModalShow(false)
                            setStatus('')
                            setReason('')
                            setRemarks('')
                            swal({
                                text: res.data.msg,
                                icon: 'success',
                                timer: 2000,
                                buttons: false,
                            });
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            });
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        });
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }
    const handleSearchQuery = (event) => {
        setSearchUser(event.target.value)
        if (event.target.value === '') {
            pendingRegistrationData('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            pendingRegistrationData(searchUser);
        }
    };
    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const closeModal = () => {
        setModalShow(false)
        setStatus('')
        setReason('')
        setRemarks('')
    }
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="#Section1" role="tab" data-toggle="tab">Pending for Approval  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15 callwell">
                                    <div className="col-md-3 col-sm-12 col-xs-8">
                                        <input className="frmcntrl" placeholder="Name/Mobile Number" type="text" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-4">
                                        <button type="button" className="btn login_white_btn" disabled={searchUser.trim() === ''}
                                            onClick={() => pendingRegistrationData(searchUser)}> Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Details</th>
                                                            <th>Date of Upload</th>
                                                            <th>Plumber Name</th>
                                                            <th>Plumber Mobile No. </th>
                                                            <th>Invoice Amount </th>
                                                            <th>Plumber Claim ID</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="8" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td><Link to="#" className="select" onClick={() => handleSelect(item.id)} > Select</Link></td>
                                                                            <td>{item.createDateTime}</td>
                                                                            <td>{item.user.name}</td>
                                                                            <td>{item.user.mobile}</td>
                                                                            <td>{item.invoiceAmount}</td>
                                                                            <td>{item.id}</td>
                                                                        </tr >
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <div className="card flex justify-content-center">
                <Dialog visible={modalShow} showHeader={false}
                    style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                    <div className="modal-dialog">
                        <div className="modal-content pop-content400">
                            <div className="modal-header">
                                <img src={close} data-dismiss="modal" className="close closeouter" alt="" onClick={closeModal} />
                            </div>
                            <div className="modal-body">
                                <div className="modal-details">
                                    <div className="row">
                                        {invoiceUrl1 !== '' ?
                                            <div className="col-lg-12  col-md-12">
                                                <div className='pending_days'>
                                                    <div className='left_side'>
                                                        <p>Pending of Days</p>
                                                    </div>
                                                    <div className='right_side'>
                                                        <p>{idData?.pendingInvoiceDay}</p>
                                                    </div>
                                                </div>
                                                <figure className="bill">
                                                    <Link to={invoiceUrl1} target='_blank'>  <img src={invoiceUrl1} alt='' /></Link>
                                                </figure>
                                            </div> : ''}
                                        {invoiceUrl2 !== '' ?
                                            <div className="col-lg-12  col-md-12">
                                                <figure className="bill">
                                                    <Link to={invoiceUrl2} target='_blank'>  <img src={invoiceUrl2} alt='' /></Link>
                                                </figure>
                                            </div> : ''}
                                        {invoiceUrl3 !== '' ?
                                            <div className="col-lg-12  col-md-12">
                                                <figure className="bill">
                                                    <Link to={invoiceUrl3} target='_blank'>  <img src={invoiceUrl3} alt='' /></Link>
                                                </figure>
                                            </div> : ''}
                                        {invoiceUrl4 !== '' ?
                                            <div className="col-lg-12  col-md-12">
                                                <figure className="bill">
                                                    <Link to={invoiceUrl4} target='_blank'>  <img src={invoiceUrl4} alt='' /></Link>
                                                </figure>
                                            </div> : ''}
                                    </div>
                                    <div className="row card pad15 mauto">
                                        {invoiceUrl1 === '' && invoiceUrl2 === '' && invoiceUrl3 === '' && invoiceUrl4 === '' ? '' :
                                            <div className="col-lg-12 col-md-12">
                                                <h4 className="heading">Proof of Purchase Status</h4>
                                            </div>}
                                        <div className="col-lg-12 form-group col-md-12">
                                            <label> Status<span style={{ color: 'red' }}></span></label>
                                            <select className="form-control2" id="status" value={status} onChange={handleSelection}>
                                                <option value=''>Select Status</option>
                                                <option value="APPROVED">Approve</option>
                                                <option value="REJECT">Reject</option>
                                            </select>
                                        </div>
                                        {status === 'REJECT' ?
                                            <div id="rejectDiv" className="col-lg-12 form-group col-md-12 custom-div">
                                                <label>Rejection Reason</label>
                                                <select className="form-control2" value={reason} onChange={(event) => setReason(event.target.value)}>
                                                    <option value=''>Select Reason</option>
                                                    <option value="Invoice Not Clear">Invoice Not Clear</option>
                                                    <option value="Invoice Amount Mismatch" >Invoice Amount Mismatch</option>
                                                    <option value="Invoice Quantity Mismatch">Invoice Quantity Mismatch</option>
                                                    <option value="Retailer Name Mismatch">Retailer Name Mismatch</option>
                                                    <option value="Invoice Date Mismatch">Invoice Date Mismatch</option>
                                                    <option value="OTHER">Other</option>
                                                </select>
                                            </div> : ''}
                                        {reason === 'OTHER' && status === 'REJECT' ?
                                            <div id="remarkDiv" className="col-lg-12 form-group col-md-12 custom-div">
                                                <label>Enter Remark</label>
                                                <textarea className="form-control txtreason" cols="60" rows="4" placeholder="Enter your remarks here" value={remarks} onChange={(e) => setRemarks(e.target.value)}></textarea>
                                            </div> : ''}
                                        <div className="form-group col-lg-12 col-md-12 text-center">
                                            <input type="submit" className="btn login_blue_btn" value="Cancel" onClick={closeModal} />
                                            <input type="submit" className="btn login_yellow_btn" value="Confirm" onClick={() => updateRegistration(idData.id)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Dialog>
            </div>
            <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>

            <Footer />
        </>
    )
}
