import React from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import scheme1 from '../../../../assets/img/scheme1.png'
import wintersale from '../../../../assets/img/wintersale.png'
import whitearrow from '../../../../assets/img/whitearrow.svg'
import { Link } from 'react-router-dom'
export default function SchemesOffers() {
    return (
        <>
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="#Section1" role="tab" data-toggle="tab">Scheme & Offer</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="schemebox">
                                            <div className="scheme_title">
                                                <h5>Super Combos Offer </h5>
                                                <p>3 Month, 6 Draws (2 monthly draw)</p>
                                            </div>
                                            <div className="scheme_image">
                                                <img className="img-fluid" src={scheme1} alt="images not found" />
                                            </div>
                                            <div className="moreinfo"><Link to="#" className="btn login_bl_btn"> More Info... <img src={whitearrow} alt='' /> </Link></div>

                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="schemebox">
                                            <div className="scheme_title">

                                                <h5>Winter Sale Offer  </h5>
                                                <p>3 Month, 6 Draws (2 monthly draw)</p>
                                            </div>
                                            <div className="scheme_image">
                                                <img className="img-fluid" src={wintersale} alt="images not found" />
                                            </div>
                                            <div className="moreinfo"><Link to="#" className="btn login_bl_btn"> More Info... <img src={whitearrow} alt='' /> </Link></div>

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="schemebox">
                                            <div className="scheme_title">

                                                <h5>	Introductory Offer </h5>
                                                <p>3 Month, 6 Draws (2 monthly draw)</p>
                                            </div>
                                            <div className="scheme_image">
                                                <img className="img-fluid" src={scheme1} alt="images not found" />
                                            </div>
                                            <div className="moreinfo"><Link to="#" className="btn login_bl_btn"> More Info... <img src={whitearrow} alt='' /> </Link></div>

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="schemebox">
                                            <div className="scheme_title">
                                                <h5>Winter Coming Offer 3 Month</h5>
                                                <p>3 Month, 6 Draws (2 monthly draw)</p>
                                            </div>
                                            <div className="scheme_image">
                                                <img className="img-fluid" src={wintersale} alt="images not found" />
                                            </div>
                                            <div className="moreinfo"><Link to="#" className="btn login_bl_btn"> More Info... <img src={whitearrow} alt='' /> </Link></div>

                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <div className="schemebox">
                                            <div className="scheme_title">

                                                <h5>	Introductory Offer </h5>
                                                <p>3 Month, 6 Draws (2 monthly draw)</p>
                                            </div>
                                            <div className="scheme_image">
                                                <img className="img-fluid" src={scheme1} alt="images not found" />
                                            </div>
                                            <div className="moreinfo"><Link to="#" className="btn login_bl_btn"> More Info... <img src={whitearrow} alt='' /> </Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
