import React from 'react'

export default function Oriya() {
    return (
        <>
            <div className="terms">
                <ol>
                        <li> ଏହି ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସୁପର ପ୍ଲମ୍ବର ଲୟଲିଟି ପ୍ରୋଗ୍ରାମ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଦ୍ୱାରା ବିବେଚିତ ପ୍ୟାନ୍ ଇଣ୍ଡିଆ ପ୍ଲମ୍ବର ପାଇଁ ସ୍ୱତନ୍ତ୍ର ଭାବରେ ଡିଜାଇନ୍ କରାଯାଇଛି |</li>
                        <li>ଏହି କାର୍ଯ୍ୟକ୍ରମ ଅକ୍ଟୋବର 16 ରୁ ଆରମ୍ଭ ହେଉଛି ଏବଂ ଏହି କାର୍ଯ୍ୟକ୍ରମରେ ଅଂଶଗ୍ରହଣ ସ୍ୱେଚ୍ଛାକୃତ ଅଟେ।</li>
                        <li>ଏହି କାର୍ଯ୍ୟକ୍ରମରେ ପ୍ଲମ୍ବରଙ୍କ ରେଜିଷ୍ଟ୍ରେସନ୍ ସ୍ୱେଚ୍ଛାକୃତ ଅଟେ। </li>
                        <li>ସମସ୍ତ ଉପଭୋକ୍ତା/ସଦସ୍ୟ ପ୍ରୋଗ୍ରାମର ନିୟମ ଓ ସର୍ତ୍ତାବଳୀ ଏବଂ ଗୋପନୀୟତା ନୀତି ବିସ୍ତୃତ ଭାବରେ ପଢ଼ିଛନ୍ତି ଏବଂ ସେଗୁଡ଼ିକ ପାଳନ କରିବାକୁ ରାଜି ହୋଇଛନ୍ତି </li>
                        <li>ଡିଜିଟାଲ ପର୍ସନାଲ ଡାଟା ପ୍ରୋଟେକ୍ସନ ଆକ୍ଟ, 2023 ଅନୁଯାୟୀ ଏହି ପ୍ଲାଟଫର୍ମ ଏବଂ ଲୟାଲିଟି ପ୍ରୋଗ୍ରାମର ସମସ୍ତ ଉପଭୋକ୍ତା/ସଦସ୍ୟ EBPL ଏବଂ FILକୁ ସେମାନଙ୍କର ସମ୍ମତି ପ୍ରଦାନ କରିଛନ୍ତି, EBPL/FIL ଦ୍ୱାରା ଉକ୍ତ ବ୍ୟକ୍ତିଗତ ଡାଟା ପ୍ରକ୍ରିୟାକରଣ ପାଇଁ ନାମ, ମୋବାଇଲ୍ ନମ୍ବର, ଫଟୋ, ଆଧାର କାର୍ଡ, ପାନ୍ କାର୍ଡ ଏବଂ ଏହାର ବୈଧ ବ୍ୟବସାୟିକ ଉଦ୍ଦେଶ୍ୟ ପାଇଁ ତୃତୀୟ ପକ୍ଷଙ୍କୁ ସମୁଦାୟ ପଞ୍ଜୀକରଣ ଡାଟା ପ୍ରକାଶ କରିବାର ଅଧିକାର ପାଇଁ ରାଜି ହୋଇଛନ୍ତି, ଯାହା କେବଳ ବିଜ୍ଞାପନ ଏବଂ ଅନ୍ୟାନ୍ୟ ସେବା ଅଫରଗୁଡ଼ିକୁ ଟାର୍ଗେଟ କରିବାରେ ସୀମିତ ନୁହେଁ</li>
                        <li>ଅନଲାଇନ୍ ରିଡେମ୍ପସନ୍ ବିକଳ୍ପ ମାଧ୍ୟମରେ ରିଡେମ୍ପସନ୍ କରାଯାଇପାରିବ</li>
                        <li>ଫିନୋଲକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ସ ପ୍ରାଧିକୃତ ଡିଲର ଏବଂ ଖୁଚୁରା ବ୍ୟବସାୟୀଙ୍କଠାରୁ କିଣାଯାଇଥିବା ଉତ୍ପାଦଗୁଡିକ (ଏହି କାର୍ଯ୍ୟକ୍ରମ ଅଧୀନରେ ଅନ୍ତର୍ଭୁକ୍ତ) ଏହି କାର୍ଯ୍ୟକ୍ରମ ପାଇଁ ବିବେଚନା କରାଯିବ |</li>
                        <li> ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଦ୍ୱାରା ଲିଖିତ ଆକାରରେ ଅନୁମୋଦନ ନହେବା ପର୍ଯ୍ୟନ୍ତ ଏହି ପ୍ରୋଗ୍ରାମକୁ ଅନ୍ୟ କ scheme ଣସି ସ୍କିମ୍ / ପ୍ରୋଗ୍ରାମ / ଅଫର୍ ସହିତ ମିଶ୍ରଣ କରାଯାଇପାରିବ ନାହିଁ |</li>
                        <li>ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସୁପର ପ୍ଲମ୍ବର ଲୟାଲିଟି ପ୍ରୋଗ୍ରାମରେ ପଞ୍ଜୀକରଣ ପାଇଁ ଯୋଗ୍ୟ ସମସ୍ତ ପ୍ଲମ୍ବରମାନେ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ବିକ୍ରୟ ଦଳ ଦ୍ୱାରା ବ valid ଧତା ଅଧୀନରେ ରହିବେ | ପଞ୍ଜୀକୃତ / ପଞ୍ଜୀକୃତ ସଦସ୍ୟମାନେ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ କର୍ମଚାରୀ, ବିକ୍ରେତା, ଏଜେଣ୍ଟ କିମ୍ବା ପ୍ରୋଗ୍ରାମ ପରିଚାଳନାରେ ଜଡିତ କ person ଣସି ବ୍ୟକ୍ତି କିମ୍ବା ସଂଗଠନ ହେବା ଆବଶ୍ୟକ କରନ୍ତି ନାହିଁ ଏବଂ ଏହିପରି କ person ଣସି ବ୍ୟକ୍ତିଙ୍କ ପାରିବାରିକ ସମ୍ପର୍କ ହେବା ଉଚିତ୍ ନୁହେଁ |</li>
                        <li>ପ୍ରୋଗ୍ରାମର ଲାଭ ଉଠାଇବା ପାଇଁ ସମସ୍ତ ରେଜିଷ୍ଟ୍ରେସନ୍ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଂ ସୁପର ପ୍ଲମ୍ବର ଲୟଲିଟି ପ୍ରୋଗ୍ରାମରେ ବ valid ଧ ହେବା ଆବଶ୍ୟକ |</li>
                        <li> ତାମିଲନାଡୁ ରାଜ୍ୟରେ ପଞ୍ଜୀକୃତ ଏବଂ କାର୍ଯ୍ୟକରୁଥିବା ସଦସ୍ୟମାନଙ୍କ ପାଇଁ ଲଟେରିଜ୍ (ରେଗୁଲେସନ୍) ଆକ୍ଟ, 1998 ଅଧୀନରେ ଆସୁଥିବା କୌଣସି ଫାଇଦା କିମ୍ବା ରିୱାର୍ଡ ଲାଗୁ ହେବ ନାହିଁ। 1 ପଏଣ୍ଟର ମୂଲ୍ୟ 0.02/- ଟଙ୍କା ହେବ।</li>
                        <li> ପେଟିଏମ୍ ୱାଲେଟ୍, ୟୁପିଇ, ବ୍ୟାଙ୍କ ଟ୍ରାନ୍ସଫର ଉତ୍ପାଦ ଏବଂ ଇ-ଭାଉଚର ମାଧ୍ୟମରେ ଅର୍ଜନ ହୋଇଥିବା ପଏଣ୍ଟଗୁଡିକ ମୁକ୍ତ କରାଯିବ | ଫିନାଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଦ୍ୱାରା ଅଧିକ ବିଜ୍ notice ପ୍ତି ନହେବା ପର୍ଯ୍ୟନ୍ତ ଶାରୀରିକ ଉତ୍ପାଦଗୁଡ଼ିକ ପୁରସ୍କାର ତାଲିକାରେ ଉପଲବ୍ଧ ରହିବ</li>
                        <li> କୌଣସି ନକଲି ଚାଲାଣ ଅପଲୋଡ୍ ଉପରେ କୌଣସି ଲୟାଲିଟି ରିୱାର୍ଡ ପଏଣ୍ଟ ପ୍ରଦାନ କରାଯିବ ନାହିଁ।</li>
                        <li> ଏହି କାର୍ଯ୍ୟକ୍ରମ କିମ୍ବା ଏହାର ସର୍ତ୍ତାବଳୀକୁ ଏକ ଯୁଗ୍ମ ଉଦ୍ୟୋଗ, ଏଜେନ୍ସି ସମ୍ପର୍କ କିମ୍ବା ଫ୍ରାଞ୍ଚାଇଜି ପ୍ରଦାନ ଭାବରେ ବିବେଚନା କରାଯିବ ନାହିଁ। </li>
                        <li>ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ କ F ଣସି କାରଣ (ଗୁଡିକ) ନଦେଇ ଯେକ time ଣସି ସମୟରେ "ଫିନୋଲେକ୍ସ ସୁପର ପ୍ଲମ୍ବର ଲୟଲିଟି ପ୍ରୋଗ୍ରାମ" କୁ ପରିବର୍ତ୍ତନ କିମ୍ବା ପ୍ରତ୍ୟାହାର କରିବାର ଅଧିକାର ସଂରକ୍ଷଣ କରେ ଏବଂ ଏହି ପରିପ୍ରେକ୍ଷୀରେ ଫିନୋଲେକ୍ସ ପାଇପ୍ ର ନିଷ୍ପତ୍ତି ଚୂଡାନ୍ତ ଏବଂ ବାଧ୍ୟତାମୂଳକ ବିବେଚନା କରାଯିବ </li>
                        <li>  ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଯେକ any ଣସି ସମୟରେ (ଫିନୋଲେକ୍ସ ସୁପର ପ୍ଲମ୍ବର ଲୟଲିଟି ପ୍ରୋଗ୍ରାମ) ସଂଶୋଧନ କିମ୍ବା ପ୍ରତ୍ୟାହାର କରିବାର ଅଧିକାର ସଂରକ୍ଷଣ କରେ ଏବଂ ଏହି ପରିପ୍ରେକ୍ଷୀରେ ଫିନୋଲେକ୍ସ ପାଇପ୍ ନିଷ୍ପତ୍ତି ଚୂଡାନ୍ତ ଏବଂ ବାଧ୍ୟତାମୂଳକ ବୋଲି ବିବେଚନା କରାଯିବ |</li>
                        <li>  ରେଜିଷ୍ଟର୍ ହୋଇଥିବା ସଦସ୍ୟ କାର୍ଯ୍ୟକ୍ରମରେ କିମ୍ବା ଅନ୍ୟ କୌଣସି ପ୍ରକାର ଅପରେଟିଭ ବାଣିଜ୍ୟ ଅଭ୍ୟାସର ପର୍ଯ୍ୟବେକ୍ଷଣ/ରିପୋର୍ଟ ଆଧାରରେ କାର୍ଯ୍ୟକ୍ରମ ଏବଂ ଅନ୍ୟାନ୍ୟ କାର୍ଯ୍ୟକ୍ରମ/ପ୍ରୋତ୍ସାହନ ଯୋଜନାରୁ ଅଯୋଗ୍ୟତା/ବହିଷ୍କାର ପାଇଁ ଉତ୍ତରଦାୟୀ ଅଟନ୍ତି ଏବଂ ତାଙ୍କ ପୁରସ୍କାର ରାଶି ମଧ୍ୟ ଜବତ କରାଯାଇପାରିବ ଯାହା ଚୂଡ଼ାନ୍ତ, ନିର୍ଣ୍ଣାୟକ ଏବଂ ବାଧ୍ୟତାମୂଳକ ହେବ ଏବଂ ଏହାକୁ ବିବାଦୀୟ କିମ୍ବା କୌଣସି ପ୍ରଶ୍ନ କରାଯିବ ନାହିଁ।</li>
                        <li>ସ୍କିମ୍ ପ୍ରତ୍ୟାହାର କିମ୍ବା ପରିବର୍ତ୍ତନ କିମ୍ବା ଅନ୍ୟ କ reason ଣସି କାରଣରୁ ପଞ୍ଜୀକୃତ ସଦସ୍ୟଙ୍କ ସମେତ କ person ଣସି ବ୍ୟକ୍ତିଙ୍କ ପାଇଁ ପ୍ରତ୍ୟକ୍ଷ କିମ୍ବା ପରୋକ୍ଷ ଭାବରେ ହୋଇଥିବା କ loss ଣସି କ୍ଷତି ପାଇଁ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଦାୟୀ ରହିବ ନାହିଁ |</li>
                        <li>ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଦ୍ୱାରା ପୂର୍ବ-ପରିଭାଷିତ ପୁରସ୍କାର ମ୍ୟାଟ୍ରିକ୍ସ ଅନୁଯାୟୀ ସମସ୍ତ ପଞ୍ଜୀକୃତ ସଦସ୍ୟଙ୍କୁ ପୁରସ୍କୃତ କରାଯିବ |</li>
                        <li> ଏହି କାର୍ଯ୍ୟକ୍ରମ ଅଧୀନରେ ପଏଣ୍ଟସ୍ କିମ୍ବା ଅନ୍ୟ କୌଣସି ସୁବିଧା ଟ୍ରାନ୍ସଫର୍ ହୋଇପାରିବ ନାହିଁ।</li>
                        <li> ସମସ୍ତ ରେଜିଷ୍ଟର୍ ହୋଇଥିବା ସଦସ୍ୟମାନେ ସ୍ୱୀକାର କରନ୍ତି ଯେ ସେମାନେ ସମସ୍ତ ସର୍ତ୍ତାବଳୀ ସମ୍ପୂର୍ଣ୍ଣ ଭାବରେ ପଢ଼ିଛନ୍ତି ଏବଂ ବୁଝିଛନ୍ତି।</li>
                        <li> ଉପହାର / ତାଲିକାରେ ଦିଆଯାଇଥିବା ତୃତୀୟ-ପକ୍ଷ ସାମଗ୍ରୀ ଉପରେ ଗୁଣବତ୍ତା, ବିକ୍ରୟ ପରେ ସେବା, ୱାରେଣ୍ଟି, କାର୍ଯ୍ୟଦକ୍ଷତା ଇତ୍ୟାଦି ପାଇଁ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ କ responsibility ଣସି ଦାୟିତ୍। ଗ୍ରହଣ କରେ ନାହିଁ |</li>
                        <li>ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଫିନୋଲେକ୍ସ ପାଇପ୍ ସଦସ୍ୟଙ୍କ ଦ୍ୱାରା ପ୍ରସାରଣ କିମ୍ବା ଦାଖଲ କରିବାରେ କ technical ଣସି ବ technical ଷୟିକ କିମ୍ବା ଶାରୀରିକ ବିଳମ୍ବ କିମ୍ବା କ inter ଣସି ବାଧା, କ୍ଷତି, କ୍ଷତି, କମ୍ପ୍ୟୁଟର ସମ୍ବନ୍ଧୀୟ ତ୍ରୁଟି / ବିଫଳତା କିମ୍ବା ପ୍ରାକୃତିକ ବିପର୍ଯ୍ୟୟ, ସରକାରୀ କାର୍ଯ୍ୟ / ପ୍ରତିବନ୍ଧକ / ଲକଡାଉନ୍, ମହାମାରୀ ଏବଂ ସଦସ୍ୟମାନଙ୍କ ଅଂଶଗ୍ରହଣକୁ ପ୍ରଭାବିତ କରୁଥିବା ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଦ୍ୱାରା ନିଯୁକ୍ତ ତୃତୀୟ ପକ୍ଷ | କ force ଣସି ଫୋର୍ସ ମୁକାବିଲା କିମ୍ବା କାର୍ଯ୍ୟ, ତ୍ରୁଟି, କିମ୍ବା ତ୍ରୁଟି ଯୋଗୁଁ ହୋଇଥିବା କ୍ଷତି ପାଇଁ ଦାୟୀ ରହିବ ନାହିଁ |</li>
                        <li>  ପାଣିପାଗ ପରିସ୍ଥିତି, ଶ୍ରମ ଅସ୍ଥିରତା, ଦେବାଳିଆ, ବ୍ୟବସାୟିକ ଜରୁରୀକାଳୀନ ପରିସ୍ଥିତି, କିମ୍ବା ପରିଚାଳନାଗତ ଏବଂ ବ technical ଷୟିକ ସମସ୍ୟା କିମ୍ବା ଅନ୍ୟ କ reason ଣସି କାରଣ ହେତୁ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଷ୍ଟକ୍ (ଗୁଡିକ) ର ଅନୁପସ୍ଥିତି ପାଇଁ ଦାୟୀ ରହିବ ନାହିଁ |</li>
                        <li>ପଏଣ୍ଟଗୁଡିକର ବ୍ୟବହାର କିମ୍ବା ବ୍ୟବହାର ନହେବା ଦ୍ Any ାରା ସୃଷ୍ଟି ହୋଇଥିବା କ dispute ଣସି ବିବାଦ, ବିବାଦ କିମ୍ବା ବିବାଦ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ନୋଟିସକୁ ଅଣାଯିବ | ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଯଥା ଶୀଘ୍ର ପାଇପ୍ ବିବାଦର ସମାଧାନ ପାଇଁ କମିଟି ନିଯୁକ୍ତ / ପରିଚାଳନା କରିବ |</li>
                        <li>ସାମ୍ପ୍ରତିକ କାର୍ଯ୍ୟକ୍ରମଟି ପୁରସ୍କାର ବାଣ୍ଟିବା ଏବଂ ବ୍ୟବସାୟ କାରବାରରେ ସ୍ୱଚ୍ଛତା ଆଣିବା ଦ୍ୱାରା ପ୍ଲମ୍ବର୍ମାନଙ୍କୁ ଉତ୍ସାହିତ କରିବା ପାଇଁ ଉଦ୍ଦିଷ୍ଟ | ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ପ୍ଲମ୍ବରଙ୍କ ପାଇଁ କ oblig ଣସି ବାଧ୍ୟତାମୂଳକ ପାଇଁ ଆଇନଗତ ଭାବରେ ଦାୟୀ ନୁହେଁ | ତେଣୁ ପ୍ଲମ୍ବରମାନେ ଏହାକୁ ସେମାନଙ୍କର ଅଧିକାର କିମ୍ବା ଆଇନଗତ ଅଧିକାର ବୋଲି ଦାବି କରିବେ ନାହିଁ | ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ପ୍ଲମ୍ବରଗୁଡିକ ପୂର୍ବ ସୂଚନା ବିନା ପୁରସ୍କାର ପ୍ରଣାଳୀ ପରିବର୍ତ୍ତନ, ପରିବର୍ତ୍ତନ ଏବଂ ପରିବର୍ତ୍ତନ କରିବାର ଅଧିକାର ସଂରକ୍ଷଣ କରେ |</li>
                        <li>  ବିବାଦ, ଯଦି କ P ଣସି ପୁଣେ ଅଧିକାର ଅଧୀନରେ ଅଛି |</li>

                </ol>
            </div>
        </>

    )
}
