import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import ResponsivePagination from 'react-responsive-pagination';
import Oval from '../Loader/CircleLoade';
import longarrow from '../../../assets/img/longarrow.svg'
import * as Constants from "../../../Constants/index"
import axios from 'axios'
import swal from 'sweetalert'
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
export default function TicketRaiseReport() {
    const navigate = useNavigate()
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [results, setResults] = useState([])
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchRecord, setSearchRecord] = useState(null)
    const [limit] = useState('10')
    const [loading, setLoading] = useState(false);
    const [selectStatus, setSelectStatus] = useState('')

    const formattedDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
    const formattedDate2 = moment(toDate).format('YYYY-MM-DD 23:59:59');
    const start = formattedDate.replace(/:/g, '%3A')
    const end = formattedDate2.replace(/:/g, '%3A')

    useEffect(() => {

        if (currentPage !== 1) {
            customerCareReport(selectStatus);
        } else {
            customerCareReport('');
        }

    }, [currentPage])


    const customerCareReport = (userName) => {
        let token = localStorage.getItem('token');

        if (!token) {
            navigate('/');
        } else {
            const searchFromDate = fromDate === '' ? '' : `start=${start}&`
            const searchToDate = toDate === '' ? '' : `end=${end}&`
            const searchStatus = userName === '' ? '' : `status=${userName}&`
            if (fromDate !== '' && toDate === '') {
                swal({
                    text: 'Please select to date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if reason is not selected when status is 'reject'
            }
            if (fromDate === '' && toDate !== '') {
                swal({
                    text: 'Please select from date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if reason is not selected when status is 'reject'
            }
            if (fromDate !== '' && toDate !== '' && new Date(fromDate) > new Date(toDate)) {
                swal({
                    text: 'From date should be less than to date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if "from" date is greater than or equal to "to" date
            }
            try {
                setLoading(true);
                axios.get(`${Constants.baseUrl}reports/raiseTicket/list?${searchStatus}${searchFromDate}${searchToDate}sortBy=createdDateTime&sortAsc=true&limit=${limit}&page=${currentPage}`, {

                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001) {
                                setResults(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                                setSearchRecord()
                                setLoading(false);
                            } else if (res.data.code === 12002) {
                                setLoading(false);
                                setSearchRecord(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                            } else {
                                // swal({
                                //     text: res.data.msg,
                                //     icon: 'error',
                                //     timer: 2000,
                                //     buttons: false,
                                // })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }
    }

    const download = () => {
        let token = localStorage.getItem('token');
        try {
            const searchFromDate = fromDate === '' ? '' : `&start=${start}`
            const searchToDate = toDate === '' ? '' : `&end=${end}`
            const searchStatus = selectStatus === '' ? '' : `&status=${selectStatus}`
            setLoading(true)
            axios.get(Constants.baseUrl + `reports/download/raiseTicket?${searchStatus}${searchFromDate}${searchToDate}`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'
            })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'ticketRaiseReport.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const handleSearchQuery = (event) => {
        setSelectStatus(event.target.value)
        // if (event.target.value === '') {
        //     customerCareReport('')
        //     setSearchRecord()
        // }
    }



    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Ticket Raise Report</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15 callwell">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                            dateFormat="dd-MM-yyyy"
                                            className="frmcntrl"
                                            id="Fromdate"
                                            showYearDropdown
                                            showMonthDropdown
                                            autoComplete="off"
                                            scrollableYearDropdown
                                            maxDate={new Date()}
                                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                            onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                            onClick={(e) => e.preventDefault()} // Preven
                                            placeholderText='From date'
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => setToDate(date)}
                                            dateFormat="dd-MM-yyyy"
                                            className="frmcntrl"
                                            id="todate"
                                            showYearDropdown
                                            showMonthDropdown
                                            autoComplete="off"
                                            scrollableYearDropdown
                                            maxDate={new Date()}
                                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                            onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                            onClick={(e) => e.preventDefault()} // Preven
                                            placeholderText='To date'
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <select className="frmcntrl" value={selectStatus} onChange={handleSearchQuery}>
                                            <option value="">All</option>
                                            <option value="OPEN">Open</option>
                                            <option value="CLOSED">Close</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <button type="button" className="btn login_white_btn"
                                            onClick={() => customerCareReport(selectStatus)} > Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* {searchRecord === null ? '' :
                                            <Link class="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10" to="#" onClick={download}> Export</Link>} */}
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            {/* <th>Zone</th> */}
                                                            {/* <th>ZSM Name</th> */}
                                                            {/* <th>ZSM Mobile No.</th> */}
                                                            {/* <th>Zone State</th> */}
                                                            {/* <th>Zone Area Name</th> */}
                                                            <th>RSM Name</th>
                                                            <th>RSM Mobile Number</th>
                                                            <th>ASM Name</th>
                                                            <th>ASM Mobile Number</th>
                                                            <th>TSE Name</th>
                                                            <th>TSE Mobile Number</th>
                                                            <th>Plumber Name</th>
                                                            <th>Plumber Mobile No.</th>
                                                            <th>Plumber City</th>
                                                            <th>Plumber Claim ID</th>
                                                            <th>Date of Concern </th>
                                                            <th>Concern Request To</th>
                                                            <th>Request of Concern</th>
                                                            <th>Concern Status</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                // const formattedDate = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            {/* <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td> */}
                                                                            <td>{item.user.roName}</td>
                                                                            <td>{item.user.roMobile}</td>
                                                                            <td>{item.user.shName}</td>
                                                                            <td>{item.user.shMobile}</td>
                                                                            <td>{item.user.parentName}</td>
                                                                            <td>{item.user.parentMobile}</td>
                                                                            <td>{item.user.name}</td>
                                                                            <td>{item.user.mobile}</td>
                                                                            <td>{item.user.addressWork.workCity}</td>
                                                                            <td>{item.id}</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>{item.status}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </section>
            <Footer />
        </>)
}
