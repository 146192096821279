import React from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import logo2 from '../../../../assets/img/logo/logo2.jpeg'
export default function AboutProgram() {
    return (
        <>
            <Header />
            <section className="aboutcera pt182" id="aboutfinolex">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="desktop-television-product">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="section-title">
                                            <h2>About Program </h2>
                                        </div>
                                        <div className="detailabt">
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <figure className="abtprgrm">
                                            <img src={logo2} alt='' />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
