import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

//theme
// import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
// import "primereact/resources/primereact.min.css"; //core css
// import "primeicons/primeicons.css"; //icons
// import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";  

// === routing components ========
import Home from './components/Pages/Home/Home'
import Login from './components/Pages/Auth/Login'
import ForgotPassword from './components/Pages/Auth/ForgotPassword'
import Page_404 from './components/Pages/Other Pages/Page_404'
import Protected from './components/Pages/Other Pages/Protected'
import DashboardSales from './components/Pages/Sales/Dashboard/DashboardSales'
import Registered from './components/Pages/Sales/Registered/Registered'
import Rejected from './components/Pages/Sales/Rejected/Rejected'
import PendingForApproval from './components/Pages/Sales/Pending/PendingForApproval'
import Testimonials from './components/Pages/Sales/Testimonials/Testimonials'
import SchemesOffers from './components/Pages/Sales/Schemes & Offers/SchemesOffers'
import DashBoardRetailer from './components/Pages/RetailerPages/Dashboard/DashBoardRetailer'
import ApprovedRetailer from './components/Pages/RetailerPages/Retailer/ApprovedRetailer'
import PendingForApprovalRetailer from './components/Pages/RetailerPages/Retailer/PendingForApprovalRetailer'
import RejectedRetailer from './components/Pages/RetailerPages/Retailer/RejectedRetailer'
import FaqRetailer from './components/Pages/RetailerPages/FaqRetailer'
import TncRetailer from './components/Pages/RetailerPages/TncRetailer'
import ReqConcernRetailer from './components/Pages/RetailerPages/ReqConcernRetailer'
import AboutFinolexPipe from './components/Pages/RetailerPages/About Us/AboutFinolexPipe'
import AboutProgram from './components/Pages/RetailerPages/About Us/AboutProgram'
import Contact from './components/Pages/RetailerPages/Contact'
import Registration from './components/Pages/Sales/Registration/Registration'
import EnrollmentReport from './components/Pages/Sales Reports/EnrollmentReport'
import RejectedPlumbersReport from './components/Pages/Sales Reports/RejectedPlumbersReport'
import PenApprovalClaimIdReport from './components/Pages/Sales Reports/PenApprovalClaimIdReport'
import RejectedClaimIDReport from './components/Pages/Sales Reports/RejectedClaimIDReport'
import ActivePlumbersReports from './components/Pages/Sales Reports/ActivePlumbersReports'
import InActivePlumbersReports from './components/Pages/Sales Reports/InActivePlumbersReports'
import Dormant1PlumbersReports from './components/Pages/Sales Reports/Dormant1PlumbersReports'
import Dormant2PlumbersReport from './components/Pages/Sales Reports/Dormant2PlumbersReport'
import RedemptionReport from './components/Pages/Sales Reports/RedemptionReport'
import RetailerDetailsReport from './components/Pages/Sales Reports/RetailerDetailsReport'
import TicketRaiseReports from './components/Pages/Sales Reports/TicketRaiseReport'
import CheckHistory from './components/Pages/RetailerPages/CheckHistory'
import InvoiceReport from './components/Pages/Sales Reports/InvoiceReport';
import EnrollmentReportr from './components/Pages/HO Retailers Reports/EnrollmentReport';
import InvoiceReportr from './components/Pages/HO Retailers Reports/InvoiceReport'
import InvoiceApprovaltr from './components/Pages/HO Retailers Reports/InvoiceApproval'
import RedemptionReportr from './components/Pages/HO Retailers Reports/RedemptionReport'
import TicketRaiseReport from './components/Pages/HO Retailers Reports/TicketRaiseReport'
import ComingSoon from './components/Pages/Other Pages/ComingSoon';
import LoginReport from './components/Pages/HO Retailers Reports/LoginReport';
import LoginCredentialReport from './components/Pages/HO Retailers Reports/LoginCredentialReport';
import SmsReport from './components/Pages/HO Retailers Reports/SmsReport';
import PlumberLeaderBoardReport from './components/Pages/HO Retailers Reports/PlumberLeaderBoardReport';
import CumulativePointReport from './components/Pages/HO Retailers Reports/CumulativePointReport';
import LiabilityReport from './components/Pages/HO Retailers Reports/LiabilityReport';
import InvoiceRejectionReport from './components/Pages/HO Retailers Reports/InvoiceRejectionReport';
import ChangePassword from './components/Pages/Auth/ChangePassword';
import KycPendingReport from './components/Pages/HO Retailers Reports/KycPendingReport';
import CamTranReport from './components/Pages/HO Retailers Reports/CamTranReport';
import BlockedMembReport from './components/Pages/HO Retailers Reports/BlockedMembReport';

// app tnc routes
import TncEnglish from './components/Pages/Other Pages/tnc pages/English'
import TncHindi from './components/Pages/Other Pages/tnc pages/Hindi';
import TncGujrat from './components/Pages/Other Pages/tnc pages/Gujrat';
import TncKannada from './components/Pages/Other Pages/tnc pages/Kannada';
import TncOriya from './components/Pages/Other Pages/tnc pages/Oriya';
import TncTamil from './components/Pages/Other Pages/tnc pages/Tamil';
import TncTelgu from './components/Pages/Other Pages/tnc pages/Telgu';
import TncMarathi from './components/Pages/Other Pages/tnc pages/Marathi';

// app faq routes
import FaqEnglish from './components/Pages/Other Pages/faq Pages/English';
import FaqHindi from './components/Pages/Other Pages/faq Pages/Hindi';
import FaqGujrat from './components/Pages/Other Pages/faq Pages/Gujrat';
import FaqKannada from './components/Pages/Other Pages/faq Pages/Kannada';
import FaqOriya from './components/Pages/Other Pages/faq Pages/Oriya';
import FaqTamil from './components/Pages/Other Pages/faq Pages/Tamil';
import FaqTelgu from './components/Pages/Other Pages/faq Pages/Telgu';
import FaqMarathi from './components/Pages/Other Pages/faq Pages/Marathi';


import SalesFaq from './components/Pages/Other Pages/SalesFaq';
import Anolomly from './components/Pages/HO Retailers Reports/Anomoly';
import Anomoly from './components/Pages/HO Retailers Reports/Anomoly';




export default function App() {
  localStorage.setItem('language', 'en')

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/login' element={<Login />}></Route >
          <Route path='/forgot_password' element={<ForgotPassword />}></Route >
          <Route path='/*' element={<Page_404 />}></Route>
          {/* app tnc routes */}
          <Route path='/app/tnc_en' element={<TncEnglish />}></Route>
          <Route path='/app/tnc_hi' element={<TncHindi />}></Route>
          <Route path='/app/tnc_gu' element={<TncGujrat />}></Route>
          <Route path='/app/tnc_kn' element={<TncKannada />}></Route>
          <Route path='/app/tnc_or' element={<TncOriya />}></Route>
          <Route path='/app/tnc_tl' element={<TncTamil />}></Route>
          <Route path='/app/tnc_te' element={<TncTelgu />}></Route>
          <Route path='/app/tnc_mr' element={<TncMarathi />}></Route>
          {/* app faq routes */}
          <Route path='/app/faq_en' element={<FaqEnglish />}></Route>
          <Route path='/app/faq_hi' element={<FaqHindi />}></Route>
          <Route path='/app/faq_gu' element={<FaqGujrat />}></Route>
          <Route path='/app/faq_kn' element={<FaqKannada />}></Route>
          <Route path='/app/faq_or' element={<FaqOriya />}></Route>
          <Route path='/app/faq_tl' element={<FaqTamil />}></Route>
          <Route path='/app/faq_te' element={<FaqTelgu />}></Route>
          <Route path='/app/faq_mr' element={<FaqMarathi />}></Route>

          <Route path='/coming_soon' element={<ComingSoon />}></Route>
          <Route path='/change_password' element={<Protected Cmp={ChangePassword} requiredRoles={['SALES', 'RETAILER']} />}></Route >
          <Route path='/sales_faq' element={<Protected Cmp={SalesFaq} requiredRoles={['SALES']} />}></Route >


          {/* sales routes */}
          <Route path='/dashboardsales' element={<Protected Cmp={DashboardSales} requiredRoles={['SALES']} />}></Route >
          <Route path='/registered_s' element={<Protected Cmp={Registered} requiredRoles={['SALES']} />}></Route >
          <Route path='/rejected_s' element={<Protected Cmp={Rejected} requiredRoles={['SALES']} />}></Route >
          <Route path='/pending_for_approval_S' element={<Protected Cmp={PendingForApproval} requiredRoles={['SALES']} />}></Route >
          <Route path='/testimonial_s' element={<Protected Cmp={Testimonials} requiredRoles={['SALES']} />}></Route >
          <Route path='/scheme_offer_s' element={<Protected Cmp={SchemesOffers} requiredRoles={['SALES']} />}></Route >
          <Route path='/registration' element={<Protected Cmp={Registration} requiredRoles={['SALES']} />}></Route >
          <Route path='/enrollment_report_s' element={<Protected Cmp={EnrollmentReport} requiredRoles={['SALES']} />}></Route >
          <Route path='/rejected_plumbers_report_s' element={<Protected Cmp={RejectedPlumbersReport} requiredRoles={['SALES']} />}></Route >
          <Route path='/invoice_report_s' element={<Protected Cmp={InvoiceReport} requiredRoles={['SALES']} />}></Route >
          <Route path='/pen_approval_claimId_report_s' element={<Protected Cmp={PenApprovalClaimIdReport} requiredRoles={['SALES']} />}></Route >
          <Route path='/rejected_claimId_report_s' element={<Protected Cmp={RejectedClaimIDReport} requiredRoles={['SALES']} />}></Route >
          <Route path='/active_plumbers_report_s' element={<Protected Cmp={ActivePlumbersReports} requiredRoles={['SALES']} />}></Route >
          <Route path='/inActive_plumbers_report_s' element={<Protected Cmp={InActivePlumbersReports} requiredRoles={['SALES']} />}></Route >
          <Route path='/dormant1_plumbers_report_s' element={<Protected Cmp={Dormant1PlumbersReports} requiredRoles={['SALES']} />}></Route >
          <Route path='/dormant2_plumbers_report_s' element={<Protected Cmp={Dormant2PlumbersReport} requiredRoles={['SALES']} />}></Route >
          <Route path='/redemption_report_s' element={<Protected Cmp={RedemptionReport} requiredRoles={['SALES']} />}></Route >
          <Route path='/retailer_details_report_s' element={<Protected Cmp={RetailerDetailsReport} requiredRoles={['SALES']} />}></Route >
          <Route path='/ticket_raise_report_s' element={<Protected Cmp={TicketRaiseReports} requiredRoles={['SALES']} />}></Route >



          {/* retailer routes */}
          <Route path='/dashboard_retailer' element={<Protected Cmp={DashBoardRetailer} requiredRoles={['RETAILER']} />}></Route >
          <Route path='/approved_retailer' element={<Protected Cmp={ApprovedRetailer} requiredRoles={['RETAILER']} />}></Route >
          <Route path='/pending_for_approval_retailer' element={<Protected Cmp={PendingForApprovalRetailer} requiredRoles={['RETAILER']} />}></Route >
          <Route path='/rejected_retailer' element={<Protected Cmp={RejectedRetailer} requiredRoles={['RETAILER']} />}></Route >
          <Route path='/dashboard_retailer' element={<Protected Cmp={DashBoardRetailer} requiredRoles={['RETAILER']} />}></Route >
          <Route path='/faq' element={<Protected Cmp={FaqRetailer} requiredRoles={['RETAILER', 'HO']} />}></Route >
          <Route path='/tnc' element={<Protected Cmp={TncRetailer} requiredRoles={['RETAILER', 'HO']} />}></Route >
          <Route path='/request_your_concernretailer' element={<Protected Cmp={ReqConcernRetailer} requiredRoles={['RETAILER']} />}></Route >
          <Route path='/AboutFinolexPipes' element={<Protected Cmp={AboutFinolexPipe} requiredRoles={['RETAILER', 'HO']} />}></Route >
          <Route path='/AboutProgram' element={<Protected Cmp={AboutProgram} requiredRoles={['RETAILER', 'HO']} />}></Route >
          <Route path='/contact' element={<Protected Cmp={Contact} requiredRoles={['SALES', 'RETAILER', 'HO']} />}></Route >
          <Route path='/check_history' element={<Protected Cmp={CheckHistory} requiredRoles={['RETAILER']} />}></Route >

          {/* HO routes */}
          <Route path='/dashboardAdmin' element={<Protected Cmp={DashboardSales} requiredRoles={['HO']} />}></Route >
          <Route path='/invoiceApproval' element={<Protected Cmp={InvoiceApprovaltr} requiredRoles={['HO']} />}></Route >
          <Route path='/anomoly_report' element={<Protected Cmp={Anomoly} requiredRoles={['HO']} />}></Route >

          {/* HO & Retailers Reports */}
          <Route path='/enrollment_report' element={<Protected Cmp={EnrollmentReportr} requiredRoles={['RETAILER', 'HO']} />}></Route >
          <Route path='/invoice_report' element={<Protected Cmp={InvoiceReportr} requiredRoles={['RETAILER', 'HO']} />}></Route >
          <Route path='/invoice_rejection_report' element={<Protected Cmp={InvoiceRejectionReport} requiredRoles={['HO']} />}></Route >
          <Route path='/cumulative_point_report' element={<Protected Cmp={CumulativePointReport} requiredRoles={['HO']} />}></Route >
          <Route path='/redemption_report' element={<Protected Cmp={RedemptionReportr} requiredRoles={['RETAILER', 'HO']} />}></Route >
          <Route path='/login_credential_report' element={<Protected Cmp={LoginCredentialReport} requiredRoles={['HO']} />}></Route >
          <Route path='/login_report' element={<Protected Cmp={LoginReport} requiredRoles={['HO']} />}></Route >
          <Route path='/sms_report' element={<Protected Cmp={SmsReport} requiredRoles={['HO']} />}></Route >
          <Route path='/ticket_raise_report' element={<Protected Cmp={TicketRaiseReport} requiredRoles={['HO', 'RETAILER']} />}></Route >
          <Route path='/plumber_leader_board_report' element={<Protected Cmp={PlumberLeaderBoardReport} requiredRoles={['HO']} />}></Route >
          <Route path='/liability_report' element={<Protected Cmp={LiabilityReport} requiredRoles={['HO']} />}></Route >
          <Route path='/kyc_pending_report' element={<Protected Cmp={KycPendingReport} requiredRoles={['HO']} />}></Route >
          <Route path='/campaign_transaction_report' element={<Protected Cmp={CamTranReport} requiredRoles={['HO']} />}></Route >
          <Route path='/blocked_member_report' element={<Protected Cmp={BlockedMembReport} requiredRoles={['HO']} />}></Route >


        </Routes >

      </BrowserRouter >
    </>
  )
}
