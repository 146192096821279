import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

export default function Hindi() {
  return (
    <>
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                {/* <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">FAQ's</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <div className="carrd1 requestbox">
                  <div className="card-body pad30">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <Accordion activeIndex={0}>
                            <AccordionTab header="Q.1 मैं कार्यक्रम के लिए पंजीकरण कैसे करूं?">
                              <p className="m-0">
                                <span>उत्तर.</span>Google Play Store से फिनोलेक्स पाइप्स  & फिटिंग सुपर प्लम्बर लॉयल्टी प्रोग्राम ऐप डाउनलोड करें या फिनोलेक्स पाइप्स सेल्स टीम वेब ऐप के माध्यम से अपने लॉगिन का उपयोग करके आपको पंजीकृत भी कर सकते है।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.2 फिनोलेक्स पाइप्स & फिटिंग सुपर प्लम्बर लॉयल्टी प्रोग्राम में भाग लेने के लिए कौन पात्र है?">
                              <p className="m-0">
                                <span>उत्तर:</span>कोई भी प्लम्बर जिसके पास वैध आधार कार्ड हो और जिसकी आयु 18 वर्ष से अधिक हो। जो लोग अधिकृत खुदरा विक्रेता से फिनोलेक्स पाइप & फिटिंग उत्पाद खरीदते हैं वे इस कार्यक्रम में भाग लेने के पात्र हैं
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.3 मैं फिनोलेक्स पाइप्स & फिटिंग सुपर प्लंबर लॉयल्टी प्रोग्राम कैसे डाउनलोड करूं?">
                              <p className="m-0">
                                <span>उत्तर:</span>एंड्रॉइड उपयोगकर्ता बस Google Play Store पर जा सकते हैं और फिनोलेक्स पाइप्स & फिटिंग सुपर प्लंबर लॉयल्टी ऐप खोज सकते हैं और फिर एप्लिकेशन डाउनलोड कर सकते हैं।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.4 मैं अपने खाते में कैसे लॉग इन करूं?">
                              <p className="m-0">
                                <span>उत्तर:</span>पंजीकरण प्रक्रिया पूरी करने के बाद, आपको कार्यक्रम में नामांकित किया जाएगा। बस अपना पंजीकृत मोबाइल नंबर दर्ज करें और ओटीपी के साथ लॉग इन करें।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.7 मैं फिनोलेक्स पाइप्स & फिटिंग सुपर प्लंबर ऐप प्रोग्राम में पॉइंट कैसे अर्जित करूं?">
                              <p className="m-0">
                                <span>उत्तर:</span>आप अधिकृत खुदरा विक्रेताओं से फिनोलेक्स पाइप्स & फिटिंग उत्पाद खरीदकर और ऐप में इनवॉइस अपलोड करके अंक अर्जित कर सकते हैं। आपकी खरीदारी के प्रमाण के सफल सत्यापन के बाद, चालान राशि अंक आपके खाते में जोड़ दिए जाएंगे।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.8 क्या यह प्रोग्राम रिटेल और प्रोजेक्ट ऑर्डर दोनों के लिए है?">
                              <p className="m-0">
                                <span>उत्तर:</span>नहीं, कार्यक्रम केवल खुदरा ऑर्डर के लिए है
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.9 फिनोलेक्स पाइप्स & फिटिंग सुपर प्लंबर प्रोग्राम में कौन से उत्पाद/एसकेयू पॉइंट आवंटन के लिए पात्र हैं?">
                              <p className="m-0">
                                <span>उत्तर:</span>यह फिनोलेक्स पाइप्स के हर उत्पाद के लिए लागू है। आपको बस इनवॉइस अपलोड करना होगा और ऐप में फिनोलेक्स उत्पाद खरीद मूल्य का उल्लेख करना होगा। खरीद मूल्य अंक आपके खाते में अंक के रूप में जुड़ जाते हैं। योग्य उत्पादों के बारे में अधिक जानकारी के लिए, आप सोमवार से शनिवार सुबह 9.30 बजे से शाम 5.30 बजे के बीच हमारे प्रोग्राम हेल्पडेस्क 18002024289 पर हमसे संपर्क कर सकते हैं।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.10 यदि मेरे खाते में रिवॉर्ड पॉइंट मेरे द्वारा की गई रिपोर्ट की गई खरीदारी से कम हैं तो क्या होगा?">
                              <p className="m-0">
                                <span>उत्तर:</span>सोमवार से शनिवार सुबह 9.30 बजे से शाम 5.30 बजे के बीच 18002024289 पर हमारे प्रोग्राम हेल्पडेस्क से जुड़ें। वैकल्पिक रूप से, आप अधिक स्पष्टता प्राप्त करने के लिए फिनोलेक्स पाइप्स की बिक्री टीम से भी संपर्क कर सकते हैं
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.11 फिनोलेक्स पाइप्स सुपर & फिटिंग प्लम्बर लॉयल्टी प्रोग्राम में प्रत्येक रिवॉर्ड पॉइंट का मूल्य क्या है?">
                              <p className="m-0">
                                <span>उत्तर:</span>एक (01) रिवॉर्ड पॉइंट = 0.02 रुपये
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.12 बोनस अंक: ">
                              <p className="m-0">
                                <span>उत्तर:</span>1. प्रमाणीकरण के बाद, आपको जॉइनिंग बोनस के रूप में 10,000 अंक प्राप्त होंगे <br />
                                2. बोनस अंक का उपयोग पहले मोचन अनुरोध के लिए नहीं किया जा सकता है<br />
                                3. आप बाद के मोचन अनुरोध पर मोचन के लिए बोनस अंक का उपयोग कर सकते हैं<br />
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.13 क्या मैं अपने अर्जित पॉइंट्स को किसी अन्य पंजीकृत फिनोलेक्स पाइप्स & फिटिंग सुपर प्लंबर लॉयल्टी प्रोग्राम खाते में स्थानांतरित कर सकता हूं?">
                              <p className="m-0">
                                <span>उत्तर:</span>नहीं, आप अपने पॉइंट्स किसी अन्य पंजीकृत सदस्य को हस्तांतरित नहीं कर सकते।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.14 मैं अपने बैलेंस प्वाइंट्स की जांच कैसे करूं?">
                              <p className="m-0">
                                {" "}
                                <span>उत्तर:</span>आप मोबाइल एप्लिकेशन में 'हैमबर्गर' टैब पर क्लिक करके अपने शेष अंक की जांच कर सकते हैं।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.15 अंकों की वैधता क्या है?">
                              <p className="m-0">
                                <span>उत्तर:</span>इसके तैयार होने की तिथि से 3(तीन) वर्ष।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.16 मोचन के लिए आवश्यक न्यूनतम अंक क्या हैं?">
                              <p className="m-0">
                                <span>उत्तर:</span>पहला रिडेम्प्शन 20000 पॉइंट्स पर किया जाता है, उसके बाद आगे रिडेम्पशन करने के लिए न्यूनतम 5000 पॉइंट्स होते हैं।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.17 मैं अपने अर्जित अंक कैसे भुना सकता हूँ?">
                              <p className="m-0">
                                <span>उत्तर:</span>आपके संचित अंकों को भुनाने के लिए विभिन्न विकल्प हैं। इस प्रकार उल्लेख किया गया है:
                                <br />
                                1. आधार से लिंक मोबाइल नंबर पर पेटीएम वॉलेट ट्रांसफर<br />
                                2. प्रत्यक्ष बैंक हस्तांतरण<br />
                                3. यूपीआई ट्रांसफर<br />
                                4. ई-उपहार वाउचर<br />
                                5. फिनोलेक्स व्यापारिक वस्तुएँ<br />
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.18 मैं अपने संचित पॉइंट्स को कितनी बार भुना सकता हूँ?">
                              <p className="m-0">
                                <span>उत्तर:</span>आप पूरे कार्यक्रम अवधि के दौरान मोचन का अनुरोध कर सकते हैं, बशर्ते आपके खाते में न्यूनतम 5000 पर्याप्त शेष अंक हों।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.19 मुझे अपने भुनाए गए इनाम की स्थिति कैसे पता चलेगी?">
                              <p className="m-0">
                                <span>उत्तर:</span>आप ऐप में रिडीम पॉइंट्स ट्रैक रिवार्ड्स के तहत अपनी रिडेम्पशन स्थिति की जांच कर सकते हैं। आपका ईजीवी/यूपीआई/पेटीएम वॉलेट ट्रांसफर/डायरेक्ट बैंक ट्रांसफर सफल होने पर आपको एक एसएमएस अपडेट भी प्राप्त होगा। हालाँकि, आप अपने मोचन की वर्तमान स्थिति जानने के लिए प्रोग्राम हेल्पडेस्क से 18002024289 सोम-शनि (सुबह 09.30 बजे से शाम 05.30 बजे तक) पर भी संपर्क कर सकते हैं।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.20 मुझे कैसे पता चलेगा कि मुझे मेरे द्वारा सबमिट की गई खरीदारी के लिए पॉइंट मिले हैं?">
                              <p className="m-0">
                                <span>उत्तर:</span>चालान की स्पष्ट छवि को सफलतापूर्वक अपलोड करने पर, अंक आवेदन में अनऑडिटेड बिंदुओं के रूप में दिखाई देंगे और वे संबंधित अधिकृत खुदरा विक्रेता से अनुमोदन के बाद ही मोचन के लिए उपलब्ध होंगे, जिनसे आपने फिनोलेक्स पाइप्स उत्पाद खरीदे हैं।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.21 मेरा भुनाया हुआ उत्पाद प्राप्त होने में कितना समय लगेगा?">
                              <p className="m-0">
                                <span>उत्तर:</span>
                                • यूपीआई ट्रांसफ़र को डिलीवर होने में 24 से 48 घंटे लगेंगे <br />
                                • सीधे बैंक ट्रांसफ़र को डिलीवर होने में 24 से 48 घंटे लगेंगे<br />
                                • पेटीएम वॉलेट ट्रांसफर की डिलीवरी होने में 24 से 48 घंटे लगेंगे<br />
                                • ई-गिफ्ट वाउचर वास्तविक समय के आधार पर दिए जाएंगे और अधिकतम 24 घंटों के भीतर ऐप में दिखाई देंगे<br />
                                • फिनोलेक्स मर्चेंडाइज को स्टॉक उपलब्धता और डिलीवरी स्थान के आधार पर 2-3 सप्ताह लगेंगे<br />
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.22 यदि मेरे जमा किए गए अंक गलत हैं या मुझे मेरी भागीदारी के लिए अंक आवंटित नहीं किए गए हैं, तो मैं क्या कर सकता हूं?">
                              <p className="m-0">
                                <span>उत्तर:</span>ऐसे परिदृश्य में, आप ऐप से अपनी चिंता का अनुरोध कर सकते हैं या अपने पंजीकृत मोबाइल नंबर से हमारे हेल्पडेस्क 18002024289 पर भी कॉल कर सकते हैं और अपनी चिंता का अनुरोध कर सकते हैं। हम चिंता के मुद्दे पर गौर करेंगे और यथाशीघ्र उत्तर देंगे।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.23 मैं फिनोलेक्स पाइप्स & फिटिंग सुपर प्लंबर प्रोग्राम सेंटर तक कैसे पहुंच सकता हूं?">
                              <p className="m-0">
                                <span>उत्तर:</span>आप हमारे कार्यक्रम के हेल्पलाइन नंबर 18002024289 पर सुबह 9:30 बजे से शाम 5:30 बजे तक हमसे संपर्क कर सकते हैं। सोमवार से शनिवार।
                              </p>
                            </AccordionTab>
                            <AccordionTab header="Q.24 क्या आप कृपया फिनोलेक्स & फिटिंग सुपर प्लम्बर लॉयल्टी प्रोग्राम के कुछ अन्य लाभ बता सकते हैं?">
                              <p className="m-0">
                                <span>उत्तर:</span>हां, प्लंबरों और उनके परिवारों के लिए कई सहभागिता गतिविधियां होंगी।
                              </p>
                            </AccordionTab>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
