import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Link } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../Loader/CircleLoade";
import longarrow from "../../../assets/img/longarrow.svg";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { Dialog } from "primereact/dialog";
import close from "../../../assets/img/close.svg";
import plus from "../../../assets/img/plus.svg";
import { useFormik } from "formik";
import convertAmountToWords from '../../../utils/convertAmountToWords';

export default function InvoiceApproval() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});
  const [modalShow, setModalShow] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [verifyModal, setVerifyModal] = useState(false);
  const [verifyApprConfModal, setVerifyApprConfModal] = useState(false);
  const [invAmount, setInvAmount] = useState('');
	const [selectedImage, setSelectedImage] = useState('');
	const [statusval, setStatusval] = useState('');
	const [remarks, setRemarks] = useState('');
	const [reason, setReason] = useState('');
	const [zoomLevel, setZoomLevel] = useState(1);
  const [slectedInvoiceUrls, setSlectedInvoiceUrls] = useState([]);
  const [invAmountWord, setInvAmountWord] = useState('');

  const [memberId, setMemberId] = useState(null);

	const searchFormik = useFormik({
    initialValues: {
      status: "",
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      // if (data.startDate && data.endDate) {
      //   const maxEndDate = new Date(data.startDate);
      //   maxEndDate.setDate(maxEndDate.getDate() + 30); // Adjust the range as needed (e.g., 31 days)

      //   if (data.endDate > maxEndDate) {
      //     errors.endDate =
      //       "End date should be within 31 days from the start date.";
      //   }
      // }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getReportData(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

	useEffect(() => {
    getReportData({ status: "", startDate: "", endDate: "" }, 1);
  }, []);

  const getReportData = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      const searchStatus = data.status === "" ? "" : `status=${data.status}&`;
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `start=${startFormattedDate}&`;
        endDate = `end=${startFormattedDate4}&`;
      } else {
        stDate = "";
        endDate = "";
      }

      let endpoint;
      // if (data.status !== "") {
      //   endpoint = `reports/invoice/list?${searchStatus}sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`;
      // }
      // if (data.status !== "" && data.startDate !== "" && data.endDate !== "") {
      //   endpoint = `reports/invoice/list?${searchStatus}${stDate}${endDate}sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`;
      // }
      // if (data.status === "" && data.startDate !== "" && data.endDate !== "") {
      //   endpoint = `reports/invoice/list?${stDate}${endDate}sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`;
      // }
			endpoint = `capture/invoice/captureQrInvoiceHistory/admin?sortAsc=true&sortBy=createDateTime&limit=${limit}&page=${pageNum}`;
      await axios
        .get(Constants.baseUrl + `${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setReportData(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setReportData(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  const download = (data) => {
    setLoading(true);
    try {
      const searchStatus = data.status === "" ? "" : `status=${data.status}`;
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      let endpoint;
      if (data.status !== "") {
        endpoint = `reports/download/invoiceeport?${searchStatus}`;
      }
      if (data.status !== "" && data.startDate !== "" && data.endDate !== "") {
        endpoint = `reports/download/invoiceeport?${searchStatus}&${stDate}${endDate}`;
      }
      if (data.status === "" && data.startDate !== "" && data.endDate !== "") {
        endpoint = `reports/download/invoiceeport?${stDate}${endDate}`;
      }
      axios
        .get(Constants.baseUrl + `${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
          responseType: "blob", // Set the responseType to 'blob'
        })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "InvoiceApproval.csv");
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const areAllValuesBlank = () => {
    const { status, startDate, endDate } = searchFormik.values;
    return !status && !startDate && !endDate;
  };

	const handleImageCClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalVisible(true);
  };

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setZoomLevel(1);
  };

	const handleImageClick = (imageUrl) => {
		window.open(imageUrl, '_blank');
	};

	const handleCloseVerifyModal = () => {
    setVerifyModal(false);
  };

	const handleVerifyModal = (id, invamnt) => {
		setStatusval('');
		setRemarks('');
		setReason('');
    setVerifyModal((prevVerifyModal) => !prevVerifyModal);
    setMemberId(id);
    setInvAmount(invamnt);

    const amountInWords = convertAmountToWords(invamnt);
    setInvAmountWord(amountInWords);

  };

  const handleCloseVerifyApprConfModal = () => {
    setVerifyApprConfModal(false);
  };

	const handleVerifyApprConfModal = () => {
		setVerifyApprConfModal(true);
  };

	const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'statusval') {
      setStatusval(value);
    } else if (name === 'remarks') {
      setRemarks(value);
    } else if (name === 'reason') {
      setReason(value);
    }
  };

  const approveMember = (id) => {
		if(statusval == '') {
			swal("Please select status");
		} 
    // else if(remarks == '') {
		// 	swal("Please enter remarks");
		// } 
    else if(statusval == 'REJECT' && reason == '') {
			swal("Please select reason");
		} else {
			//alert(statusval);
			try {
				axios.post(
					Constants.baseUrl + "capture/invoice/manage/updatestatus/" + id,
					{
						status: statusval,
						remarks: remarks,
						reason: reason
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
							"Accept-Language": localStorage.getItem("langauge"),
							appVersion: Constants.av,
							platform: Constants.pt,
							company: Constants.company,
							program: Constants.program,
						},
					}
				).then((resp) => {
					//swal("Status Updated");
					if (resp.status === 200) {
						getReportData({ status: "", startDate: "", endDate: "" }, 1);
						setVerifyModal(false);
            setVerifyApprConfModal(false);
					} else if (resp.status === 403) {
						navigate("/");
					}
				}).catch((err) => {
					console.error(err);
				});
			} catch (err) {
				console.error(err);
			}
		}
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Header />
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <Link to="" role="tab" data-toggle="tab">
                    Invoice Approval{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                {/* <form onSubmit={searchFormik.handleSubmit}>
                  <div className="row clearfix mb-15 callwell">
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <DatePicker
                        id="startDate"
                        selected={searchFormik.values.startDate}
                        onChange={(date) =>
                          searchFormik.setFieldValue("startDate", date)
                        }
                        dateFormat="dd-MM-yyyy"
                        className="frmcntrl"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="From date"
                      />
                      {getSearchFormErrorMessage("startDate")}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <DatePicker
                        id="endDate"
                        selected={searchFormik.values.endDate}
                        onChange={(date) =>
                          searchFormik.setFieldValue("endDate", date)
                        }
                        dateFormat="dd-MM-yyyy"
                        className="frmcntrl"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="To date"
                      />
                      {getSearchFormErrorMessage("endDate")}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <select
                        className="frmcntrl"
                        id="status"
                        value={searchFormik.values.status}
                        onChange={searchFormik.handleChange}
                      >
                        <option value="">Select Status</option>
                        <option value="APPROVED">Approved</option>
                        <option value="REJECT">Rejected</option>
                        <option value="PENDING">Pending for approval</option>
                      </select>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-4">
                      <button
                        class="btn login_white_btn"
                        type="submit"
                        disabled={areAllValuesBlank()}
                      >
                        {" "}
                        Search <img src={longarrow} alt="" />
                      </button>
                    </div>
                  </div>
                </form> */}
                <div className="row">
                  <div className="col-md-12">
                    {/* {reportData === null ||
                    userType === "RETAILER" ||
                    userType === "SALES" ? (
                      ""
                    ) : (
                      <button
                        class="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10"
                        type="submit"
                        onClick={() => download(searchFormik.values)}
                      >
                        {" "}
                        Export
                      </button>
                    )} */}
                    <div className="carrd1">
                      <div className="card-body table-responsive">
                        <table className="table table-bordered mytable mb_01">
                          <thead>
                            <tr>
                              <th>S.No</th>
															<th>Action</th>
															<th>Invoice Image</th>
                              <th>Invoice Number</th>
                              <th>Invoice Amount</th>
                              <th>Plumber Name</th>
															<th>Plumber Mobile</th>
															<th>Retailer Name</th>
															<th>Retailer Mobile</th>
															<th>TSE Name</th>
															<th>TSE Mobile</th>
															<th>Work Pincode</th>
															<th>Work City</th>
															<th>Work District</th>
															<th>Work State</th>
															<th>Status</th>
															<th>Pending Invoice Day </th>
															<th>Invoice Date </th>
															<th>Created On</th>
															
                            </tr>
                          </thead>
                          {reportData === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="11"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {reportData &&
                                reportData.map((item, index) => {
                                  const serialNumber =
                                    (currentPage - 1) * limit + index + 1;
                                  // const dateOfReg = moment(item[16]).format(
                                  //   "DD-MM-YYYY"
                                  // );
                                  // const invoiceUploadDate = item[20]
                                  //   ? moment(item[20]).format("DD-MM-YYYY")
                                  //   : "";
                                  // const firstTransDate = item[25]
                                  //   ? moment(item[25]).format("DD-MM-YYYY")
                                  //   : "";
                                  // const firstTransValue = item[26] * 0.02;
                                  // const claimRejectionDate = item[33]
                                  //   ? moment(item[33]).format("DD-MM-YYYY")
                                  //   : "";

                                  return (
                                    <>
                                      <tr key={item.id}>
                                        <td>{serialNumber}</td>
																				<td>
																					{item.status === 'VERIFIED' &&
																						<button className="btn btn-outline-primary btn-sm" onClick={() => handleVerifyModal(item.id, item.invoiceAmount)}>Verify</button>
																					}
																				</td>
																				<td>
																					<div>
																						{item.invoiceUrl1 && <img src={item.invoiceUrl1} height="75px" alt="Invoice 1" onClick={() => handleImageClick(item.invoiceUrl1)} />}
																						{item.invoiceUrl2 && <img src={item.invoiceUrl2} height="75px" alt="Invoice 2" onClick={() => handleImageClick(item.invoiceUrl2)} />}
																					</div>
																					<div>
																						{item.invoiceUrl3 && <img src={item.invoiceUrl3} height="75px" alt="Invoice 3" onClick={() => handleImageClick(item.invoiceUrl3)} />}
																						{item.invoiceUrl4 && <img src={item.invoiceUrl4} height="75px" alt="Invoice 4" onClick={() => handleImageClick(item.invoiceUrl4)} />}
																					</div>

																				</td>
                                        <td>{item.invoiceNumber}</td>
                                        <td>{item.invoiceAmount}</td>
                                        <td>{item.name}</td>
																				<td>{item.mobile}</td>
																				<td>{item.retailerFirmName}</td>
																				<td>{item.retailerMobile}</td>
																				<td>{item.user?.parentName}</td>
																				<td>{item.user?.parentMobile}</td>
																				<td>{item.user?.addressWork?.workPincode}</td>
																				<td>{item.user?.addressWork?.workCity}</td>
																				<td>{item.user?.addressWork?.workdistrict}</td>
																				<td>{item.user?.addressWork?.workState}</td>
																				
																				<td>{item.status}</td>
																				<td>{item.pendingInvoiceDay}</td>
																				<td>{item.invoiceDate}</td>
																				<td>{item.createDateTime}</td>
																				
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => {
                              setCurrentPage(page);
                              getReportData(filterData, page);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="card flex justify-content-center">
        <Dialog visible={modalShow} showHeader={false}>
          <div className="modal-dialog">
            <div className="modal-content pop-content400">
              <div className="modal-header">
                <img
                  src={close}
                  data-dismiss="modal"
                  className="close closeouter"
                  alt=""
                  onClick={() => setModalShow(false)}
                />
              </div>
              <div className="modal-body">
                <div className="modal-details">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <h3 className="invoice"> Invoices</h3>
                    </div>
                    <div className="mt-15 col-lg-12 col-md-12 d-flex text-center">
                      <div className="row">
                        {slectedInvoiceUrls?.length > 0
                          ? slectedInvoiceUrls?.map((item, idx) => {
                              return (
                                <div className="col-md-6">
                                  <div style={{ cursor: "pointer" }} key={idx}>
                                    {item ? (
                                      <Link
                                        to={item}
                                        target="_blank"
                                        className="btn login_blue_btn invoice_btn"
                                      >
                                        View Invoice
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          : "No Invoice found"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

				<Dialog visible={modalVisible}  showHeader={false}>
          <div className="modal-dialog">
            <div className="modal-content pop-content400">
              <div className="modal-header mt-10 ml-10">
								{/* <img
                  src={plus}
                  alt=""
									height={'30px'}
                  onClick={() => handleZoomIn()}
                /> */}
								<button onClick={handleZoomIn}>Zoom In</button>
        				<button onClick={handleZoomOut}>Zoom Out</button>
                <img
                  src={close}
                  data-dismiss="modal"
                  className="close closeouter"
                  alt=""
                  onClick={() => handleCloseModal()}
                />
              </div>
              <div className="modal-body">
                <div className="modal-details">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
											<div className="image-container">
												<img
													src={selectedImage}
													alt="Zoomed Image"
													className="zoomable-image"
													style={{ transform: `scale(${zoomLevel})`, transition: 'transform 0.2s ease' }}
												/>
											</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

				<Dialog visible={verifyModal} showHeader={false}>
					<div className="modal-dialog">
						<div className="modal-content pop-content400">
							<div className="modal-header mt-10 ml-10">
								<img
									src={close}
									data-dismiss="modal"
									className="close closeouter"
									alt=""
									onClick={handleCloseVerifyModal}
								/>
							</div>
							<div className="modal-body">
							<div className="row mt-10">
										<div className="col-sm-4">Status</div>
										<div className="col-sm-8">
											<select
												value={statusval}
												name="statusval"
												className="frmcntrl"
												onChange={handleInputChange}
											>
												<option value="">Please select</option>
												<option value="APPROVED">APPROVED</option>
												<option value="REJECT">REJECT</option>
											</select>
										</div>
									</div>
									<div className="row mt-10">
										<div className="col-sm-4">Remarks</div>
										<div className="col-sm-8">
											<textarea
												className="frmcntrl"
												name="remarks"
												value={remarks}
												onChange={handleInputChange}
                        maxLength={400}
											></textarea>
										</div>
									</div>
									{statusval === 'REJECT' && (
										<div className="row mt-10">
											<div className="col-sm-4">Reason</div>
											<div className="col-sm-8">
												<select
													value={reason}
													name="reason"
													className="frmcntrl"
													onChange={handleInputChange}
												>
													<option value="">Select Reason</option>
													<option value="Incorrect KYC Documents">Incorrect KYC Documents</option>
													<option value="Not a Genuine Plumber">Not a Genuine Plumber</option>
													<option value="Duplicate Plumber">Duplicate Plumber</option>
													<option value="other">Other</option>
												</select>
											</div>
										</div>
									)}
									<div className="row mt-10">
										<div className="col-sm-12">
                      {statusval === 'APPROVED' ? (
                        <button className="btn login_btn" onClick={() => handleVerifyApprConfModal()}>
                          Submit
                        </button>
                      ) : (
                        <button className="btn login_btn" onClick={() => approveMember(memberId)}>
                          Submit
                        </button>
                      )}
											
										</div>
									</div>
							</div>
						</div>
					</div>
				</Dialog>

        <Dialog visible={verifyApprConfModal} showHeader={false}>
					<div className="modal-dialog">
						<div className="modal-content pop-content400">
							<div className="modal-header mt-10 ml-10">
								<img
									src={close}
									data-dismiss="modal"
									className="close closeouter"
									alt=""
									onClick={handleCloseVerifyApprConfModal}
								/>
							</div>
							<div className="modal-body">
                <div className="row mt-10">
                  <h4 className="text-center">Are you sure want to approve this invoice with</h4>
                  <h4 className="text-center">Amount(INR):{invAmount}</h4>
                  <h4 className="text-center">Amount(WORDS):{invAmountWord}</h4>
                </div>
                
                <div className="row mt-10">
                  <div className="col-sm-12 text-center">
                    <button className="btn login_btn" onClick={() => approveMember(memberId)}>
                      CONFIRM
                    </button>
                  </div>
                </div>
							</div>
						</div>
					</div>
				</Dialog>
      </div>
      <Footer />
    </>
  );
}
