import React from 'react'

export default function Page_404() {

    return (
        <>
            <h1>404 ! Page not found</h1>
        </>
    )
}
