import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
export default function Tamil() {
	return (
		<section className="blog-area pt182">
			<div className="container">
				<div className="row">
					<div className="col-md-12 tab mb-20">
						<ul className="nav nav-tabs" role="tablist">
							{/* <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">FAQ's</Link></li> */}
						</ul>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="all-single-blog rpt">
							<div className="carrd1 requestbox">
								<div className="card-body pad30">
									<div className="row">
										<div className="col-md-12">
											<div className="card">
												<Accordion activeIndex={0}>
													<AccordionTab header="கே.1  திட்டத்தில் நான் எவ்வாறு பதிவு செய்து கொள்வது?">
														<p className="m-0">
															<span>பதில்:</span>கூகுள் ப்ளே ஸ்டோரிலிருந்து ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் சூப்பர் ப்ளம்பர் லாயல்டி திட்டம் செயலியைப் பதிவிறக்கம் செய்யவும் அல்லது ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் விற்பனைக் குழுவினரும் தங்கள் லாகின் கொண்டு இணைய தளத்தில் உங்களைப் பதிவு செய்யலாம்</p>
													</AccordionTab>
													<AccordionTab header="கே.2 பினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் சூப்பர் ப்ளம்பர் லாயல்டி திட்டத்தில் பங்கேற்கத் தகுதி பெற்றவர் யார்?">
														<p className="m-0">
															<span>பதில்:</span>18 வயதுக்கு மேல் ஆன, சரியான ஆதார் அட்டை கொண்ட எந்த ப்ளம்பரும். ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் தயாரிப்புகளை அங்கீகரிக்கப்பட்ட சில்லறை விற்பனையாளரி டமிருந்து வாங்கும் யாரும் இதில் பங்கேற்கலாம்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.3 ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் சூப்பர் ப்ளம்பர் லாயல்டி திட்டத்தை நான் எப்படிப் பதிவிறக்கம் செய்வது?">
														<p className="m-0">
															<span>பதில்:</span>ஆண்ட்ராய்டு பயனர்கள் கூகுள் ப்ளே ஸ்டோருக்குச் சென்று ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் சூப்பர் ப்ளம்பர் லாயல்டி செயலியைத் தேடி அதைப் பதிவிறக்கம் செய்யலாம்
														</p>
													</AccordionTab>
													<AccordionTab header="கே.4 எனது கணக்கில் நான் எப்படி லாக் இன் செய்வது?">
														<p className="m-0">
															<span>பதில்:</span>பதிவு செய்து கொண்ட பின், நீங்கள் இந்தத் திட்டத்தில் இணைக்கப்படுகிறீர்கள். பதிவு செய்யப்பட்ட மொபை எண்ணை உள்ளீடு செய்து ஓடிபி கொண்டு லாக் இன் செய்யவும்
														</p>
													</AccordionTab>
													<AccordionTab header="கே.5 ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் சூப்பர் ப்ளம்பர் செயலித் திட்டத்தில் நான் புள்ளிகளைப் பெறுவது எப்படி?">
														<p className="m-0">
															<span>பதில்:</span>அங்கீகரிக்கப்பட்ட சில்லறை வர்த்தகர்களிடமிருந்து ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் தயாரிப்புகளை வாங்கி, அதன் ரசீதைப் பதிவேற்றம் செய்து புள்ளிகளை வெல்லலாம். உங்கள் சான்று சரிபார்க்கப்பட்ட பின், ரசீதுத் தொகைக்கேற்ப புள்ளிகள் உங்கள் கணக்கில் வரவு வைக்கபப்டும்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.6 இந்தத் திட்டம், சில்லறை மற்றும் திட்ட ஆர்டர்களுக்குப் பொருந்துமா?">
														<p className="m-0">
															<span>பதில்:</span> இல்லை. திட்டம் சில்லறை வர்த்தகத்துக்கு மட்டுமே.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.7 ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் சூப்பர் ப்ளம்பர் திட்டத்தில் புள்ளிகள் ஒதுக்கீட்டுக்கு எந்தத் தயாரிப்புகள்/ எஸ்கேயூக்கள் தகுதி பெற்றவை?">
														<p className="m-0">
															<span>பதில்:</span>பதில்: ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் ஒவ்வொரு தயாரிப்புக்கும் இது பொருந்தும். செயலியில் ரசீதைப் பதிவேற்றம் செய்து, ஃபினோலெக்ஸ் தயாரிப்பின் விலையைக் குறிப்பிட வேண்டியது தான். வாங்கிய விலைக்கு ஏற்ப புள்ளிகள் உங்கள் கணக்கில் ஏற்றப்படும்.
															   தகுதி வாய்ந்த பொருட்களின் விவரங்களுக்கு எங்கள் திட்ட ஹெல்ப்டெஸ்க் எண் 18002024289-க்குத் திங்கள் முதல் சனி வரை காலை 9.30 முதல் மாலை 5.30 வரை தொடர்பு கொள்ளலாம்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.8  நான் வாங்கியதாகக் குறிப்பிட்டிருக்கும் தொகைக்குக் குறைவாக எனது கணக்கில் புள்ளிகள் இருந்தால்?">
														<p className="m-0">
															<span>பதில்:</span>  எங்கள் திட்ட ஹெல்ப்டெஸ்க் எண் 18002024289-க்குத் திங்கள் முதல் சனி வரை காலை 9.30 முதல் மாலை 5.30 வரை தொடர்பு கொள்ளலாம். மாற்றாக, மேலும் தெளிவு பெற, ஃபினோலெக்ஸ் விற்பனைக் குழுவினரைத் தொடர்பு கொள்ளலாம்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.9  ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் சூப்பர் ப்ளம்பர் லாயல்டி திட்டத்தில் ஒவ்வொரு பரிசுப் புள்ளிக்குமான மதிப்பு எவ்வளவு?">
														<p className="m-0">
															<span>பதில்:</span> ஒரு பரிசுப் புள்ளி = 0.02 ரூபாய்
														</p>
													</AccordionTab>
													<AccordionTab header="கே.10 போனஸ் புள்ளிகள்">
														<p className="m-0">
															<span>பதில்:</span>1. அங்கீகரிப்புக்குப் பிறகு, இணைப்பு போனஸாக 10,000 புள்ளிகளைப் பெறுவீர்கள். <br />
															 2. முதல் மீட்புக் கோரிக்கைக்கு போனஸ் புள்ளிகளைப் பயன்படுத்த முடியாது.<br />
															 3. அடுத்த மீட்புக் கோரிக்கைக்கு போனஸ் புள்ளிகளைப் பயன்படுத்தலாம்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.11 நான் வென்ற புள்ளிகளை மற்றொரு பதிவு செய்யப்பட்ட ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் சூப்பர் ப்ளம்பர் லாயல்டி திட்டக் கணக்குக்கு மாற்ற முடியுமா?">
														<p className="m-0">
															<span>பதில்:</span>இல்லை. வேறு எந்தப் பதிவு செய்யப்பட்ட உறுப்பினருக்கும் உங்கள் புள்ளிகளை மாற்ற முடியாது..
														</p>
													</AccordionTab>
													<AccordionTab header="கே.12 என்னிடம் மீதமுள்ள புள்ளிகளை நான் எப்படித் தெரிந்து கொள்வது?">
														<p className="m-0">
															<span>பதில்:</span>மொபைல் செயலியில் உள்ள’ஹாம்பர்கர்’ பொத்தானை அழுத்தி உங்கள் மீதமுள்ள புள்ளிகளைப் பற்றி அறியலாம்
														</p>
													</AccordionTab>
													<AccordionTab header="கே.13  புள்ளிகளின் செல்லுபடியாகும் காலம் என்ன?">
														<p className="m-0">
															<span>பதில்:</span>அது உருவாக்கப்பட்ட தேதியிலிருந்து 3 (மூன்று) ஆண்டுகள்
														</p>
													</AccordionTab>
													<AccordionTab header="கே.14 மீட்புக்குத் தேவையான குறைந்தபட்சப் புள்ளிகள் எவ்வளவு?">
														<p className="m-0">
															<span>பதில்:</span>முதல் மீட்பு 20000 புள்ளிகளில் செய்துகொள்ளலாம். அடுத்தடுத்த மீட்புகளுக்குக் குறைந்த பட்சம் 5000 புள்ளிகள் இருக்க வேண்டும்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.15   நான் வென்ற புள்ளிகளை நான் எப்படி மீட்பது?">
														<p className="m-0">
															<span>பதில்:</span>நீங்கள் சேர்த்து வைத்த புள்ளிகளை மீட்கப் பல வழிகள் உள்ளன. அவை கீழ் வருமாறு:<br />

														   1. ஆதாருடன் இணைக்கப்பட்ட மொபைல் எண்ணில் பேடிஎம் வாலட் ட்ரான்ஸ்ஃபர் <br />
														   2. நேரடி வங்கிக் கணக்குக்கு மாற்றம்<br />
														   3. யூபிஐ ட்ரான்ஸ்ஃபர்<br />
														   4. ஈ-கிஃப்ட் வௌச்சர்கள்<br />
														   5. ஃபினோலெக்ஸ் விற்பனைப் பொருட்கள்<br />
														</p>
													</AccordionTab>
													<AccordionTab header="கே.16 நான் சேர்த்து வைத்த புள்ளிகளை எவ்வெப்போது நான் மீட்கலாம்?">
														<p className="m-0">
															<span>பதில்:</span> திட்ட காலம் முழுவதும் நீங்கள் மீட்புக் கோரிக்கை விடுக்கலாம். ஆனால், குறைந்த பட்சம் உங்கள் கணக்கில் 5000 புள்ளிகள் இருக்க வேண்டும்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.17  நான் மீட்ட பரிசு குறித்து நான் அறிவது எப்படி?">
														<p className="m-0">
															<span>பதில்:</span>செயலியில் ரிடீம் பாயின்ட்ஸ்ட்ராக் ரிவார்ட்ஸ் பொத்தானை அழுத்தி உங்கள் மீட்பு நிலையை அறியலாம்
															உங்கள் ஈஜிவி/யூபிஐ/பேடிஎம் வாலட் ட்ராஸ்ஃபர் அல்லது நேரடி வங்கிப் பரிவர்த்தனை வெற்றிகரமானதும் உங்களுக்குக் குறுஞ்செய்தி வரும்.
															உங்கள் மீட்பு நிலை குறித்து அறிய, எங்கள் திட்ட ஹெல்ப்டெஸ்க் எண் 18002024289-க்குத் திங்கள் முதல் சனி வரை காலை 9.30 முதல் மாலை 5.30 வரை தொடர்பு கொள்ளலாம்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.18 நான் வாங்கிய பொருட்களுக்கான புள்ளிகளை நான் பெற்றுவிட்டதை நான் எவ்வாறு அறியலாம்?">
														<p className="m-0">
															<span>பதில்:</span>வாங்கியதற்கான ரசீதைத் தெளிவாகப் பதிவேற்றம் செய்த பிறகு, செயலியில் உங்கள் புள்ளிகள் தெளிவாகத் தெரியும். ஆனால், நீங்கள் ஃபினோலெக்ஸ் தயாரிப்புகள் வாங்கிய அங்கீகரிக்கப்பட்ட சில்லறை வர்த்தகரின் ஒப்புதலுக்குப் பிறகே நீங்கள் அந்தப் புள்ளிகளை மீட்கக் கோரலாம்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.19 எனது மீட்ட பொருளை நான் பெற, எத்தனை காலம் ஆகும்?">
														<p className="m-0">
															• யூபிஐ ட்ரான்ஸ்ஃபர்களுக்கு 24 முதல் 48 மணி நேரங்கள் வரை ஆகும்<br />
															• நேரடி வங்கிப் பரிவர்த்தனைகளுக்கு 24 முதல் 48 மணி நேரங்கள் வரை ஆகும்.<br />
															• பேடிஎம் வாலட் ட்ரான்ஸ்ஃபருக்கு 24 முதல் 48 மணி நேரங்கள் வரை ஆகும்.<br />
															• ஈ-கிஃப்ட் வௌச்சர்கள் நிகழ் நேரத்தில் வழங்கப்படும். அதிகபட்சம் 24 மணி நேரத்துக்குள் அது செயலியில் பிரதிபலிக்கும்.<br />
															• ஃபினோலெக்ஸ் பொருட்கள், சரக்கு இருப்பு மற்றும் இடத்தைப் பொருத்து 2-3 வாரங்கள் வரை ஆகும்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.20 எனக்கு ஒதுக்கப்பட்ட புள்ளிகள் தவறாக இருந்தாலோ எனது பங்கேற்புக்கான புள்ளிகள் ஒதுக்கப்படவில்லை என்றாலோ நான் என்ன செய்ய வேண்டும்?">
														<p className="m-0">
															<span>பதில்:</span>அந்தச் சூழலில், செயலி மூலமாகவோ எங்கள் ஹெல்ப்டெஸ்க் எண் 18002024289 ஐ பதிவு செய்யப்பட்ட மொபைல் எண்ணிலிருந்து அழைத்தோ உங்கள் குறைகளைக் கூறலாம். விரைவில் அது குறித்துக் கவனித்து நாங்கள் பதிலளிப்போம்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.21  ஃபினோலெக்ஸ் பைப்ஸ் & ஃபிட்டிங்ஸ் சூப்பர் ப்ளம்பர் திட்ட மையத்தை  நான் எப்படித் தொடர்பு கொள்வது?">
														<p className="m-0">
															<span>பதில்:</span>எங்கள் திட்ட ஹெல்ப்லைன் எண் 18002024289 ஐ திங்கள் முதல் சனி வரை காலை 9.30 முதல் மாலை 5.30 வரை அழைத்துத் தொடர்பு கொள்ளலாம்.
														</p>
													</AccordionTab>
													<AccordionTab header="கே.22 ஃபினோலெக்ஸ் சூப்பர் ப்ளம்பர் லாயல்டி திட்டத்தின் பிற பலன்களைக் கூற முடியுமா?">
														<p className="m-0">
															<span>பதில்:</span>ஆம். ப்ளம்பர்கள் மற்றும் அவர்கள் குடும்பத்தினர்களுக்கான பல தரப்பட்ட நிகழ்ச்சிகள் செயல்பாடுகள் உள்ளன.
														</p>
													</AccordionTab>
												</Accordion>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		</section >)
}
