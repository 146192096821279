import React, { useState, useEffect } from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import slider1 from '../../../../assets/img/slider/1.jpg'
import slider3 from '../../../../assets/img/slider/3.jpg'
import slider4 from '../../../../assets/img/slider/4.jpg'
import finolexcatalouge from '../../../../assets/img/finolexcatalouge.svg'
import sessionwith from '../../../../assets/img/sessionwith.svg'
import finolexvid from '../../../../assets/img/finolexvid.svg'
import whatsnew from '../../../../assets/img/whatsnew.svg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'
import axios from 'axios';
import * as Constants from "../../../../Constants/index"
export default function AboutFinolexPipe() {
    const [catalog, setCatalog] = useState('')
    const [whatsNew, setWhatsNew] = useState('')
    // const [banner, setBanner] = useState([])
    useEffect(() => {
        let token = localStorage.getItem('token');
        try {
            axios.get(Constants.baseUrl + 'program/child', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept-Language": localStorage.getItem("langauge"),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    program: Constants.program,
                    company: Constants.company
                }
            })
                .then((res) => {
                    if (res.status === 201 && res.data.output) {
                        {
                            res.data.output.results.map((item) => {
                                setCatalog(item.catelog)
                                setWhatsNew(item.whatsNew)
                            })
                        }
                    } else {
                        // swal(res.data.msg);
                    }
                })
        } catch (error) {
            console.log(error)
        }

        // banner
        try {
            axios.get(Constants.baseUrl + 'program/banner', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept-Language": localStorage.getItem("langauge"),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    program: Constants.program,
                    company: Constants.company
                }
            })
                .then((res) => {
                    if (res.status === 201 && res.data.output) {
                        // setBanner(res.data.output.results)
                    } else {
                        // swal(res.data.msg);
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }, [])
    return (
        <>
            <Header />
            <section className="aboutcera pt182" id="aboutfinolex">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="desktop-television-product">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="section-title">
                                            <h2>About Finolex Pipes</h2>
                                        </div>
                                        <div className="detailabt">
                                            <p>
                                                We are India’s most trusted PVC pipes and fittings manufacturer. Over the last 40 years, we have impacted the sanitation-plumbing and agricultural sectors with prudent investments across our value chain.

                                                Right from the sourcing of quality raw materials and resin production, to manufacturing, storage, transport, sales, marketing and even further downstream to include customer engagement. A commitment that has not only earned us ISO 9001:2015 certifications across plants, but also earned us the sincere affection of farmers, plumbers, homeowners, partners, associates and shareholders. They have all come to expect the world from us. Because that's exactly what we expect from ourselves. We are Finolex Industries Limited (FIL).

                                            </p>
                                            {/* <div className="numbersstat text-center">
                                                <div className="wd50"><span className="numbr">21000+ </span> <br />
                                                    Retail <br />
                                                    Touch points
                                                </div>
                                                <div className="wd50"><span className="numbr">900+ </span> <br />Dealer <br /> Touch points</div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div id="banner" className="carousel slide" data-ride="carousel">
                                            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
                                                {/* {banner && banner.map((item)=>{
                                                    return()
                                                })} */}
                                                <div>
                                                    <img src={slider1} alt='' />
                                                </div>
                                                <div>
                                                    <img src={slider3} alt='' />
                                                </div>
                                                <div>
                                                    <img src={slider4} alt='' />
                                                </div>
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="catalouge Area pt-20 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="desktop-television-product">
                                <div className="row">
                                    <div className="col-md-3 col-xs-12 rewd">
                                        <div className="cardd2">
                                            <div className="cardd2-body">
                                                <Link target="_blank" to={catalog}>
                                                    <img className="lazyloaded" src={finolexcatalouge} alt="" />
                                                </Link>
                                            </div>
                                            <div className="cardd2-footer">
                                                <p><Link target="_blank" to={catalog}>Finolex Product Catalogue</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xs-12 rewd">
                                        <div className="cardd2">
                                            <div className="cardd2-footer">
                                                <p><Link to="#">SESSION WITH</Link></p>
                                            </div>
                                            <div className="cardd2-body">
                                                <Link target="_blank" to="#">
                                                    <img className="lazyloaded" src={sessionwith} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xs-12 rewd">
                                        <div className="cardd2">
                                            <div className="cardd2-body">
                                                <Link target="_blank" to="#">
                                                    <img className="lazyloaded" src={finolexvid} alt="" />
                                                </Link>
                                            </div>
                                            <div className="cardd2-footer">
                                                <p><Link to="#">Finolex Product Videos</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xs-12 rewd">
                                        <div className="cardd2">
                                            <div className="cardd2-body">
                                                <Link target="_blank" to={whatsNew}>
                                                    <img className="lazyloaded" src={whatsnew} alt="" />
                                                </Link>
                                            </div>
                                            <div className="cardd2-footer">
                                                <p><Link target="_blank" to={whatsNew}>What's New</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
