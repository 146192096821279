import React, { useState, useEffect } from 'react'
import axios from 'axios';
import * as Constants from "../../../Constants/index"
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import historricon from '../../../assets/img/historricon.svg'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert';
export default function ReqConcernRetailer() {
    const navigate = useNavigate
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [issue, setIssue] = useState('')
    const [pictureUrl, setPictureUrl] = useState('')
    const [description, setDescription] = useState('')
    const [value, setValue] = useState('Sales')
    const [errors, setErrors] = useState({});


    useEffect(() => {
        let token = localStorage.getItem('token');

        if (!token) {
            navigate('/');
        } else {
            try {
                setLoading(true);
                axios.get(Constants.baseUrl + `program/issueType?sortBy=name&sortAsc=true&limit=1000&page=1`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        platform: Constants.pt,
                        appVersion: Constants.av,
                        program: Constants.program
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001) {
                                setLoading(false);
                                setResults(res.data.output.results)
                            } else if (res.data.code === 12001) {

                            } else {
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    timer: 2000,
                                    buttons: false,
                                })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }

    }, [])

    const handlePicture = (event) => {
        updatePicture(event.target.files[0]);
    };

    const updatePicture = (file) => {
        let token = localStorage.getItem('token');

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'pic');

            axios.post(Constants.baseUrl + 'uploader/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': localStorage.getItem('language'),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                if (res.status === 201) {
                    setPictureUrl(res.data.output.url)

                } else {
                    // swal('Something went wrong!');
                }
            });
        } catch (error) {
            swal(error)
        }
    }

    const validateForm = () => {

        let errors = {};
        if (!issue) {
            errors.issue = 'Please select issue';
        }
        if (!pictureUrl) {
            errors.pictureUrl = 'Select a picture'
        }
        if (!description) {
            errors.description = 'Enter description'
        }

        return errors;
    };

    const handleSubmit = async (event) => {
        let token = localStorage.getItem('token');
        event.preventDefault();
        setErrors({});
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }
        try {
            setLoading(true)
            await axios.post(Constants.baseUrl + "program/child/ticket", {
                action: value,
                type: issue,
                description: description,
                ticketImg: pictureUrl,
                status: 'PENDING',
                adminRemarks: ''
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setLoading(false)
                            swal({
                                text: res.data.msg,
                                icon: 'success',
                                timer: 2000,
                                buttons: false,
                            });
                            window.location = '/request_your_concernretailer'
                        } else if (res.data.code === 12001) {
                            setLoading(false)
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            });
                        } else {
                            setLoading(true)
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            });
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        });
                    }
                });
        } catch (error) {
            console.log(error)
        }

    }
    return (
        <>
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Request Your Concern  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="text-right">
                                    <Link to="/check_history" className="checkhistory" ><img src={historricon} alt='' /> Check History</Link>
                                </div>
                                <div className="carrd1 requestbox">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="question">Whom do you want to connect with</p>
                                                <ul className="listinline">
                                                    <li>
                                                        <label className="radio-inline">
                                                            <input type="radio" value="Sales" checked={value === 'Sales'} onChange={(e) => setValue(e.target.value)} /> Sales</label>
                                                    </li>
                                                    <li>
                                                        <label className="radio-inline"><input type="radio" value="Ho" checked={value === 'Ho'} onChange={(e) => setValue(e.target.value)} /> HO</label>
                                                    </li>
                                                    <li>
                                                        <label className="radio-inline"><input type="radio" value="Feedback" checked={value === 'Feedback'} onChange={(e) => setValue(e.target.value)} /> Feedback</label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Issue Type</label>
                                                    <select className="form-control2" value={issue} onChange={(e) => setIssue(e.target.value)}>
                                                        <option value=''>Select issue</option>
                                                        {results && results.map((item) => {
                                                            return (
                                                                <option value={item.name}>{item.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                {errors.issue && (
                                                    <div className="error-message">{errors.issue}</div>
                                                )}
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Upload Picture(Optional)</label>
                                                    <input type="file" className="form-control" accept=".jpg, .jpeg, .png, .svg" onChange={handlePicture} />
                                                </div>
                                                {errors.pictureUrl && (
                                                    <div className="error-message">{errors.pictureUrl}</div>
                                                )}
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Description Remarks</label>
                                                    <textarea type='text' className="form-control" rows="4" cols="50" placeholder="Type details...." value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                                </div>
                                                {errors.description && (
                                                    <div className="error-message">{errors.description}</div>
                                                )}
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input type="submit" className="btn login_yellow_btn" value="Submit" onClick={handleSubmit} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <Footer />
        </>
    )
}
