// import numberToWords from 'number-to-words';

// const convertAmountToWords = (amount) => {
//   const [integerPart, fractionalPart] = amount.toString().split('.');
//   const integerWords = numberToWords.toWords(parseInt(integerPart, 10));
//   let fractionalWords = '';

//   if (fractionalPart) {
//     const fractionalNumber = parseInt(fractionalPart, 10);
//     fractionalWords = `${numberToWords.toWords(fractionalNumber)} cent${fractionalNumber > 1 ? 's' : ''}`;
//   }

//   return fractionalWords ? `${integerWords} dollars and ${fractionalWords}` : `${integerWords} dollars`;
// };

// export default convertAmountToWords;


import numberToWords from 'number-to-words';
const capitalizeWords = (str) => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
const convertAmountToWords = (amount) => {
  const [integerPart, fractionalPart] = amount.toString().split('.');
  const integerWords = numberToWords.toWords(parseInt(integerPart, 10));
  let fractionalWords = '';

  if (fractionalPart) {
    const fractionalNumber = parseInt(fractionalPart, 10);
    fractionalWords = `${numberToWords.toWords(fractionalNumber)}`;
  }

  const result = fractionalWords ? `${integerWords} point ${fractionalWords}` : `${integerWords}`;

  return capitalizeWords(result.replace(/-/g, ' '));
};

export default convertAmountToWords;