import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
export default function Marathi() {
    return (
        <section className="blog-area pt182">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 tab mb-20">
                        <ul className="nav nav-tabs" role="tablist">
                            {/* <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">FAQ's</Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="all-single-blog rpt">
                            <div className="carrd1 requestbox">
                                <div className="card-body pad30">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <Accordion activeIndex={0}>
                                                    <AccordionTab header="प्र.1 मी प्रोग्रामसाठी नोंदणी कशी करु?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>गुगुगल प्ले स्टोअरमधून फिनोलेक्स पाईप्स अँड फिटिंग्ज सुपर प्लंबर लॉयल्टी प्रोग्राम अ‍ॅप डाऊनलोड करा किंवा फिनोलेक्स पाईप्स अँड फिटिंग्ज सेल्स टीमही वेब अ‍ॅपच्या माध्यमातून त्यांच्या लॉग-इनमधून तुमची नोंदणी करू शकते.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.2 फिनोलेक्स पाईप्स अँड फिटिंग्ज सुपर प्लंबर लॉयल्टी प्रोग्राममध्ये सहभागी होण्यासाठी कोण पात्र आहे?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>वैध आधार कार्ड आणि 18 वर्षांपेक्षा अधिक वयाचा कोणताही प्लंबर. अधिकृत रिटेलरकडून जे फिनोलेक्स पाईप्स उत्पादनांची खरेदी करतात ते या प्रोग्राममध्ये सहभागी होण्यास पात्र आहेत.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.3 फिनोलेक्स पाईप्स अँड फिटिंग्ज सुपर प्लंबर लॉयल्टी प्रोग्राम मी कसा डाऊनलोड करू?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>अँड्रॉइड वापरकर्ते गुगल प्ले स्टोअर्सला जाऊन फिनोलेक्स पाईप्स अँड फिटिंग्ज सुपर प्लंबर लॉयल्टी प्रोग्राम अ‍ॅप शोधून ती अप्लिकेशन डाऊनलोड करू शकता.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.4 मी माझ्या अकाऊंटमध्ये (खात्यात) कसे लॉग करायचे?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>नोंदणी प्रक्रिया पूर्ण केल्यानंतर, तुमची प्रोग्राममध्ये नोंदणी होईल. तुमचा नोंदणीकृत मोबाईल नंबर प्रविष्ट करा आणि ओटीपीच्या मदतीने लॉग-इन करा
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.5 फिनोलेक्स पाईप्स सुलर प्लंबर लॉयल्टी अ‍ॅप प्रोग्राममध्ये मला पॉईंट्स कसे मिळतील?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>अधिकृत रिटेलर्सकडून फिनोलेक्स पाईप्स उत्पादने खरेदी करुन आणि इनव्हॉइस अ‍ॅपमध्ये अपलोड करुन तुम्ही पॉईंट्स मिळवू शकता. तुम्ही केलेल्या खरेदीचा पुरावा यशस्वीपणे प्रमाणित झाल्यावर इनव्हॉइस रक्कम पॉईंट्स तुमच्या खात्यात जमा केले जातील.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.6 हा प्रोग्राम रिटेल आणि प्रकल्प ऑर्डर दोन्हीसाठी आहे का?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>नाही. हा प्रोग्राम फक्त रिटेल ऑर्डर्ससाठीच आहे.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.7 फिनोलेक्स पाईप्स अँड फिटिंग्ज सुपर प्लंबर लॉयल्टी प्रोग्राममध्ये कोणती उत्पादने/एसकेयू पॉईंट्स देण्यासाठी पात्र आहेत?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>हे फिनोलेक्स पाईप्सच्या प्रत्येक उत्पादनाला लागू आहे. तुम्ही फक्त इनव्हॉइस अपलोड करायचे आहे आणि फिनोलेक्स उत्पादनाची खरेदी किंमत अ‍ॅपमध्ये भरायचे आहे. खरेदी किंमत पॉईंट्स तुमच्या खात्यात भरले जातात.<br />
                                                            पात्र उत्पादनांवरील अधिक तपशीलासाठी तुम्ही आमच्या प्रोग्राम हेल्पडेस्कशी 18002024289 या नंबरवर सोमवार ते शनिवार सकाळी 9.30 ते संध्याकाळी 5.30 पर्यंत संपर्क करू शकता.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.8 मी जी खरेदीची माहिती दिली आहे त्यापेक्षा माझ्या खात्यातील रिवॉर्ड पॉईंट्स कमी असतील तर?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>तुम्ही आमच्या प्रोग्राम हेल्पडेस्कशी 18002024289 या नंबरवर सोमवार ते शनिवार सकाळी 9.30 ते संध्याकाळी 5.30 पर्यंत संपर्क करू शकता. किंवा अधिक स्पष्टता येण्यासाठी तुम्ही आमच्या फिनोलेक्स पाईप्स सेल्स टीमशी संपर्क करू शकता.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.9 फिनोलेक्स पाईप्स अँड फिटिंग्ज सुपर प्लंबर लॉयल्टी प्रोग्राममध्ये प्रत्येक रिवॉर्ड पॉईंटचे मूल्य काय आहे?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>एक (01) रिवॉर्ड पॉईंट्स = 0.02 रुपये
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.10 बोनस पॉईंट्स:">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>1. प्रमाणिकरणानंतर तुम्हाला सहभागीत्व बोनस (जॉयनिंग बोनस) म्हणून 10,000 पॉईंट्स मिळतील<br />

                                                            2. बोनस पॉईंट्स फिल्या रिडिम्पशन विनंतीसाठी वापरता येणार नाही<br />

                                                            3. पुढील रिडिम्पशन विनंतीसाठी तुम्ही बोनस पॉईंट्स वापरू शकता
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.11 मी मिळवलेले पॉईंट्स मी दुसर्‍या नोंदणीकृत फिनोलेक्स पाईप्स अँड फिटिंग्ज सुपर प्लंबर लॉयल्टी प्रोग्राम खात्यात हस्तांतरित करू शकतो का?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>नाही, तुम्ही इतर कोणत्याही नोंदणीकृत सदस्याला पॉईंट्स हस्तांतरित करू शकत नाही.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.12 मी माझे बॅलंस पॉईंट्स कसे पाहू शकतो?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>मोबाईल अप्लिकेशनमध्ये ‘हॅम्बर्गर’ टॅबवर क्लिक करुन तुम्ही तुमचे बॅलंस पॉईंट्स पाहू शकता
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.13 पॉईंट्सची वैधता किती असते?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>तयार केल्याच्या तारखेपासून (तीन) वर्षे
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.14 रिडिम्पशनसाठी किमान किती पॉईंट्सची गरज असते?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>पहिले रिडिम्पशन 20000 पॉईंट्सवर केले जाते आणि पुढील रिडिम्पशन करण्यासाठी किमान 5000 पॉईंट्स हवे
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.15 मला मिळालेले पॉईंट्स मी कसे रिडिम करायचे?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>तुमचे जमा झालेले पॉईंट्स रिडिम करण्याचे विविध पर्याय आहेत:<br />

                                                            1. आधारशी संलह्न केलेल्या मोबाईल नंबरवर पेटीएम वॉलेट हस्तांतरण<br />

                                                            2. थेट बँकेत हस्तांतरण<br />

                                                            3. यूपीआय हस्तांतरण<br />

                                                            4. ई-गिफ्ट व्हाऊचर्स<br />

                                                            5. फिनोलेक्स मर्चंडाइझ आयटम्स
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.16 माझे जमा झालेले पॉईंट्स मी कितीवेळा रिडिम करु शकतो?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>तुमच्या खात्यात पुरेसे किमान 5000 बॅलंस पॉईंट्स असतील तर तुम्ही प्रोग्राम कालावधीत रिडिम्पशनसाठी विनंती करू शकता.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="Q.17 रिडिम केलेल्या माझ्या रिवॉर्डची स्थिती मला कशी माहिती होईल?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>अ‍ॅपमध्ये, रिडिम पॉईंट्स ट्रॅक रिवॉर्ड्स या अंतर्गत तुम्ही तुमची रिडिम्पशन स्थिती पाहू शकता
                                                            एकदा तुमचए ईजीव्ही/यूपीआय/पेटीएम वॉलेट हस्तांतरण/थेट बँक हस्तांतरण यशस्वी झाले की तुम्हाला एक एसएमएस प्राप्त होईल.<br />
                                                            मात्र, तुमच्या रिडिम्पशनची वर्तमान स्थिती माहिती करुन घेण्यासाठी तुम्ही प्रोग्राम हेल्पडेस्कशी 18002024289 या नंबरवर सोमवार ते शनिवार सकाळी 9.30 ते संध्याकाळी 5.30 पर्यंत संपर्क करू शकता.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.18 मी सादर केलेल्या खरेदींसाठी मला पॉईंट्स मिळाले आहेत हे मला कसे कळेल?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>इनव्हॉइसची स्पष्ट इमेज यशस्वीपने अपलोड केल्यावर अप्लिकेशमध्ये अनऑडिटेड पॉईंट्स म्हणून तुमचे पॉईंट्स दिसती; आणि तुम्ही फिनोलेक्स पाईप्स उत्पादने ज्यांच्याकडून खरेदी केली त्या संबंधित अधिकृत रिटेलरकडून मंजुरी मिळाल्यानंतरच ते रिडिम्पशनसाठी उपलब्ध असतील.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.19 माझे रिडिम केलेले उत्पादन मिळण्यास किती वेळ लागेल?">
                                                        <p className="m-0">
                                                            • वितरणासाठी यूपीआय हस्तांतरणाला 24 ते 48 तास लागतात.<br />

                                                            • वितरणासाठी थेट बँक हस्तांतरणाला 24 ते 48 तास लागतात.<br />

                                                            • वितरणासाठी पेटीएम हस्तांतरणाला 24 ते 48 तास लागतात.<br />

                                                            • ई-गिफ्ट व्हाऊचर्स प्रत्यक्ष-वेळ तत्त्वावर दिले जाती आणि अ‍ॅपमध्ये जास्तीत जास्त २४ तासात दिसतील.<br />

                                                            • मालाची उपलब्धता आणि वितरण स्थळ या आधारावर फिनोलेक्स मर्चंडाइझला 2-3 आठवडे लागतील
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.20 If जमा झालेले माझे पॉईंट्स बरोबर नसतील किंवा माझ्या सहभागासाठी मला पॉईंट्स देण्यात आले नसतील तर मी काय करू शकतो?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>अशा परिस्थितीत, तुम्ही तुमच्या चिंतेत लक्ष घालण्याची विनंती करू शकता किंवा तुमच्या नोंदणीकृत मोबाईल नंबरवरुन तुम्ही आम्हाला हेल्पडेस्कवर 18002024289 येथे कॉल करू शकता आणि तुमच्या चिंतेत लक्ष घालण्याची विनंती करू शकता. आम्ही तुमच्या चिंतेच्या मुद्द्यात लक्ष घाली आणि शक्य तितके लवकर तुम्हाला उत्तर देऊ.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.21फिनोलेक्स पाईप्स अँड फिटिंग्ज सुपर प्लंबर प्रोग्राम सेंटरशी मला कसा संपर्क करता येईल?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>उततुम्ही आमच्या प्रोग्राम हेल्पलाईन नंबर 18002024289 वर सोमवार ते शुक्रवार सकाळी 9.30 ते 5:30 दरम्यान संपर्क करू शकता
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="प्र.22 फिनोलेक्स पाईप्स अँड फिटिंग्ज सुपर प्लंबर प्रोग्रामचे इतर फायदे तुम्ही कृपया सांगू शकता का?">
                                                        <p className="m-0">
                                                            <span>उत्तर:</span>होय, प्लंबर्स आणि त्यांच्या कुटुंबासाठी अनेक उपक्रम असतील
                                                        </p>
                                                    </AccordionTab>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    )
}
