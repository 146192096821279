import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import ResponsivePagination from 'react-responsive-pagination';
import Oval from '../Loader/CircleLoade';
import longarrow from '../../../assets/img/longarrow.svg'
import * as Constants from "../../../Constants/index"
import axios from 'axios'
import swal from 'sweetalert'
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
export default function Dormant1PlumbersReports() {
    const navigate = useNavigate()
    const [results, setResults] = useState([])
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchRecord, setSearchRecord] = useState(null)
    const [limit] = useState('10')
    const [loading, setLoading] = useState(false);
    const [searchUser, setSearchUser] = useState('')


    useEffect(() => {
        if (currentPage !== 1) {
            dormantReport(searchUser);
        } else {
            dormantReport('');
        }

    }, [currentPage])

    const dormantReport = (userName) => {
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/')
        } else {
            const searchUserVal = userName === '' ? '' : `mobile=${userName}&`
            try {
                setLoading(true);
                axios.get(Constants.baseUrl + `reports/dormant1/list?${searchUserVal}userType=MEMBER&sortBy=name&sortAsc=false&limit=${limit}&page=${currentPage}`, {

                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001 && res.data.output) {
                                setResults(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                                setSearchRecord()
                                setLoading(false);
                            } else if (res.data.code === 12002) {
                                setLoading(false);
                                setSearchRecord(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                            } else {
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    timer: 2000,
                                    buttons: false,
                                })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }
    }
    const handleSearchQuery = (event) => {
        setSearchUser(event.target.value)
        if (event.target.value === '') {
            dormantReport('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            dormantReport(searchUser);
        }
    };
    const download = () => {
        let token = localStorage.getItem('token');
        try {
            const searchUserVal = searchUser === '' ? '' : `mobile=${searchUser}&`

            setLoading(true)
            axios.get(Constants.baseUrl + `reports/download/dormant1report?${searchUserVal}userType=MEMBER`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'

            })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'dormantReport.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Dormant 1 Plumbers Report  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15 callwell">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <input className="frmcntrl" placeholder="Name/Mobile no." type="text" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <button type="button" className="btn login_white_btn" disabled={searchUser.trim() === ''}
                                            onClick={() => dormantReport(searchUser)}> Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {searchRecord === null ? '' :
                                            <Link class="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10" to="#" onClick={download}> Export</Link>}
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            {/* <th>Zone</th> */}
                                                            {/* <th>ZSM Name</th> */}
                                                            {/* <th>ZSM Mobile No.</th> */}
                                                            {/* <th>Zone State</th> */}
                                                            {/* <th>Zone Area Name</th> */}
                                                            <th>RSM Name</th>
                                                            <th>RSM Mobile Number</th>
                                                            <th>ASM Name</th>
                                                            <th>ASM Mobile Number</th>
                                                            <th>TSE Name</th>
                                                            <th>TSE Mobile Number</th>
                                                            {/* <th>Retailer Name </th> */}
                                                            {/* <th>Retailer City</th> */}
                                                            <th>Plumber Name</th>
                                                            <th>Plumber  Mobile Number</th>
                                                            <th>City</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="10" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                // const formattedDate = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            {/* <td></td> */}
                                                                            {/* <td></td> */}
                                                                            {/* <td></td> */}
                                                                            {/* <td></td> */}
                                                                            {/* <td></td> */}
                                                                            <td>{item.roName}</td>
                                                                            <td>{item.roMobile}</td>
                                                                            <td>{item.shName}</td>
                                                                            <td>{item.shMobile}</td>
                                                                            <td>{item.parentName}</td>
                                                                            <td>{item.parentMobile}</td>
                                                                            {/* <td></td> */}
                                                                            {/* <td></td> */}
                                                                            <td>{item.name}</td>
                                                                            <td>{item.mobile}</td>
                                                                            <td>{item.addressWork.workCity}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </section>
            <Footer />
        </>)
}
