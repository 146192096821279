import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import ResponsivePagination from 'react-responsive-pagination';
import * as Constants from "../../../Constants/index"
import axios from 'axios'
import swal from 'sweetalert'
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import longarrow from '../../../assets/img/longarrow.svg'
import Oval from '../Loader/CircleLoade';
import moment from 'moment';
export default function PlumberLeaderBoardReport() {
    const navigate = useNavigate()
    const userType = localStorage.getItem('user_type')
    const [results, setResults] = useState([])
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchRecord, setSearchRecord] = useState(null)
    const [limit] = useState('10')
    const [loading, setLoading] = useState(false);
    const [searchUser, setSearchUser] = useState('')

    useEffect(() => {
        if (currentPage !== 1) {
            PlumberLeaderReport(searchUser);
        } else {
            PlumberLeaderReport('');
        }

    }, [currentPage])

    const PlumberLeaderReport = (userName) => {
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/')
        } else {
            const searchUserVal = userName === '' ? '' : `mobile=${userName}&`
            try {
                setLoading(true);
                axios.get(Constants.baseUrl + `reports/leaderBoard/list?${searchUserVal}sortBy=totalPoints&sortAsc=false&limit=${limit}&page=${currentPage}`, {

                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001 && res.data.output) {
                                setResults(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                                setSearchRecord()
                                setLoading(false);
                            } else if (res.data.code === 12002) {
                                setLoading(false);
                                setSearchRecord(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                            }
                            else if (res.data.code === 12001) {
                                setLoading(false);
                                setSearchRecord(null)
                                setTotalPages(0);
                            }
                            else {
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    timer: 2000,
                                    buttons: false,
                                })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }
    }
    const handleSearchQuery = (event) => {
        setSearchUser(event.target.value)
        if (event.target.value === '') {
            PlumberLeaderReport('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            PlumberLeaderReport(searchUser);
        }
    };
    const download = () => {
        let token = localStorage.getItem('token');
        try {
            const searchUserVal = searchUser === '' ? '' : `?mobile=${searchUser}`

            setLoading(true)
            axios.get(Constants.baseUrl + `reports/download/leaderBoardreport${searchUserVal}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'

            })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'plumberLeaderBoardReport.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section class="blog-area pt182">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 tab mb-20">
                            <ul class="nav nav-tabs" role="tablist">
                                <li role="presentation" class="active"><a href="#Section1" role="tab" data-toggle="tab"> Plumber Leader Board Report</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="all-single-blog rpt">
                                <div class="row clearfix mb-15 callwell">
                                    <div class="col-md-3 col-sm-12 col-xs-8">
                                        <input className="frmcntrl" placeholder="Name/Mobile no." type="text" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-4">
                                        <button type="button" class="btn login_white_btn" disabled={searchUser.trim() === ''}
                                            onClick={() => PlumberLeaderReport(searchUser)}> Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        {searchRecord === null || userType === 'RETAILER' || userType === 'SALES' ? '' :
                                            <Link class="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10" to="#" onClick={download}> Export</Link>}                                          <div class="carrd1">
                                            <div class="card-body table-responsive">
                                                <table class="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            {/* <th>Zone</th>
                                                            <th>ZSM Name</th>
                                                            <th>ZSM Mobile Number</th>
                                                            <th>Zone State</th>
                                                            <th>Zone Area Name</th> */}
                                                            <th>RSM Name</th>
                                                            <th>RSM Mobile Number</th>
                                                            <th>ASM Name</th>
                                                            <th>ASM Mobile Number</th>
                                                            <th>TSE Name	</th>
                                                            <th>TSE Mobile Number</th>
                                                            <th>Retailer Code</th>
                                                            <th>Retailer Name</th>
                                                            <th>Retailer Mobile</th>
                                                            <th>Retailer City</th>
                                                            <th>Retailer District</th>
                                                            <th>Retailer Pin Code</th>
                                                            <th>Retailer State</th>
                                                            <th>Retailer GST No</th>
                                                            <th>Plumber Name</th>
                                                            <th>Plumber Unique ID (Mobile Number)</th>
                                                            <th>Date of Registration</th>
                                                            {/* <th>Total Number Of Products Purchased</th> */}
                                                            <th>Total Points</th>
                                                            <th>Total Amount INR</th>
                                                            <th>Rank</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="10" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                const formattedDate = item.createDateTime ? moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY') : '';
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            {/* <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td> */}
                                                                            <td>{item.roName}</td>
                                                                            <td>{item.roMobile}</td>
                                                                            <td>{item.shName}</td>
                                                                            <td>{item.shMobile}</td>
                                                                            <td>{item.parentName}</td>
                                                                            <td>{item.parentMobile}</td>
                                                                            <td>{item.retailerId}</td>
                                                                            <td>{item.retailerName}</td>
                                                                            <td>{item?.approval?.actionBy?.mobile}</td>
                                                                            <td>{item?.approval?.actionBy?.addressWork?.workCity}</td>
                                                                            <td>{item?.approval?.actionBy?.addressWork?.workdistrict}</td>
                                                                            <td>{item?.approval?.actionBy?.addressWork?.workPincode}</td>
                                                                            <td>{item?.approval?.actionBy?.addressWork?.workState}</td>
                                                                            <td>{item?.approval?.actionBy?.gst}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.mobile}</td>
                                                                            <td>{formattedDate}</td>
                                                                            {/* <td></td> */}
                                                                            <td>{item.totalPoints}</td>
                                                                            <td>{item?.totalPoints * 0.02}</td>
                                                                            <td>{serialNumber}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody >}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
