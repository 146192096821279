import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
export default function Telgu() {
    return (
        <section className="blog-area pt182">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 tab mb-20">
                        <ul className="nav nav-tabs" role="tablist">
                            {/* <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">FAQ's</Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="all-single-blog rpt">
                            <div className="carrd1 requestbox">
                                <div className="card-body pad30">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <Accordion activeIndex={0}>
                                                    <AccordionTab header="ప్ర.1 నేను కార్యక్రమం కోసం ఏ విధంగా నమోదు చేయాలి?">
                                                        <p className="m-0">
                                                            <span>జ:</span>గూగుల్ ప్లే స్టోర్ నుండి మ ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ లాయల్టీ ప్రోగ్రామ్ ను డౌన్లోడ్ చేయవచ్చు లేదా ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సేల్స్ టీమ్ కూడా వెబ్ యాప్ ద్వారా తమ లాగ్ఇన్ ను వినియోగించి మిమ్మల్ని నమోదు చేయగలదు.
                                                            </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.2  ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ లాయల్టీ ప్రోగ్రామ్ లో పాల్గొనడానికి ఎవరు అర్హులు?">
                                                        <p className="m-0">
                                                            <span>జ:</span>చెల్లుబాటయ్యే ఆధార్ కార్డ్ తో మరియు 18 ఏళ్లకు పైబడిన ప్లంబర్. అథీకృత రిటైలర్ నుండి ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ ఉత్పత్తులు నుండి కొనుగోలు చేసిన వారు ఈ ప్రోగ్రామ్ లో పాల్గొనడానికి అర్హులు
                                                            </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.3  ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ లాయల్టీ ప్రోగ్రామ్ ను నేను ఏ విధంగా డౌన్‌లోడ్ చేయగలను?">
                                                        <p className="m-0">
                                                            <span>జ:</span>ఆండ్రాయడ్ యూజర్స్ గూగుల్ ప్లే స్టోర్ కు వెళ్లాలి మరియు ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ లాయల్టీ యాప్ కోసం అన్వేషించాలి మరియు తదుపరి అప్లికేషన్ ను డౌన్‌లోడ్ చేసుకోవాలి
                                                            </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.4  నేను నా అకౌంట్ లో కి ఏ విధంగా లాగ్ ఇన్ చేయాలి?">
                                                        <p className="m-0">
                                                            <span>జ:</span>రిజిస్ట్రేషన్ ప్రక్రియ పూర్తయిన తరువాత, మీరు ప్రోగ్రామ్ లో కి నమోదు కావాలి. మీ రిజిస్టర్డ్ మొబైల్ నంబర్ నమోదు చేయండి మరియు ఓటీపీ తో లాగ్ ఇన్ చేయాలి
                                                             </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.5  ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ యాప్ ప్రోగ్రామ్ లో నేను ఏ విధంగా పాయింట్స్ సంపాదించగలను?">
                                                        <p className="m-0">
                                                            <span>జ:</span> అథీకృత రిటైలర్స్ నుండి మీరు ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ ఉత్పత్తులను కొనుగోలు చేయడం ద్వారా పాయింట్స్ సంపాదించవచ్చు. మీరు కొనుగోలు చేసిన ప్రూఫ్ ను విజయవంతంగా ధృవీకరించిన తరువాత, ఇన్‌వాయిస్ మొత్తం పాయింట్స్ మీ అకౌంట్ కు చేర్చబడతాయి.
                                                            </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.6 ఈ ప్రోగ్రామ్ రీటైల్ మరియు ప్రాజెక్ట్ ఆర్డర్స్ రెండిటి కోసం వర్తిస్తుందా?">
                                                        <p className="m-0">
                                                            <span>జ:</span>కాదు. ప్రోగ్రామ్ కేవలం రీటైల్ ఆర్డర్స్ కోసం మాత్రమే
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.7 ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ ప్రోగ్రామ్ లో పాయింట్స్ కేటాయింపు కోసం ఏ ఉత్పత్తులు/ఎస్‌కేయులు అర్హమైనవి?">
                                                        <p className="m-0">
                                                            <span>జ:</span>ఇది ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ యొక్క ప్రతి ఉత్పత్తి కోసం వర్తిస్తుంది. మీరు కేవలం ఇన్‌వాయిస్‌లు అప్‌లోడ్ చేయాలి మరియు ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ ఉత్పత్తి యొక్క కొనుగోలు విలువను యాప్ లో పేర్కొనాలి. కొనుగోలు విలువ పాయింట్స్ మీ అకౌంట్ లో పాయింట్స్ గా చేర్చబడతాయి.
అర్హమైన ఉత్పత్తులు పై మరిన్ని వివరాలు కోసం, మీరు మా ప్రోగ్రామ్ హెల్ప్‌డెస్క్ 18002024289 ని సోమవారం నుండి శనివారం వరకు ఉదయం 9.30 నుండి సాయంత్రం 5.30 వరకు సంప్రదించవచ్చు.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.8  నా అకౌంట్ లో రివార్డ్స్ పాయింట్స్ నివేదించబడిన కొనుగోళ్లు కంటే తక్కువగా ఉంటే ఏమవుతుంది?">
                                                        <p className="m-0">
                                                            <span>జ:</span> మా ప్రోగ్రామ్ హెల్ప్‌డెస్క్ 18002024289 ని సోమవారం నుండి శనివారం వరకు ఉదయం 9.30 నుండి సాయంత్రం 5.30 వరకు సంప్రదించవచ్చు. ప్రత్యామ్నాయంగా, మరింత స్పష్టత కోసం మీరు ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సేల్స్ టీమ్ ను కూడా సంప్రదించవచ్చు
                                                            </p>
                                                    </AccordionTab>
                                                    <AccordionTab header=" ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ లాయల్టీ ప్రోగ్రామ్ లో ప్రతి రివార్డ్ పాయింట్ యొక్క విలువ ఎంత?">
                                                        <p className="m-0">
                                                            <span>జ:</span> ఒకటి (01) రివార్డ్ పాయింట్స్ = 0.02 రూపాయలు
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="బోనస్ పాయింట్స్:">
                                                        <p className="m-0">
                                                           1. ప్రామాణీకరించిన తరువాత, మీరు జాయినింగ్ బోనస్ గా 10,000 పాయింట్స్ అందుకుంటారు<br />

                                                           2. బోనస్ పాయింట్స్ మొదటి రిడెంప్షన్ అభ్యర్థన కోసం ఉపయోగించబడలేదు<br />

                                                           3. తదుపరి రిడెంప్షన్ అభ్యర్థన పై రిడెంప్షన్ కోసం మీరు బోనస్ పాయింట్స్ ను వాడవచ్చు
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.11  నేను వేరొక రిజిస్టర్డ్ ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ లాయల్టీ ప్రోగ్రామ్ అకౌంట్ కు నేను సంపాదించిన పాయింట్స్ ను బదిలీ చేయగలనా?">
                                                        <p className="m-0">
                                                            <span>జ:</span>లేదు, మీరు మీ వేరే ఇతర రిజిస్టర్డ్ సభ్యునికి మీ పాయింట్స్ ను బదిలీ చేయలేరు.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.12  నేను నా బ్యాలెన్స్ పాయింట్స్ ను ఏ విధంగా తనిఖీ చేయగలను?">
                                                        <p className="m-0">
                                                            <span>జ:</span>జ. మొబైల్ అప్లికేషన్ లో 'హామ్‌బర్గర్' ట్యాబ్ పై క్లిక్ చేయడం ద్వారా మీరు మీ బ్యాలెన్స్ పాయింట్స్ ను తనిఖీ చేయవచ్చు
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.13 పాయింట్స్ ఎంత సమయం వరకు చెల్లుతాయి?">
                                                        <p className="m-0">
                                                            <span>జ:</span>తయారైన తేదీ నుండి (మూడు) సంవత్సరాలు
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.14 రిడెంప్షన్ కోసం కనీసం ఎన్ని పాయింట్స్ కావాలి?">
                                                        <p className="m-0">
                                                            <span>జ:</span> మొదటి రిడెంప్షన్ 20000 పాయింట్స్ కు జరుగుతుంది తదుపరి భవిష్యత్తులో రిడెంప్షన్స్ చేయడానికి కనీసం 5000 పాయింట్స్ కావాలి
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.15  నేను సంపాదించిన పాయింట్స్ ను ఏ విధంగా రిడీమ్ చేయగలను?">
                                                        <p className="m-0">
                                                            <span>జ:</span>మీరు సేకరించిన పాయింట్స్ ను రిడీమ్ చేయడానికి వివిధ ఆప్షన్స్ ఉన్నాయి. అవి ఈ కింద పేర్కొనబడ్డాయి:<br />

                                                            1. ఆధార్ తో లింక్ చేయబడిన మొబైల్ నంబర్ పై పేటీఎం వాలెట్ బదిలీ<br />
                                                            2. నేరుగా బ్యాంక్ ట్రాన్స్‌ఫర్ ద్వారా<br />
                                                            3. యూపీఐ ట్రాన్స్‌ఫర్ ద్వారా<br />
                                                            4. ఈ-గిఫ్ట్ వోచర్స్ ద్వారా<br />
                                                            5. ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ మర్చండైజ్ వస్తువులు ద్వారా
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.16 నేను సేకరించిన పాయింట్స్ ను ఎంత తరచుగా నేను రెడీమ్ చేసుకోగలను?">
                                                        <p className="m-0">
                                                            <span>జ:</span>మీరు ప్రోగ్రామ్ వ్యవధి అంతటా రిడెంప్షన్ ను అభ్యర్థించవచ్చు, అయితే మీ అకౌంట్ లో మీకు కనీసం 5000 కావలసినంత బ్యాలెన్స్ పాయింట్స్ ఉండాలి
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.17  నేను రిడీమ్ చేసిన బహుమతి స్థితి గురించి నాకు ఏ విధంగా తెలుస్తుంది?">
                                                        <p className="m-0">
                                                            <span>జ:</span>రిడీమ్ పాయింట్స్ ట్రాక్ కింద మీరు మీ రిడెంప్షన్ హోదాను యాప్ లో చూడవచ్చు.<br />
                                                             మీ ఈజీవీ/యూపీఐ/పేటీఎం వాలెట్ ట్రాన్స్‌ఫర్/నేరుగా బ్యాంక్ ట్రాన్స్‌ఫర్ విజయవంతమైన తరువాత మీరు ఎస్ఎంఎస్ అప్‌డేట్ ను కూడా అందుకుంటారు.<br />
                                                             అయితే, మీ రిడెంప్షన్ యొక్క ప్రసుత హోదాన తెలుసుకోవడానికి మా ప్రోగ్రామ్ హెల్ప్‌డెస్క్ 18002024289 ని సోమ-శని వరకు (ఉదయం 9.30 నుండి సాయంత్రం 5.30) సంప్రదించవచ్చు.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.18 నేను సమర్పించిన కొనుగోళ్లు కోసం నేను పాయింట్స్ అందుకున్నానని నేను ఏ విధంగా తెలుసుకోవచ్చు?">
                                                        <p className="m-0">
                                                            <span>జ:</span>ఇన్‌వాయిస్ స్పష్టమైన ఇమేజ్ ను విజయవంతంగా అప్‌లోడింగ్ చేసిన తరువాత, పాయింట్స్ ఆడిట్ చేయబడని పాయింట్స్ గా అప్లికేషన్ లో కనిపిస్తాయి మరియు మీరు కొనుగోలు చేసిన ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ ఉత్పత్తులు యొక్క సంబంధిత అథీకృత రీటైలర్ నుండి ఆమోదం పొందిన తరువాత మాత్రమే ఇవి రిడెంప్షన్ కోసం మీకు లభిస్తాయి.</p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.19  నా రెడీమ్డ్ పాయింట్స్ ను అందుకోవడానికి ఎంత సమయం పడుతుంది?">
                                                        <p className="m-0">
                                                            • యూపీఐ ట్రాన్స్‌ఫర్స్ 24 నుండి 48 గంటలు లోగా డెలివరీ చేయబడతాయి · 24 నుండి 48 గంటలు లోగా నేరుగా బ్యాంక్ ట్రాన్స్‌ఫర్స్ అవుతాయి <br />
                                                            · 24 నుండి 48 గంటలు లోగా పేటీఎం వాలెట్ ట్రాన్స్‌ఫర్స్ అవుతాయి<br />
                                                            · ఈ-గిఫ్ట్ వోచర్స్ వాస్తవిక సమయం ఆధారంగా ఇవ్వబడతాయి మరియు గరిష్టంగా 24 గంటల సమయం లోగా యాప్ లో కనిపిస్తాయి<br />
                                                            · ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ మర్చండైజ్ కోసం స్టాక్ లభ్యతను బట్టి మరియు డెలివరీ ప్రదేశాన్ని బట్టి 2-3 వారాల సమయం కావాలి                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.20 క్రెడిట్ చేయబడిన నా పాయింట్స్ తప్పు అయినట్లయితే లేదా నేను పాల్గొన్నందుకు నాకు పాయింట్స్ కేటాయించబడకపోతే, నేను ఏంటి చేయాలి?">
                                                        <p className="m-0">
                                                            <span>జ:</span>అలాంటి పరిస్థితి లో, మీరు యాప్ నుండి మీ ఆందోళన గురించి అభ్యర్థించవచ్చు లేదా మీ రిజిస్టర్డ్ మొబైల్ నంబర్ తో మా హెల్ప్‌డెస్క్ 18002024289 కి కాల్ చేయవచ్చు మరియు మీ ఆందోళన గురించి అభ్యర్థన ప్రతిపాదించవచ్చు. మేము మీ ఆందోళన సమస్యను పరిశీలించి సాధ్యమైనంత త్వరగా జవాబు ఇస్తాము.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.21 ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ ప్రోగ్రామ్ సెంటర్ ను నేను ఏవిధంగా సంప్రదించగలను?">
                                                        <p className="m-0">
                                                            <span>జ:</span>  మీరు మా ప్రోగ్రామ్ హెల్ప్‌లైన్ నంబర్ 18002024289 ని సోమవారం నుండి శనివారం వరకు ఉదయం 9.30 గంటలు నుండి సాయంత్రం 5.30 గంటల వరకు సంప్రదించవచ్చు.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ప్ర.22  ఫినోలెక్స్ పైప్స్ & ఫిట్టింగ్స్ సూపర్ ప్లంబర్ లాయల్టీ ప్రోగ్రామ్ యొక్క కొన్ని ఇతర ప్రయోజనాలు గురించి దయచేసి మీరు మాకు చెప్పగలరా?">
                                                        <p className="m-0">
                                                            <span>జ:</span> సరే, ప్లంబర్స్ మరియు వారి కుటుంబాలు కోసం వివిధ రకాలుగా పాల్గొనడానికి కార్యకలాపాలు గలవు.
                                                        </p>
                                                    </AccordionTab>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    )
}
