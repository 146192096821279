import React, { useState } from 'react'
import axios from 'axios';
import * as Constants from "../../../Constants/index"
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom'
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import loginBanner from '../../../assets/img/loginbanner.jpg'

export default function ForgotPassword() {
    const [mobile, setMobile] = useState('')
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()

    const validateForm = () => {
        let errors = {};

        if (!mobile) {
            errors.mobile = 'User ID is required';
        } else if (!/^\d+$/.test(mobile)) {
            errors.mobile = 'User ID should be numeric';
        } else if (mobile.length !== 10) {
            errors.mobile = 'User ID should be 10 digits';
        }


        return errors;
    };

    const handleSubmit = async () => {
        setErrors({});

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }

        try {
            await axios.post(Constants.baseUrl + 'auth/forget-password',
                {
                    mobile: mobile,
                    password: ''
                },
                {
                    headers: {
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    },
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 2000);
                        } else if (res.data.code === 12002) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });

                        } else {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                });

        } catch (error) {
            console.log(error);
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    return (
        <>
            <Header />
            <section className="loginsec mt134 bgforgot_pass" style={{ backgroundImage: `url(${loginBanner})`, }}>
                <div className="container">
                    <div className="loginwindow">
                        <div className="row mr0">
                            <div className="col-md-8 col-md-offset-2 col-xs-12 loginside">
                                <div className="formlogin">
                                    <h2>Forgot Password </h2>
                                    <div className="form-group">
                                        <label for="user id">User ID</label>
                                        <input type="text" placeholder="User ID" className="form-control frm" maxLength='10' value={mobile} onChange={(event) => {
                                            const input = event.target.value;
                                            if (/^\d*$/.test(input)) {
                                                setMobile(input);
                                            }
                                        }} onKeyDown={handleKeyDown} />
                                    </div>
                                    {errors.mobile && (
                                        <div className="error-message">{errors.mobile}</div>
                                    )}
                                    <div className="form-group mtop20">
                                        <input type="submit" value="Submit" className="btn loginbuttn" onClick={handleSubmit} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
