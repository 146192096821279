import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import contactus from '../../../assets/img/contactus.svg'
import email from '../../../assets/img/email.svg'
import { Link } from 'react-router-dom'
import { FaInstagramSquare } from "react-icons/fa";
import facbook from '../../../assets/img/social/Facebook.webp'
import instagram from '../../../assets/img/social/instagram.avif'
import twitter from '../../../assets/img/social/twitter.webp'
import youtube from '../../../assets/img/social/youtube.png'
import linkdin from '../../../assets/img/social/linkedin.png'
import website from '../../../assets/img/social/website.png'
export default function Contact() {
    return (
        <>
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Contact Us  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="carrd1 requestbox">
                                    <div className="card-body pad30">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <figure className="infodetail">
                                                    <figcaption>
                                                        <h4>Program  Center</h4>
                                                        <address>
                                                            C/o Evolve Brands Pvt Ltd
                                                            <br />
                                                            249 G, AIHP Tower,
                                                            Level 2, Wing A,<br />
                                                            Udyog Vihar Phase 4,
                                                            Gurugram,122015
                                                        </address>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-md-4">
                                                <figure className="infodetail">

                                                    <figcaption>
                                                        <h4>Helpline Number </h4>
                                                        <address>
                                                            <img src={contactus} alt='' />  18002024289
                                                            <br /> <img src={contactus} style={{ visibility: 'hidden' }} alt='' />  09:30AM to 5:30PM (Mon-Sat)<br />
                                                        </address>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-md-4">
                                                <figure className="infodetail">
                                                    <h4>Email</h4>
                                                    <figcaption>
                                                        <h4 className="mail"><Link to="mailto:"> <img src={email} alt='' /> cc@finolexsuperplumber.com</Link></h4>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4 className="tollfree">For any product related query. Finolex Customer Care Number <span>18002003466</span>	 (Toll Free	)</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 social-icons ">
                                                <Link to='https://www.facebook.com/finolexpipes' target='_blank'><img src={facbook} alt="" /></Link>
                                                <Link to='https://www.instagram.com/finolexpipes' target='_blank'><img src={instagram} alt="" /></Link>
                                                <Link to='https://twitter.com/Finolexpipe' target='_blank'><img src={twitter} alt="" /></Link>
                                                <Link to='https://www.youtube.com/channel/UC53laHlNZTYkUx6crpusvQw' target='_blank'><img src={youtube} alt="" /></Link>
                                                <Link to='https://www.linkedin.com/company/finolex-industries-ltd' target='_blank'><img src={linkdin} alt="" /></Link>
                                                <Link to='https://www.finolexpipes.com' target='_blank'><img src={website} alt="" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
