import React from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import user from '../../../../assets/img/user.png'
import star from '../../../../assets/img/5star.svg'
import quote from '../../../../assets/img/quote.svg'
import { Link } from 'react-router-dom'
export default function Testimonials() {
    return (
        <>
            <Header />
            <section class="blog-area pt182">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 tab mb-20">
                            <ul class="nav nav-tabs" role="tablist">
                                <li role="presentation" class="active"><Link to="" role="tab" data-toggle="tab">Testimonials</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="all-single-blog rpt">
                                <div class="row">
                                    <div class="col-md-4 testimbox">
                                        <div class="testimonial_item">
                                            <div class="testimonial_image">
                                                <img class="img-fluid" src={user} alt="images not found" />

                                            </div>
                                            <div class="testimonial_details">
                                                <div class="testimonial_title">
                                                    <figure><img src={star} alt='' /></figure>
                                                    <h5>Aakash Singh Chauhan </h5>
                                                    <p>Saket, New Delhi</p>
                                                </div>
                                                <figure class="text-center"><img src={quote} alt='' /></figure>
                                                <p>
                                                    Past 10 year's financial track record analysis by Moneyworks4me indicates that Finolex Industries Ltd is a good quality company.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 testimbox">
                                        <div class="testimonial_item">
                                            <div class="testimonial_image">
                                                <img class="img-fluid" src={user} alt="images not found" />

                                            </div>
                                            <div class="testimonial_details">
                                                <div class="testimonial_title">
                                                    <figure><img src={star} alt='' /></figure>
                                                    <h5>Aakash Singh Chauhan </h5>
                                                    <p>Saket, New Delhi</p>
                                                </div>
                                                <figure class="text-center"><img src={quote} alt='' /></figure>
                                                <p>
                                                    Past 10 year's financial track record analysis by Moneyworks4me indicates that Finolex Industries Ltd is a good quality company.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 testimbox">
                                        <div class="testimonial_item">
                                            <div class="testimonial_image">
                                                <img class="img-fluid" src={user} alt="images not found" />

                                            </div>
                                            <div class="testimonial_details">
                                                <div class="testimonial_title">
                                                    <figure><img src={star} alt='' /></figure>
                                                    <h5>Aakash Singh Chauhan </h5>
                                                    <p>Saket, New Delhi</p>
                                                </div>
                                                <figure class="text-center"><img src={quote} alt='' /></figure>
                                                <p>
                                                    Past 10 year's financial track record analysis by Moneyworks4me indicates that Finolex Industries Ltd is a good quality company.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 testimbox">
                                        <div class="testimonial_item">
                                            <div class="testimonial_image">
                                                <img class="img-fluid" src={user} alt="images not found" />

                                            </div>
                                            <div class="testimonial_details">
                                                <div class="testimonial_title">
                                                    <figure><img src={star} alt='' /></figure>
                                                    <h5>Aakash Singh Chauhan </h5>
                                                    <p>Saket, New Delhi</p>
                                                </div>
                                                <figure class="text-center"><img src={quote} alt='' /></figure>
                                                <p>
                                                    Past 10 year's financial track record analysis by Moneyworks4me indicates that Finolex Industries Ltd is a good quality company.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
