import React, { useState, useEffect } from "react";
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import axios from "axios";
import * as Constants from "../../../../Constants/index";
import shortarrow from "../../../../assets/img/shortarrow.svg";
import registered from "../../../../assets/img/registered.svg";
import rejected from "../../../../assets/img/rejected.svg";
import pendingFa from "../../../../assets/img/pendingFa.svg";
import download from "../../../../assets/img/download.svg";
import longarrow from "../../../../assets/img/longarrow.svg";
import video from "../../../../assets/img/catalouge.png";
import testimonial from "../../../../assets/img/aboutFinolexPipe.png";
import scheme from "../../../../assets/img/finolexvideo.png";
import contact from "../../../../assets/img/contact.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Oval from "../../Loader/CircleLoade";
import plus from "../../../../assets/img/plus.svg";
import swal from "sweetalert";
import { Dialog } from "primereact/dialog";
import close from "../../../../assets/img/close.svg";
import { Accordion, AccordionTab } from "primereact/accordion";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import "./dashboard.css";

export default function DashboardSales() {
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [topPlumber, setTopPlumber] = useState([]);
  const [topRetailer, setTopRetailer] = useState([]);
  const [dashboard, setDashboard] = useState({});
  const [loading, setLoading] = useState(false);
  const [redemsummary, setRedemsummary] = useState(false);
  const [mobile, setMobile] = useState("");
  const [errors, setErrors] = useState({});
  const [modalPlumber, setModalPlumber] = useState(false);
  const [modalRetailer, setModalRetailer] = useState(false);
  const userType = localStorage.getItem("user_type");
  const [programDataChild, setProgramDataChild] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [month, setMonth] = useState(null);
  const [regDataCount, setRegDataCount] = useState(null);
  const [monthValue, setMonthValue] = useState();
  const [yearValue, setYearValue] = useState();
  const [pendingVerificationData, setPendingVerificationData] = useState(null)
  const monthList = [
    { name: "YTD", code: 0 },
    { name: "January", code: 1 },
    { name: "February", code: 2 },
    { name: "March", code: 3 },
    { name: "April", code: 4 },
    { name: "May", code: 5 },
    { name: "June", code: 6 },
    { name: "July", code: 7 },
    { name: "August", code: 8 },
    { name: "September", code: 9 },
    { name: "October", code: 10 },
    { name: "November", code: 11 },
    { name: "December", code: 12 },
  ];

  const yearList0 = () => {
    const currentYear = new Date().getFullYear();
    const yearLength = currentYear - 2023;
    const startYear = currentYear - yearLength

    const years = Array.from({ length: yearLength + 1 }, (_, index) => startYear + index);
    return years;
  };
  const yearList = yearList0().map((item) => {
    return { name: item, code: item };
  });

  // console.log("yearList", yearList);

  useEffect(() => {
    //top retailers
    if (!token) {
      navigate("/");
    } else {
      try {
        let endpoint = "";

        if (userType === "SALES") {
          endpoint = `user/manage/userByparentId?userType=RETAILER&status=APPROVED&sortBy=auditedPoint&sortAsc=false&limit=15&page=1`;
        } else if (userType === "HO") {
          endpoint = `user/manage/userByparentId?userType=MEMBER&status=APPROVED&sortBy=auditedPoint&sortAsc=false&limit=15&page=1`;
        }
        setLoading(true);
        axios
          .get(Constants.baseUrl + endpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              platform: Constants.pt,
              appVersion: Constants.av,
              program: Constants.program,
              company: Constants.company,
            },
          })
          .then((res) => {
            if (res.status === 201 && res.data.output) {
              setLoading(false);
              setTopRetailer(res.data.output.results);
            } else {
              // swal(res.data.msg);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }

    //dashboard data

    ProgramData();
    getMonthList();
    getPendingApprovalData()
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const curentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    setMonthValue(curentMonth);
    setYearValue(currentYear);
    getSalesData(curentMonth, currentYear);
    getTop10PLumbers(curentMonth, currentYear);
  }, []);

  const getSalesData = async (monthVal, yearVal) => {
    if (!token) {
      navigate("/");
    } else {
      try {
        setLoading(true);
        await axios
          .get(
            `${
              Constants.baseUrl
            }program/dashboard/mtd_ytd/sales?mongth=${monthVal}&year=${yearVal}&mtd_or_ytd=${
              monthVal === 0 ? "YTD" : "MTD"
            }`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                platform: Constants.pt,
                appVersion: Constants.av,
                program: Constants.program,
              },
            }
          )
          .then((res) => {
            if (res.status === 201 && res.data.output) {
              setLoading(false);
              setDashboard(res.data.output);
            } else {
              // swal(res.data.msg);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getTop10PLumbers = async (monthVal, yearVal) => {
    if (!token) {
      navigate("/");
    } else {
      try {
        let endpoint = "";
        // program/dashboard/top15/sales?mtd_or_ytd=MTD&month=9&year=2024&userType=MEMBER&status=APPROVED&sortBy=totalPoints&sortAsc=false&limit=10&page=1

        //   if (userType === "SALES") {
        //     endpoint = `program/dashboard/top15/sales?userType=MEMBER&status=APPROVED&sortBy=totalPoints&sortAsc=false&limit=10&page=1`;
        //   } else if (userType === "HO") {
        //     endpoint = `program/dashboard/top15/sales?userType=MEMBER&status=APPROVED&sortBy=totalPoints&sortAsc=false&limit=10&page=1`;
        //   }

        endpoint = `program/dashboard/top15/sales?mtd_or_ytd=${
          monthVal === 0 ? "YTD" : "MTD"
        }&month=${monthVal}&year=${yearVal}&userType=MEMBER&status=APPROVED&sortBy=totalPoints&sortAsc=false&limit=10&page=1`;
        setLoading(true);
        await axios
          .get(Constants.baseUrl + endpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              platform: Constants.pt,
              appVersion: Constants.av,
              program: Constants.program,
              company: Constants.company,
            },
          })
          .then((res) => {
            if (res.status === 201 && res.data.output) {
              setLoading(false);
              setTopPlumber(res.data.output.results);
            } else {
              // swal(res.data.msg);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getPendingApprovalData = async () => {
    if (!token) {
      navigate("/");
    } else {
      try {
        setLoading(true);
        await axios
          .get(`${Constants.baseUrl}program/dashboard/sales`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              platform: Constants.pt,
              appVersion: Constants.av,
              program: Constants.program,
              company: Constants.company,
            },
          })
          .then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
              setPendingVerificationData(dt.output)
              setLoading(false);
            } else {
              console.log('error', dt.msg)
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  //Program child
  const ProgramData = () => {
    let token = localStorage.getItem("token");

    if (!token) {
      navigate("/");
    } else {
      try {
        setLoading(true);
        axios
          .get(
            Constants.baseUrl +
              `program/child?sortBy=name&sortAsc=true&limit=10&page=1`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                platform: Constants.pt,
                appVersion: Constants.av,
                program: Constants.program,
                company: Constants.company,
              },
            }
          )
          .then((res) => {
            if (res.status === 201 && res.data.output) {
              setLoading(false);
              setProgramDataChild(res.data?.output?.results[0]);
            } else {
              // swal(res.data.msg);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  // get months list
  const getMonthList = () => {
    if (!token) {
      navigate("/");
    } else {
      try {
        setLoading(true);
        axios
          .get(`${Constants.baseUrl}user/month_year?userType=MEMBER`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              program: Constants.program,
            },
          })
          .then((res) => {
            if (res.status === 201 && res.data.result === "success") {
              getRegDataCount(res.data.output[0]);
              localStorage.setItem("month_name", res.data.output[0]);
              const months = res.data.output.map((item) => ({
                name: item,
              }));
              setSelectedMonth(months[0]);
              setMonth(months);
            } else if (res.status === 201 && res.data.result === "error") {
              setLoading(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleRegCount = (e) => {
    setSelectedMonth(e.value);
    localStorage.setItem("month_name", e.value.name);
    getRegDataCount(e.value.name);
  };

  const getRegDataCount = (monthName) => {
    if (!token) {
      navigate("/");
    } else {
      try {
        setLoading(true);
        axios
          .get(
            Constants.baseUrl +
              `user/month_year/count?monthYear=${monthName}&userType=MEMBER&status=UPLOAD&sortBy=name&sortAsc=true&limit=10&page=1`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                program: Constants.program,
              },
            }
          )
          .then((res) => {
            if (res.status === 201 && res.data.result === "success") {
              setLoading(false);
              setRegDataCount(res.data.output);
            } else if (res.status === 201 && res.data.result === "error") {
              setLoading(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};

    if (!mobile) {
      errors.mobile = "Mobile no. is required";
    } else if (!/^[6-9]\d{9}$/.test(mobile)) {
      errors.mobile = "Invalid mobile no.";
    } else if (mobile.length !== 10) {
      errors.mobile = "Mobile no. must be 10 digits";
    }

    return errors;
  };

  const sendRegLink = (number) => {
    let token = localStorage.getItem("token");
    setErrors({});

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    try {
      setLoading(true);
      axios
        .post(
          Constants.baseUrl + `user/sendAppDownloadLink?mobile=${number}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              platform: Constants.pt,
              appVersion: Constants.av,
              program: Constants.program,
              company: Constants.company,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              swal({
                text: res.data.msg,
                icon: "success",
                timer: 2000,
                buttons: false,
              });
              setLoading(false);
              setMobile("");
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
              setLoading(false);
            }
          } else {
            swal({
              text: res.data.msg,
              icon: "error",
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendRegLink(mobile);
    }
  };

  const handleDropdownToggle = () => {
    setRedemsummary((prevState) => !prevState);
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <Header />
      <section className="blog-area pt182">
        <div className="container-fluid">
          <div className="d-flex mx-3 my-2">
            <div className="mx-2 my-3">
              <span className="dashboard-calender">
                <Dropdown
                  id="monthValue"
                  value={monthValue}
                  options={monthList}
                  onChange={(e) => {
                    getSalesData(e.value, yearValue);
                    getTop10PLumbers(e.value, yearValue);
                    setMonthValue(e.value);
                  }}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Month"
                />
              </span>
            </div>
            <div className="mx-2">
              <span className="dashboard-calender">
                <Dropdown
                  id="yearValue"
                  value={yearValue}
                  options={yearList.reverse()}
                  onChange={(e) => {
                    getSalesData(monthValue, e.value);
                    getTop10PLumbers(monthValue, e.value);
                    setYearValue(e.value);
                  }}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Year"
                />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="cardboard">
                      <div className="row mauto">
                        <div className="col-md-3 padding">
                          <div className="info-box bg-pink hover-expand-effect  lbalance border-right border-bottom">
                            <div className="content">
                              <div className="text">Sales Reported in INR</div>
                              <div className="number count-to">
                                {dashboard.salesReportInr}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 padding">
                          <div className="info-box bg-pink hover-expand-effect  lbalance border-right border-bottom">
                            <div className="content">
                              <div
                                className="text "
                                onClick={handleDropdownToggle}
                              >
                                Redemption Summary{" "}
                                <i className="fa fa-angle-down"></i>
                              </div>
                              {redemsummary === true ? (
                                <div className="number count-to">
                                  <div className="redemsummary">
                                    <ul className="summary-menu">
                                      <li>
                                        Request Received
                                        <span>{dashboard.requestRecived}</span>
                                      </li>
                                      <li className="redemption-summary-item">
                                        Request Delivered
                                        <span className="dashboard-red-summery">{dashboard.requestDeleverd}</span>
                                      </li>
                                      <li className="redemption-summary-item">
                                        Request Process
                                        <span className="dashboard-red-summery">{dashboard.requestProcess}</span>
                                      </li>
                                      <li className="redemption-summary-item">
                                        Request Reject
                                        <span className="dashboard-red-summery">{dashboard.requestReject}</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 padding">
                          <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom border-right">
                            <div className="content">
                              <div
                                className="text listtopplumber"
                                onClick={() => setModalPlumber(true)}
                              >
                                Top 10 Plumbers <img src={plus} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 padding">
                          <div className="info-box bg-pink hover-expand-effect border-bottom lbalance">
                            <div className="content">
                              <div
                                className="text listtopretailer"
                                onClick={() => setModalRetailer(true)}
                              >
                                Top 15 Retailers <img src={plus} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 padding">
                          <div className="info-box bg-pink hover-expand-effect border-right border-bottom lbalance">
                            <div className="content">
                              <div className="text">
                                Plumber Verification Pending
                              </div>
                              <div className="number count-to">
                                {dashboard.pendingverification}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 padding">
                          <div className="info-box bg-pink hover-expand-effect border-right border-bottom lbalance">
                            <div className="content">
                              <div className="text">
                                Retailer Sales Claim Pending
                              </div>
                              <div className="number count-to">
                                {dashboard.retailerClaimPending}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 padding">
                          <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom border-right">
                            <div className="content">
                              <div className="text">Total Rejected</div>
                              <div className="number count-to">
                                {dashboard.memberRejected}
                              </div>
                            </div>
                          </div>
                        </div>

                        {monthValue === 0 ? (
                          ""
                        ) : (
                          <>
                            {userType === "SALES" ? (
                              <>
                                <div className="col-md-3 padding">
                                  <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom">
                                    <div className="content">
                                      <div className="text">Total Target</div>
                                      <div className="number count-to">
                                        {dashboard.totalSalesTarget}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 padding">
                                  <div className="info-box bg-pink hover-expand-effect border-right border-bottom  lbalance">
                                    <div className="content">
                                      <div className="text">Planned Period</div>
                                      <div className="number count-to">
                                        {dashboard.planeedPeriod}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 padding">
                                  <div className="info-box bg-pink hover-expand-effect  lbalance border-right border-bottom">
                                    <div className="content">
                                      <div className="text">
                                        Planned Run Rate(Per Day)
                                      </div>
                                      <div className="number count-to">
                                        {dashboard.planRunRatePerDay}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}

                        {userType === "SALES" ? (
                          <div className="col-md-3 padding">
                            <div className="info-box bg-pink hover-expand-effect  lbalance border-right border-bottom">
                              <div className="content">
                                <div className="text">
                                Enrollment Count (Actual)
                                </div>
                                <div className="number count-to">
                                  {dashboard.enrollmentCount}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {monthValue === 0 ? (
                          ""
                        ) : (
                          <>
                            {userType === "SALES" ? (
                              <div className="col-md-3 padding">
                                <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom">
                                  <div className="content">
                                    <div className="text">
                                      No. of Days (Actual)
                                    </div>
                                    <div className="number count-to">
                                      {dashboard.noOfDaysActual}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {userType === "SALES" ? (
                              <div className="col-md-3 padding">
                                <div className="info-box bg-pink hover-expand-effect border-right border-bottom lbalance">
                                  <div className="content">
                                    <div className="text">
                                      Actual Run Rate (Per Day)
                                    </div>
                                    <div className="number count-to">
                                      {dashboard.actualRunRatePerDay}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {userType === "SALES" ? (
                              <div className="col-md-3 padding">
                                <div className="info-box bg-pink hover-expand-effect border-bottom lbalance  border-right">
                                  <div className="content">
                                    <div className="text">Balance Target</div>
                                    <div className="number count-to">
                                      {dashboard.balanceTarget}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {userType === "SALES" ? (
                              <div className="col-md-3 padding">
                                <div className="info-box bg-pink hover-expand-effect  lbalance border-right border-bottom ">
                                  <div className="content">
                                    <div className="text">Balance Days</div>
                                    <div className="number count-to">
                                      {dashboard.balanceDays}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {userType === "SALES" ? (
                              <div className="col-md-3 padding">
                                <div className="info-box bg-pink hover-expand-effect  border-bottom  lbalance">
                                  <div className="content">
                                    <div className="text">
                                      Required Run Rate
                                    </div>
                                    <div className="number count-to">
                                      {dashboard.requiredRunRate}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}

                        {monthValue === 0 ? (
                          <div className="col-md-3 padding">
                            <div
                              className={`info-box bg-pink hover-expand-effect lbalance ${
                                userType === "HO"
                                  ? "border-bottom "
                                  : "border-right"
                              }`}
                            >
                              <div className="content">
                                <div className="text">Active Plumbers</div>
                                <div className="number count-to">
                                  {dashboard.memberActive}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {monthValue === 0 ? (
                          <div className="col-md-3 padding">
                            <div className="info-box bg-pink hover-expand-effect  lbalance border-right">
                              <div className="content">
                                <div className="text">Inactive Plumbers</div>
                                <div className="number count-to">
                                  {dashboard.memberInActive}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {monthValue === 0 ? (
                          <div className="col-md-3 padding">
                            <div className="info-box bg-pink hover-expand-effect  lbalance border-right">
                              <div className="content">
                                <div className="text">Dormant 1</div>
                                <div className="number count-to">
                                  {dashboard.userDormant1}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {monthValue === 0 ? (
                          <div className="col-md-3 padding">
                            <div
                              className={`info-box bg-pink hover-expand-effect lbalance ${
                                userType === "HO" ? "border-right" : ""
                              }`}
                            >
                              <div className="content">
                                <div className="text">Dormant 2</div>
                                <div className="number count-to">
                                  {dashboard.userDormant2}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {userType === "SALES" ? (
                  <div className="row mt-30">
                    <div className="col-md-8 col-sm-8 col-xs-12 col-23">
                      <div className="row">
                        <div className="col-md-3 col-xs-12">
                          <div className="card flex justify-content-center">
                            <Dropdown
                              value={selectedMonth}
                              onChange={(e) => handleRegCount(e)}
                              options={month}
                              optionLabel="name"
                              placeholder="Select a Month"
                              className="w-full md:w-14rem"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row mt-30">
                  <div className="col-md-8 col-sm-8 col-xs-12 col-23">
                    <div className="row">
                      <div className="col-md-4 col-xs-12">
                        <div className="kpibox">
                          <div className="upperkpi h134">
                            <div className="iconkpi">
                              <img src={registered} alt="" />
                            </div>
                            <div className="namekpi">REGISTERED</div>
                          </div>
                          {userType === "HO" ? (
                            <div className="kpilink">{dashboard.members}</div>
                          ) : (
                            <div className="kpilink">
                              {regDataCount === null
                                ? 0
                                : regDataCount?.members}
                            </div>
                          )}
                        </div>
                        {userType === "HO" ? (
                          ""
                        ) : (
                          <div className="viewdetail">
                            <Link to="/registered_s">
                              {" "}
                              View Details <img src={shortarrow} alt="" />
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 col-xs-12">
                        <div className="kpibox">
                          <div className="upperkpi h134">
                            <div className="iconkpi">
                              <img src={rejected} alt="" />
                            </div>
                            <div className="namekpi">REJECTED</div>
                          </div>
                          {userType === "HO" ? (
                            <div className="kpilink">
                              {dashboard.memberRejected}
                            </div>
                          ) : (
                            <div className="kpilink">
                              {regDataCount === null
                                ? 0
                                : regDataCount?.memberRejected}
                            </div>
                          )}
                        </div>
                        {userType === "HO" ? (
                          ""
                        ) : (
                          <div className="viewdetail">
                            <Link to="/rejected_s">
                              {" "}
                              View Details <img src={shortarrow} alt="" />
                            </Link>
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 col-xs-12">
                        <div className="kpibox">
                          <div className="upperkpi h134">
                            <div className="iconkpi">
                              <img src={pendingFa} alt="" />
                            </div>
                            <div className="namekpi">
                              PENDING FOR APPROVAL KYC
                            </div>
                          </div>
                          {userType === "HO" ? (
                            <div className="kpilink">
                              {pendingVerificationData?.pendingverification}
                            </div>
                          ) : (
                            <div className="kpilink">
                              {pendingVerificationData === null
                                ? 0
                                : pendingVerificationData?.pendingverification}
                            </div>
                          )}
                        </div>
                        {userType === "HO" ? (
                          ""
                        ) : (
                          <div className="viewdetail">
                            <Link to="/pending_for_approval_S">
                              {" "}
                              View Details <img src={shortarrow} alt="" />
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {userType === "HO" ? (
                    ""
                  ) : (
                    <div className="col-md-4 col-sm-4 col-xs-12 col-23">
                      <div className="card downloadapp">
                        <div className="card-header">
                          <div className="card-title">
                            <div className="forreg">
                              Enter the plumber number for Sending the app
                              download link:{" "}
                            </div>
                            <Link to="#" className="download">
                              {" "}
                              <img src={download} alt="" />{" "}
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-8">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="frmcntrl"
                                  placeholder="Mobile No."
                                  maxLength="10"
                                  value={mobile}
                                  onChange={(event) => {
                                    const input = event.target.value;
                                    if (/^\d*$/.test(input)) {
                                      setMobile(input);
                                    }
                                  }}
                                  onKeyDown={handleKeyDown}
                                />
                              </div>
                              {errors.mobile && (
                                <div className="error-message">
                                  {errors.mobile}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <Link
                                  to="#"
                                  className="btn login_white_btn"
                                  disabled={mobile.trim() === ""}
                                  onClick={() => sendRegLink(mobile)}
                                >
                                  {" "}
                                  Send <img src={longarrow} alt="" />{" "}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-3 col-xs-12">
                    <Link to={programDataChild?.catelog} target="_blank">
                      <div className="kpibox2">
                        <div className="upperkpi2">
                          <div className="iconkpi2">
                            <img src={video} alt="" />
                          </div>
                          <div className="namekpi2">
                            Finolex Product Catalogue
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <Link to="https://www.finolexpipes.com" target="_blank">
                      {/* <Link to={programDataChild?.whatsNew} target='_blank'> */}
                      <div className="kpibox2">
                        <div className="upperkpi2">
                          <div className="iconkpi2">
                            <img src={testimonial} alt="" />
                          </div>
                          <div className="namekpi2">About finolex Pipes</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <Link
                      to="https://www.youtube.com/channel/UC53laHlNZTYkUx6crpusvQw"
                      target="_blank"
                    >
                      <div className="kpibox2">
                        <div className="upperkpi2">
                          <div className="iconkpi2">
                            <img src={scheme} alt="" />
                          </div>
                          <div className="namekpi2">Finolex Pipes Video</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <Link to="/contact">
                      <div className="kpibox2">
                        <div className="upperkpi2">
                          <div className="iconkpi2">
                            <img src={contact} alt="" />
                          </div>
                          <div className="namekpi2">Contact Us</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dialog visible={modalPlumber} showHeader={false}>
        <div id="modalTopPlumber" className="" role="dialog">
          <div className="modal-dialog modal-plumber">
            <div className="modal-content pop-content660">
              <div className="modal-header">
                <img
                  src={close}
                  data-dismiss="modal"
                  className="close closeouter"
                  alt=""
                  onClick={(e) => setModalPlumber(false)}
                />
              </div>
              <div className="modal-body">
                <div className="modal-details">
                  <div className="row">
                    <div className="col-lg-12  col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="carrd1">
                            <div className="card-body">
                              <div
                                className="panel-group"
                                id="accordion"
                                role="tablist"
                                aria-multiselectable="true"
                              >
                                <div className="listhead">
                                  Plumbers In Your Territory
                                </div>
                                <div className="card">
                                  {topPlumber &&
                                    topPlumber.map((item, index) => {
                                      const formattedDate = moment(
                                        item.createDateTime,
                                        "DD-MM-YYYY HH:mm"
                                      ).format("DD-MM-YYYY");
                                      return (
                                        <>
                                          <Accordion
                                            activeIndex={index === 0 ? 0 : null}
                                            key={index}
                                          >
                                            <AccordionTab header={item.name}>
                                              <div
                                                id="collapseOne"
                                                className="panel-collapse collapse in"
                                                role="tabpanel"
                                                aria-labelledby="headingOne"
                                              >
                                                <div className="panel-body">
                                                  <div className="plmbdetail">
                                                    <p>
                                                      Joining Date :{" "}
                                                      {formattedDate}
                                                    </p>
                                                    <p>
                                                      City :{" "}
                                                      {
                                                        item.addressWork
                                                          .workCity
                                                      }
                                                    </p>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-md-4 col-xs-12">
                                                      <div className="kpibox plumberdetailbox">
                                                        <div className="upperkpi">
                                                          <div className="namekpi">
                                                            Total Earned Points
                                                          </div>
                                                        </div>
                                                        <div className="kpilink">
                                                          {item.totalPoints}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-4 col-xs-12">
                                                      <div className="kpibox plumberdetailbox">
                                                        <div className="upperkpi">
                                                          <div className="namekpi">
                                                            Total Redeemed
                                                            Points
                                                          </div>
                                                        </div>
                                                        <div className="kpilink">
                                                          {item.redeemPoints}
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="col-md-4 col-xs-12">
                                                      <div className="kpibox plumberdetailbox">
                                                        <div className="upperkpi">
                                                          <div className="namekpi">
                                                            Total Balance Points
                                                          </div>
                                                        </div>
                                                        <div className="kpilink">
                                                          {item.points}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </AccordionTab>
                                          </Accordion>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog visible={modalRetailer} showHeader={false}>
        <div id="modalTopPlumber" className="" role="dialog">
          <div className="modal-dialog modal-plumber">
            <div className="modal-content pop-content660">
              <div className="modal-header">
                <img
                  src={close}
                  data-dismiss="modal"
                  className="close closeouter"
                  alt=""
                  onClick={(e) => setModalRetailer(false)}
                />
              </div>
              <div className="modal-body">
                <div className="modal-details">
                  <div className="row">
                    <div className="col-lg-12  col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="carrd1">
                            <div className="card-body">
                              <div
                                className="panel-group"
                                id="accordion"
                                role="tablist"
                                aria-multiselectable="true"
                              >
                                <div className="listhead">
                                  Retailers In Your Territory
                                </div>
                                <div className="card">
                                  {topRetailer &&
                                    topRetailer.map((item, index) => {
                                      const formattedDate = moment(
                                        item.createDateTime,
                                        "DD-MM-YYYY HH:mm"
                                      ).format("DD-MM-YYYY");
                                      return (
                                        <>
                                          <Accordion
                                            activeIndex={index === 0 ? 0 : null}
                                            key={index}
                                          >
                                            <AccordionTab header={item.name}>
                                              <div
                                                id="collapseOne"
                                                className="panel-collapse collapse in"
                                                role="tabpanel"
                                                aria-labelledby="headingOne"
                                              >
                                                <div className="panel-body">
                                                  <div className="plmbdetail">
                                                    <p>
                                                      Joining Date :{" "}
                                                      {item.createDateTime ? formattedDate : 'NA'}
                                                    </p>
                                                    <p>
                                                      City :{" "}
                                                      {
                                                        item.addressWork
                                                          .workCity
                                                      }
                                                    </p>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-md-4 col-xs-12">
                                                      <div className="kpibox plumberdetailbox">
                                                        <div className="upperkpi">
                                                          <div className="namekpi">
                                                            Audited Points
                                                          </div>
                                                        </div>
                                                        <div className="kpilink">
                                                          {item.auditedPoint}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-4 col-xs-12">
                                                      <div className="kpibox plumberdetailbox">
                                                        <div className="upperkpi">
                                                          <div className="namekpi">
                                                            Unaudited Points
                                                          </div>
                                                        </div>
                                                        <div className="kpilink">
                                                          {item.unAuditedPoints}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </AccordionTab>
                                          </Accordion>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Footer />
    </>
  );
}
